
.pagination-arrow:disabled .arrow-left path[data-v-42d72f],
  .pagination-arrow:disabled .arrow-right path[data-v-42d72f] {
    fill: #DCD9D3;
}
.pagination-arrow .arrow-left path[data-v-42d72f],
  .pagination-arrow .arrow-right path[data-v-42d72f] {
    fill: #474747;
}

