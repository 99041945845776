
  /* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
    border-radius: 9999px;
    background: rgb(17 24 39);
}
/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    border-radius: 9999px;
    background: rgb(17 24 39);
    height: 1rem;
    width: 1rem;
}
/* All the same stuff for IE */
input[type=range]::-ms-thumb {
    border-radius: 9999px;
    background: rgb(17 24 39);
}
