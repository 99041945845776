
.overlay[data-v-944cca] {
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.5;
  height: 100%;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 10;
}
.dialog[data-v-944cca] {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.3rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;
  max-width: 100%;
  width: 31rem;
}
.content[data-v-944cca] {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.3rem;
}
.dialog-header[data-v-944cca] {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem 1rem;
}
.dialog-body[data-v-944cca] {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dialog-header.square[data-v-944cca] {
  background-image: url('../images/mask-square-background.png');
  background-size: cover;
}
.dialog-footer[data-v-944cca] {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  padding: 0.75rem 0.75rem 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dialog-footer-buttons[data-v-944cca] {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}
.dialog-footer > *[data-v-944cca] {margin: 0.25rem;}
.continue[data-v-944cca] {
  font-size: 1.125rem;
  font-weight: 500;
  background-color: #0A2430;
  color: #FFFFFF;
  padding: 0.65rem 2rem;
}
.register[data-v-944cca] {
    font-size: 1.125rem;
    font-weight: 500;
    background-color: #757575;
    color: #FFFFFF;
    padding: 0.65rem 2rem;
}
#view-searches[data-v-944cca] {
  font-size: 0.875rem;
  font-weight: 400;
  background-color: transparent;
  color: #A0A09E;
  padding: 0.375rem 0.75rem;
}
.continue[data-v-944cca], .register[data-v-944cca], #view-searches[data-v-944cca] {
  display: inline-block;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 25px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: 'DM Sans', sans-serif;
}
.title[data-v-944cca] {
  font-weight: 700;
  font-family: 'Athletics', sans-serif;
  font-size: calc(1.3rem + 0.6vw);
  line-height: 1.2;
  margin-bottom: 0.5rem;
  --tw-text-opacity: 1;
  color: #0A2430;
}
@media (min-width: 1200px) {
.title[data-v-944cca] {
    font-size: 2.25rem;
}
}
.description[data-v-944cca] {
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  margin-bottom: 0;
  color: #474747;
  font-family: 'DM Sans', sans-serif;
}
.img-fluid[data-v-944cca] {
  max-width: 100%;
  height: auto;
}

