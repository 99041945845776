
.quick-view[data-v-e4cac1] {
      width: 100%;
      top: 0px;
      right: 0px;
      background-color: #F9F6F2;
      z-index: 5;
}
@media (min-width: 992px) {
.quick-view[data-v-e4cac1] {
        position: fixed;
        width: 50%;
}
}
.close-quick-view[data-v-e4cac1]{
      margin: 10px;
      float: right;
      cursor: pointer;
}
.quick-view-inner[data-v-e4cac1]{
      margin: 60px;
      background-color: #FFFCFB;
      border-radius: 4px;
}

  