
.overlay[data-v-ac0110] {
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.5;
  height: 100%;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 999;
}
.dialog[data-v-ac0110] {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.3rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;
  max-width: 100%;
  width: 40rem;
}
.content-inner[data-v-ac0110] {
  padding: 3rem 1.75rem;
}
.title[data-v-ac0110] {
  font-weight: 700;
  font-size: 1.25rem;
  font-family: 'Athletics', sans-serif;
  line-height: 1.75rem;
  margin-bottom: 2rem;
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
  display: flex;
  align-items: center;
  gap: 1rem;
}
#alertName[data-v-ac0110] {
  padding: 0.5rem 2rem 0.5rem 0.8rem;
  border-radius: 5px;
  border: 1px solid #DCD9D3;
  color: #474747;
  font-family: 'DM Sans', sans-serif;
  font-size: 0.875rem;
}
#alertName[data-v-ac0110]::placeholder {
  color: #A0A09E;
}
#alertFreq[data-v-ac0110] {
  border: 1px solid #DCD9D3;
  border-radius: 5px;
  color: #474747;
  font-family: 'DM Sans', sans-serif;
  font-size: 0.875rem;
  padding: 0.5rem 2rem 0.5rem 0.8rem;
  position: relative;
  display: block;
  font-weight: 400;
  line-height: 1.5;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
.description[data-v-ac0110] {
  line-height: 1.25rem;
  margin-bottom: 1rem;
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  font-family: 'DM Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
}
.footer[data-v-ac0110] {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 1px solid #DCD9D3;
}
.footer-inner[data-v-ac0110] {
  padding: 0 1.75rem;
}
.cancel[data-v-ac0110] {
  border-radius: 0.75rem;
  font-weight: 500;
  font-family: 'DM Sans', sans-serif;
  margin-right: 1rem;
}
.cancel[data-v-ac0110]:focus{
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.cancel[data-v-ac0110]{
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.cancel[data-v-ac0110]:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  --tw-ring-opacity: 0.5;
}
.cancel[data-v-ac0110]{
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.cancel[data-v-ac0110]:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.confirm[data-v-ac0110] {
  display: inline-block;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #0A2430;
  color: #FFFFFF;
  font-family: 'DM Sans', sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  border-radius: 25px;
  padding: 0.7rem 2.9rem;
}
.confirm[data-v-ac0110]:hover {
  background-image: linear-gradient(to left, #8efffa 0%, #85f2d1 52.2%, #9befc2 100%);
  color: #0A2430;
}
.info[data-v-ac0110] {
  position: relative;
  display: inline-block;
}
.info .info-message[data-v-ac0110] {
  visibility: hidden;
  background-color: #0A2430;
  color: #fff;
  padding: 1rem;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  top: 200%;
  left: 50%;
  margin-left: -240px;
  width: 18rem;
  line-height: 1.5rem;
  font-size: 0.875rem;
  font-weight: 400;
}
.info .info-message[data-v-ac0110]::after {/* arrow on tooltip */
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 83%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}
@media (min-width: 576px) {
.info .info-message[data-v-ac0110] {
    width: 25rem;
    margin-left: -200px;
}
.info .info-message[data-v-ac0110]::after {
    left: 50%;
}
}
.info:hover .info-message[data-v-ac0110] {
  visibility: visible;
}

