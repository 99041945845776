
:root {
    --popper-theme-background-color: transparent;
    --popper-theme-background-color-hover: transparent;
    --popper-theme-text-color: rgb(31, 41, 55);
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 0px;
    --popper-theme-padding: 0;
    --popper-theme-box-shadow: none;
}
@media(max-width: 1024px) {
.popper {
        /* We do this to prevent popper activating on mobile where we just list instead */
        position: relative !important;
        transform: none !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
}
}
