.gs-btn-primary[data-v-71b988]{
    background-color: rgb(252, 166, 239);
    background-image: linear-gradient(90deg, rgb(251, 205, 114) 0%, rgb(255, 174, 180) 50%, rgb(252, 166, 239) 100%);
    font-size: 18px;
    line-height: 1.1;
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    border-radius: 9999px;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    text-align: center;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}