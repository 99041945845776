.vue-map {
  width: 100%;
  height: 100%;
}

.vue-map-container {
  height: 100%;
}

.primary-btn[data-v-d66b16] {
  color: #f9f7f4;
  background-color: #0a2430;
}

:root {
  --popper-theme-background-color: transparent;
  --popper-theme-background-color-hover: transparent;
  --popper-theme-text-color: #1f2937;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 0px;
  --popper-theme-padding: 0;
  --popper-theme-box-shadow: none;
}

@media (max-width: 1024px) {
  .popper {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative !important;
    transform: none !important;
  }
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.gs-container {
  width: 100%;
}

@media (min-width: 640px) {
  .gs-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .gs-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .gs-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .gs-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .gs-container {
    max-width: 1536px;
  }
}

.gs-prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.gs-prose :where([class~="lead"]):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.gs-prose :where(a):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.gs-prose :where(strong):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.gs-prose :where(ol):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: decimal;
}

.gs-prose :where(ol[type="A"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.gs-prose :where(ol[type="a"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.gs-prose :where(ol[type="A" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.gs-prose :where(ol[type="a" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.gs-prose :where(ol[type="I"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-roman;
}

.gs-prose :where(ol[type="i"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-roman;
}

.gs-prose :where(ol[type="I" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-roman;
}

.gs-prose :where(ol[type="i" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-roman;
}

.gs-prose :where(ol[type="1"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: decimal;
}

.gs-prose :where(ul):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: disc;
}

.gs-prose :where(ol > li):not(:where([class~="gs-not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.gs-prose :where(ul > li):not(:where([class~="gs-not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.gs-prose :where(hr):not(:where([class~="gs-not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.gs-prose :where(blockquote):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.gs-prose :where(blockquote p:first-of-type):not(:where([class~="gs-not-prose"] *)):before {
  content: open-quote;
}

.gs-prose :where(blockquote p:last-of-type):not(:where([class~="gs-not-prose"] *)):after {
  content: close-quote;
}

.gs-prose :where(h1):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.gs-prose :where(h1 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 900;
}

.gs-prose :where(h2):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.gs-prose :where(h2 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 800;
}

.gs-prose :where(h3):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.gs-prose :where(h3 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 700;
}

.gs-prose :where(h4):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.gs-prose :where(h4 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 700;
}

.gs-prose :where(figure > *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.gs-prose :where(figcaption):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.gs-prose :where(code):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.gs-prose :where(code):not(:where([class~="gs-not-prose"] *)):before, .gs-prose :where(code):not(:where([class~="gs-not-prose"] *)):after {
  content: "`";
}

.gs-prose :where(a code):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-links);
}

.gs-prose :where(pre):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)):before, .gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)):after {
  content: none;
}

.gs-prose :where(table):not(:where([class~="gs-not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.gs-prose :where(thead):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.gs-prose :where(thead th):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.gs-prose :where(tbody tr):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.gs-prose :where(tbody tr:last-child):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 0;
}

.gs-prose :where(tbody td):not(:where([class~="gs-not-prose"] *)) {
  vertical-align: baseline;
  padding: .571429em;
}

.gs-prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.gs-prose :where(p):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.gs-prose :where(img):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(video):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(figure):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(h2 code):not(:where([class~="gs-not-prose"] *)) {
  font-size: .875em;
}

.gs-prose :where(h3 code):not(:where([class~="gs-not-prose"] *)) {
  font-size: .9em;
}

.gs-prose :where(li):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.gs-prose :where(ol > li):not(:where([class~="gs-not-prose"] *)) {
  padding-left: .375em;
}

.gs-prose :where(ul > li):not(:where([class~="gs-not-prose"] *)) {
  padding-left: .375em;
}

.gs-prose > :where(ul > li p):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.gs-prose > :where(ul > li > :first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
}

.gs-prose > :where(ul > li > :last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.gs-prose > :where(ol > li > :first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
}

.gs-prose > :where(ol > li > :last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.gs-prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.gs-prose :where(hr + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h2 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h3 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h4 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(thead th:first-child):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 0;
}

.gs-prose :where(thead th:last-child):not(:where([class~="gs-not-prose"] *)) {
  padding-right: 0;
}

.gs-prose :where(tbody td:first-child):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 0;
}

.gs-prose :where(tbody td:last-child):not(:where([class~="gs-not-prose"] *)) {
  padding-right: 0;
}

.gs-prose > :where(:first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose > :where(:last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 0;
}

.pac-container {
  box-shadow: none;
  margin-top: .25rem;
}

.pac-container > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse) );
}

.pac-container {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  border-style: none;
  border-radius: .375rem;
  padding: 1.25rem;
}

.pac-icon {
  margin-top: .25rem;
}

.pac-item {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(220 217 211 / var(--tw-border-opacity) );
  border-radius: .125rem;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.pac-item-query {
  font-size: 1rem;
  line-height: 1.5rem;
}

.pac-logo {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.pac-logo:after {
  margin-top: 1rem;
}

.gs-static {
  position: static;
}

.gs-fixed {
  position: fixed;
}

.gs-absolute {
  position: absolute;
}

.gs-relative {
  position: relative;
}

.gs-sticky {
  position: sticky;
}

.gs-inset-y-0 {
  top: 0;
  bottom: 0;
}

.gs-inset-y-1 {
  top: .25rem;
  bottom: .25rem;
}

.gs-top-0 {
  top: 0;
}

.gs-left-0 {
  left: 0;
}

.gs-right-2 {
  right: .5rem;
}

.gs-top-2 {
  top: .5rem;
}

.gs-bottom-0 {
  bottom: 0;
}

.gs-right-3 {
  right: .75rem;
}

.gs-top-3 {
  top: .75rem;
}

.gs-left-3 {
  left: .75rem;
}

.gs-left-3\.5 {
  left: .875rem;
}

.gs-z-20 {
  z-index: 20;
}

.gs-z-10 {
  z-index: 10;
}

.gs-z-0 {
  z-index: 0;
}

.gs-col-span-2 {
  grid-column: span 2 / span 2;
}

.gs-col-span-1 {
  grid-column: span 1 / span 1;
}

.gs-m-6 {
  margin: 1.5rem;
}

.gs-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.-gs-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.gs-my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.gs-my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.gs-mt-10 {
  margin-top: 2.5rem;
}

.gs-mt-14 {
  margin-top: 3.5rem;
}

.gs-mt-5 {
  margin-top: 1.25rem;
}

.gs-mt-2 {
  margin-top: .5rem;
}

.gs-mt-1 {
  margin-top: .25rem;
}

.gs-mt-4 {
  margin-top: 1rem;
}

.gs-mr-4 {
  margin-right: 1rem;
}

.gs-mt-6 {
  margin-top: 1.5rem;
}

.gs-ml-2 {
  margin-left: .5rem;
}

.gs-mt-7 {
  margin-top: 1.75rem;
}

.gs-mt-3 {
  margin-top: .75rem;
}

.gs-mr-2 {
  margin-right: .5rem;
}

.gs-ml-5 {
  margin-left: 1.25rem;
}

.gs-ml-3 {
  margin-left: .75rem;
}

.gs-mt-16 {
  margin-top: 4rem;
}

.gs--mt-16 {
  margin-top: -4rem;
}

.gs-block {
  display: block;
}

.gs-flex {
  display: flex;
}

.gs-inline-flex {
  display: inline-flex;
}

.gs-grid {
  display: grid;
}

.gs-hidden {
  display: none;
}

.gs-h-14 {
  height: 3.5rem;
}

.gs-h-11 {
  height: 2.75rem;
}

.gs-h-2 {
  height: .5rem;
}

.gs-h-5 {
  height: 1.25rem;
}

.gs-h-12 {
  height: 3rem;
}

.gs-h-3 {
  height: .75rem;
}

.gs-h-16 {
  height: 4rem;
}

.gs-h-screen {
  height: 100vh;
}

.gs-h-\[calc\(100vh_-_10\.5rem\)\] {
  height: calc(100vh - 10.5rem);
}

.gs-h-\[56px\] {
  height: 56px;
}

.gs-h-1 {
  height: .25rem;
}

.gs-max-h-screen {
  max-height: 100vh;
}

.gs-max-h-\[calc\(100vh_-_2rem\)\] {
  max-height: calc(100vh - 2rem);
}

.gs-max-h-full {
  max-height: 100%;
}

.gs-max-h-\[calc\(100vh_-_26rem\)\] {
  max-height: calc(100vh - 26rem);
}

.gs-min-h-screen {
  min-height: 100vh;
}

.gs-w-14 {
  width: 3.5rem;
}

.gs-w-20 {
  width: 5rem;
}

.gs-w-full {
  width: 100%;
}

.gs-w-3 {
  width: .75rem;
}

.gs-w-\[71px\] {
  width: 71px;
}

.gs-w-11 {
  width: 2.75rem;
}

.gs-max-w-md {
  max-width: 28rem;
}

.gs-max-w-none {
  max-width: none;
}

.gs-flex-1 {
  flex: 1;
}

.gs-flex-none {
  flex: none;
}

.gs-shrink-0 {
  flex-shrink: 0;
}

.gs-basis-full {
  flex-basis: 100%;
}

.gs-basis-1\/2 {
  flex-basis: 50%;
}

.gs-basis-2\/5 {
  flex-basis: 40%;
}

.gs-basis-3\/5 {
  flex-basis: 60%;
}

.gs-scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.gs-scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.gs-transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

@keyframes gs-pulse {
  50% {
    opacity: .5;
  }
}

.gs-animate-pulse {
  animation: gs-pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
}

.gs-cursor-pointer {
  cursor: pointer;
}

.gs-list-inside {
  list-style-position: inside;
}

.gs-list-disc {
  list-style-type: disc;
}

.gs-appearance-none {
  appearance: none;
}

.gs-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.gs-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.gs-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.gs-flex-col {
  flex-direction: column;
}

.gs-flex-wrap {
  flex-wrap: wrap;
}

.gs-items-start {
  align-items: flex-start;
}

.gs-items-end {
  align-items: flex-end;
}

.gs-items-center {
  align-items: center;
}

.gs-justify-start {
  justify-content: flex-start;
}

.gs-justify-end {
  justify-content: flex-end;
}

.gs-justify-center {
  justify-content: center;
}

.gs-justify-between {
  justify-content: space-between;
}

.gs-gap-4 {
  gap: 1rem;
}

.gs-gap-2 {
  gap: .5rem;
}

.gs-gap-5 {
  gap: 1.25rem;
}

.gs-gap-x-1 {
  column-gap: .25rem;
}

.gs-gap-y-4 {
  row-gap: 1rem;
}

.gs-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse) );
}

.gs-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse) );
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse) );
}

.gs-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse) );
}

.gs-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse) );
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse) );
}

.gs-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse) ) );
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse) );
}

.gs-overflow-y-auto {
  overflow-y: auto;
}

.gs-overflow-y-hidden {
  overflow-y: hidden;
}

.gs-rounded-xl {
  border-radius: .75rem;
}

.gs-rounded-md {
  border-radius: .375rem;
}

.gs-rounded-full {
  border-radius: 9999px;
}

.gs-rounded {
  border-radius: .25rem;
}

.gs-rounded-3xl {
  border-radius: 1.5rem;
}

.gs-rounded-sm {
  border-radius: .125rem;
}

.gs-rounded-lg {
  border-radius: .5rem;
}

.gs-rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.gs-border {
  border-width: 1px;
}

.gs-border-0 {
  border-width: 0;
}

.gs-border-t {
  border-top-width: 1px;
}

.gs-border-none {
  border-style: none;
}

.gs-border-beige-500 {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 211 / var(--tw-border-opacity) );
}

.gs-border-beige-400 {
  --tw-border-opacity: 1;
  border-color: rgb(160 160 158 / var(--tw-border-opacity) );
}

.gs-border-stone-800 {
  --tw-border-opacity: 1;
  border-color: rgb(41 37 36 / var(--tw-border-opacity) );
}

.gs-border-stone-500 {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.gs-border-blueslate {
  --tw-border-opacity: 1;
  border-color: rgb(56 91 111 / var(--tw-border-opacity) );
}

.gs-border-beige-600 {
  --tw-border-opacity: 1;
  border-color: rgb(122 122 122 / var(--tw-border-opacity) );
}

.gs-border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
}

.gs-border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.gs-bg-beige-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 252 251 / var(--tw-bg-opacity) );
}

.gs-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.gs-bg-beige-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 217 211 / var(--tw-bg-opacity) );
}

.gs-bg-beige-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity) );
}

.gs-bg-beige-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 238 234 / var(--tw-bg-opacity) );
}

.gs-bg-blueslate {
  --tw-bg-opacity: 1;
  background-color: rgb(56 91 111 / var(--tw-bg-opacity) );
}

.gs-bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity) );
}

.gs-bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity) );
}

.gs-bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.gs-from-green-100 {
  --tw-gradient-from: #dcfce7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dcfce700);
}

.gs-to-cyan-100 {
  --tw-gradient-to: #cffafe;
}

.gs-p-10 {
  padding: 2.5rem;
}

.gs-p-6 {
  padding: 1.5rem;
}

.gs-p-2 {
  padding: .5rem;
}

.gs-p-5 {
  padding: 1.25rem;
}

.gs-p-1 {
  padding: .25rem;
}

.gs-p-8 {
  padding: 2rem;
}

.gs-py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.gs-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.gs-py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.gs-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.gs-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.gs-px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.gs-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.gs-px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.gs-px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.gs-pt-8 {
  padding-top: 2rem;
}

.gs-pb-4 {
  padding-bottom: 1rem;
}

.gs-pt-4 {
  padding-top: 1rem;
}

.gs-pl-5 {
  padding-left: 1.25rem;
}

.gs-pl-12 {
  padding-left: 3rem;
}

.gs-pr-3 {
  padding-right: .75rem;
}

.gs-text-center {
  text-align: center;
}

.gs-text-right {
  text-align: right;
}

.gs-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.gs-text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.gs-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.gs-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.gs-text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.gs-text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.gs-text-\[15px\] {
  font-size: 15px;
}

.gs-text-\[16px\] {
  font-size: 16px;
}

.gs-font-medium {
  font-weight: 500;
}

.gs-font-bold {
  font-weight: 700;
}

.gs-text-twilight-900 {
  --tw-text-opacity: 1;
  color: rgb(10 36 48 / var(--tw-text-opacity) );
}

.gs-text-beige-400 {
  --tw-text-opacity: 1;
  color: rgb(160 160 158 / var(--tw-text-opacity) );
}

.gs-text-granite-500 {
  --tw-text-opacity: 1;
  color: rgb(71 71 71 / var(--tw-text-opacity) );
}

.gs-text-stone-300 {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity) );
}

.gs-text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity) );
}

.gs-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.gs-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.gs-text-beige-600 {
  --tw-text-opacity: 1;
  color: rgb(122 122 122 / var(--tw-text-opacity) );
}

.gs-text-stone-600 {
  --tw-text-opacity: 1;
  color: rgb(87 83 78 / var(--tw-text-opacity) );
}

.gs-text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity) );
}

.gs-text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.gs-underline {
  text-decoration-line: underline;
}

.gs-opacity-0 {
  opacity: 0;
}

.gs-opacity-100 {
  opacity: 1;
}

.gs-shadow-\[0_-3px_5px_3px_rgba\(0\,0\,0\,0\.1\)\] {
  --tw-shadow: 0 -3px 5px 3px #0000001a;
  --tw-shadow-colored: 0 -3px 5px 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.gs-shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.gs-ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.gs-ring-gray-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity) );
}

.gs-ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.gs-drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.gs-transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.gs-duration-100 {
  transition-duration: .1s;
}

.gs-duration-75 {
  transition-duration: 75ms;
}

.gs-ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.gs-ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.first\:gs-rounded-t-xl:first-child {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.checked\:gs-bg-gray-800:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity) );
}

.hover\:gs-cursor-pointer:hover {
  cursor: pointer;
}

.hover\:gs-border-stone-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.hover\:gs-border-gray-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.hover\:gs-bg-blueslate-light:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(90 129 136 / var(--tw-bg-opacity) );
}

.hover\:gs-bg-beige-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity) );
}

.hover\:gs-bg-gradient-to-r:hover {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.hover\:gs-from-orange-400:hover {
  --tw-gradient-from: #fb923c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fb923c00);
}

.hover\:gs-to-pink-400:hover {
  --tw-gradient-to: #f472b6;
}

.hover\:gs-text-stone-500:hover {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity) );
}

.hover\:gs-text-twilight-900:hover {
  --tw-text-opacity: 1;
  color: rgb(10 36 48 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity) );
}

.hover\:gs-shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:gs-shadow-orange-400\/50:hover {
  --tw-shadow-color: #fb923c80;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:gs-ring-2:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:gs-border-stone-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.focus\:gs-outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.disabled\:gs-border-beige-500:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 211 / var(--tw-border-opacity) );
}

.disabled\:gs-text-stone-300:disabled {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity) );
}

@media (min-width: 640px) {
  .sm\:gs-pt-0 {
    padding-top: 0;
  }
}

@media (min-width: 768px) {
  .md\:gs-w-1\/2 {
    width: 50%;
  }

  .md\:gs-flex-row {
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  .lg\:gs-relative {
    position: relative;
  }

  .lg\:gs-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:gs-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:gs-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:gs-mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:gs-block {
    display: block;
  }

  .lg\:gs-flex {
    display: flex;
  }

  .lg\:gs-inline-flex {
    display: inline-flex;
  }

  .lg\:gs-hidden {
    display: none;
  }

  .lg\:gs-h-full {
    height: 100%;
  }

  .lg\:gs-w-fit {
    width: fit-content;
  }

  .lg\:gs-w-96 {
    width: 24rem;
  }

  .lg\:gs-max-w-\[260px\] {
    max-width: 260px;
  }

  .lg\:gs-basis-1\/2 {
    flex-basis: 50%;
  }

  .lg\:gs-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:gs-flex-row {
    flex-direction: row;
  }

  .lg\:gs-items-center {
    align-items: center;
  }

  .lg\:gs-justify-center {
    justify-content: center;
  }

  .lg\:gs-gap-4 {
    gap: 1rem;
  }

  .lg\:gs-rounded-xl {
    border-radius: .75rem;
  }

  .lg\:gs-border {
    border-width: 1px;
  }

  .lg\:gs-border-beige-500 {
    --tw-border-opacity: 1;
    border-color: rgb(220 217 211 / var(--tw-border-opacity) );
  }

  .lg\:gs-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  }

  .lg\:gs-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:gs-pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:gs-pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:gs-text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:gs-text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:gs-shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:gs-drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:last\:gs-rounded-b-xl:last-child {
    border-bottom-left-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:gs-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .\32 xl\:gs-col-span-1 {
    grid-column: span 1 / span 1;
  }
}

input[type="range"]::-moz-range-thumb {
  background: #111827;
  border-radius: 9999px;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  height: 1rem;
  width: 1rem;
  background: #111827;
  border-radius: 9999px;
}

input[type="range"]::-ms-thumb {
  background: #111827;
  border-radius: 9999px;
}

.search-btn[data-v-886e96] {
  padding: .25rem 0;
}

.primary-btn[data-v-886e96] {
  color: #f9f7f4;
  background-color: #0a2430;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.gs-container {
  width: 100%;
}

@media (min-width: 640px) {
  .gs-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .gs-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .gs-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .gs-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .gs-container {
    max-width: 1536px;
  }
}

.gs-prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.gs-prose :where([class~="lead"]):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.gs-prose :where(a):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.gs-prose :where(strong):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.gs-prose :where(ol):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: decimal;
}

.gs-prose :where(ol[type="A"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.gs-prose :where(ol[type="a"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.gs-prose :where(ol[type="A" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.gs-prose :where(ol[type="a" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.gs-prose :where(ol[type="I"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-roman;
}

.gs-prose :where(ol[type="i"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-roman;
}

.gs-prose :where(ol[type="I" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-roman;
}

.gs-prose :where(ol[type="i" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-roman;
}

.gs-prose :where(ol[type="1"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: decimal;
}

.gs-prose :where(ul):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: disc;
}

.gs-prose :where(ol > li):not(:where([class~="gs-not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.gs-prose :where(ul > li):not(:where([class~="gs-not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.gs-prose :where(hr):not(:where([class~="gs-not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.gs-prose :where(blockquote):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.gs-prose :where(blockquote p:first-of-type):not(:where([class~="gs-not-prose"] *)):before {
  content: open-quote;
}

.gs-prose :where(blockquote p:last-of-type):not(:where([class~="gs-not-prose"] *)):after {
  content: close-quote;
}

.gs-prose :where(h1):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.gs-prose :where(h1 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 900;
}

.gs-prose :where(h2):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.gs-prose :where(h2 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 800;
}

.gs-prose :where(h3):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.gs-prose :where(h3 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 700;
}

.gs-prose :where(h4):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.gs-prose :where(h4 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 700;
}

.gs-prose :where(figure > *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.gs-prose :where(figcaption):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.gs-prose :where(code):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.gs-prose :where(code):not(:where([class~="gs-not-prose"] *)):before, .gs-prose :where(code):not(:where([class~="gs-not-prose"] *)):after {
  content: "`";
}

.gs-prose :where(a code):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-links);
}

.gs-prose :where(pre):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)):before, .gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)):after {
  content: none;
}

.gs-prose :where(table):not(:where([class~="gs-not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.gs-prose :where(thead):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.gs-prose :where(thead th):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.gs-prose :where(tbody tr):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.gs-prose :where(tbody tr:last-child):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 0;
}

.gs-prose :where(tbody td):not(:where([class~="gs-not-prose"] *)) {
  vertical-align: baseline;
  padding: .571429em;
}

.gs-prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.gs-prose :where(p):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.gs-prose :where(img):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(video):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(figure):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(h2 code):not(:where([class~="gs-not-prose"] *)) {
  font-size: .875em;
}

.gs-prose :where(h3 code):not(:where([class~="gs-not-prose"] *)) {
  font-size: .9em;
}

.gs-prose :where(li):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.gs-prose :where(ol > li):not(:where([class~="gs-not-prose"] *)) {
  padding-left: .375em;
}

.gs-prose :where(ul > li):not(:where([class~="gs-not-prose"] *)) {
  padding-left: .375em;
}

.gs-prose > :where(ul > li p):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.gs-prose > :where(ul > li > :first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
}

.gs-prose > :where(ul > li > :last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.gs-prose > :where(ol > li > :first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
}

.gs-prose > :where(ol > li > :last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.gs-prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.gs-prose :where(hr + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h2 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h3 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h4 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(thead th:first-child):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 0;
}

.gs-prose :where(thead th:last-child):not(:where([class~="gs-not-prose"] *)) {
  padding-right: 0;
}

.gs-prose :where(tbody td:first-child):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 0;
}

.gs-prose :where(tbody td:last-child):not(:where([class~="gs-not-prose"] *)) {
  padding-right: 0;
}

.gs-prose > :where(:first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose > :where(:last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 0;
}

.gs-static {
  position: static;
}

.gs-fixed {
  position: fixed;
}

.gs-absolute {
  position: absolute;
}

.gs-relative {
  position: relative;
}

.gs-sticky {
  position: sticky;
}

.gs-inset-y-0 {
  top: 0;
  bottom: 0;
}

.gs-inset-y-1 {
  top: .25rem;
  bottom: .25rem;
}

.gs-top-0 {
  top: 0;
}

.gs-left-0 {
  left: 0;
}

.gs-right-2 {
  right: .5rem;
}

.gs-top-2 {
  top: .5rem;
}

.gs-bottom-0 {
  bottom: 0;
}

.gs-right-3 {
  right: .75rem;
}

.gs-top-3 {
  top: .75rem;
}

.gs-left-3 {
  left: .75rem;
}

.gs-left-3\.5 {
  left: .875rem;
}

.gs-z-20 {
  z-index: 20;
}

.gs-z-10 {
  z-index: 10;
}

.gs-z-0 {
  z-index: 0;
}

.gs-col-span-2 {
  grid-column: span 2 / span 2;
}

.gs-col-span-1 {
  grid-column: span 1 / span 1;
}

.gs-m-6 {
  margin: 1.5rem;
}

.gs-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.-gs-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.gs-my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.gs-my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.gs-mt-10 {
  margin-top: 2.5rem;
}

.gs-mt-14 {
  margin-top: 3.5rem;
}

.gs-mt-5 {
  margin-top: 1.25rem;
}

.gs-mt-2 {
  margin-top: .5rem;
}

.gs-mt-1 {
  margin-top: .25rem;
}

.gs-mt-4 {
  margin-top: 1rem;
}

.gs-mr-4 {
  margin-right: 1rem;
}

.gs-mt-6 {
  margin-top: 1.5rem;
}

.gs-ml-2 {
  margin-left: .5rem;
}

.gs-mt-7 {
  margin-top: 1.75rem;
}

.gs-mt-3 {
  margin-top: .75rem;
}

.gs-mr-2 {
  margin-right: .5rem;
}

.gs-ml-5 {
  margin-left: 1.25rem;
}

.gs-ml-3 {
  margin-left: .75rem;
}

.gs-mt-16 {
  margin-top: 4rem;
}

.gs--mt-16 {
  margin-top: -4rem;
}

.gs-block {
  display: block;
}

.gs-flex {
  display: flex;
}

.gs-inline-flex {
  display: inline-flex;
}

.gs-grid {
  display: grid;
}

.gs-hidden {
  display: none;
}

.gs-h-14 {
  height: 3.5rem;
}

.gs-h-11 {
  height: 2.75rem;
}

.gs-h-2 {
  height: .5rem;
}

.gs-h-5 {
  height: 1.25rem;
}

.gs-h-12 {
  height: 3rem;
}

.gs-h-3 {
  height: .75rem;
}

.gs-h-16 {
  height: 4rem;
}

.gs-h-screen {
  height: 100vh;
}

.gs-h-\[calc\(100vh_-_10\.5rem\)\] {
  height: calc(100vh - 10.5rem);
}

.gs-h-\[56px\] {
  height: 56px;
}

.gs-h-1 {
  height: .25rem;
}

.gs-max-h-screen {
  max-height: 100vh;
}

.gs-max-h-\[calc\(100vh_-_2rem\)\] {
  max-height: calc(100vh - 2rem);
}

.gs-max-h-full {
  max-height: 100%;
}

.gs-max-h-\[calc\(100vh_-_26rem\)\] {
  max-height: calc(100vh - 26rem);
}

.gs-min-h-screen {
  min-height: 100vh;
}

.gs-w-14 {
  width: 3.5rem;
}

.gs-w-20 {
  width: 5rem;
}

.gs-w-full {
  width: 100%;
}

.gs-w-3 {
  width: .75rem;
}

.gs-w-\[71px\] {
  width: 71px;
}

.gs-w-11 {
  width: 2.75rem;
}

.gs-max-w-md {
  max-width: 28rem;
}

.gs-max-w-none {
  max-width: none;
}

.gs-flex-1 {
  flex: 1;
}

.gs-flex-none {
  flex: none;
}

.gs-shrink-0 {
  flex-shrink: 0;
}

.gs-basis-full {
  flex-basis: 100%;
}

.gs-basis-1\/2 {
  flex-basis: 50%;
}

.gs-basis-2\/5 {
  flex-basis: 40%;
}

.gs-basis-3\/5 {
  flex-basis: 60%;
}

.gs-scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.gs-scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.gs-transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

@keyframes gs-pulse {
  50% {
    opacity: .5;
  }
}

.gs-animate-pulse {
  animation: gs-pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
}

.gs-cursor-pointer {
  cursor: pointer;
}

.gs-list-inside {
  list-style-position: inside;
}

.gs-list-disc {
  list-style-type: disc;
}

.gs-appearance-none {
  appearance: none;
}

.gs-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.gs-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.gs-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.gs-flex-col {
  flex-direction: column;
}

.gs-flex-wrap {
  flex-wrap: wrap;
}

.gs-items-start {
  align-items: flex-start;
}

.gs-items-end {
  align-items: flex-end;
}

.gs-items-center {
  align-items: center;
}

.gs-justify-start {
  justify-content: flex-start;
}

.gs-justify-end {
  justify-content: flex-end;
}

.gs-justify-center {
  justify-content: center;
}

.gs-justify-between {
  justify-content: space-between;
}

.gs-gap-4 {
  gap: 1rem;
}

.gs-gap-2 {
  gap: .5rem;
}

.gs-gap-5 {
  gap: 1.25rem;
}

.gs-gap-x-1 {
  column-gap: .25rem;
}

.gs-gap-y-4 {
  row-gap: 1rem;
}

.gs-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse) );
}

.gs-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse) );
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse) );
}

.gs-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse) );
}

.gs-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse) );
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse) );
}

.gs-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse) ) );
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse) );
}

.gs-overflow-y-auto {
  overflow-y: auto;
}

.gs-overflow-y-hidden {
  overflow-y: hidden;
}

.gs-rounded-xl {
  border-radius: .75rem;
}

.gs-rounded-md {
  border-radius: .375rem;
}

.gs-rounded-full {
  border-radius: 9999px;
}

.gs-rounded {
  border-radius: .25rem;
}

.gs-rounded-3xl {
  border-radius: 1.5rem;
}

.gs-rounded-sm {
  border-radius: .125rem;
}

.gs-rounded-lg {
  border-radius: .5rem;
}

.gs-rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.gs-border {
  border-width: 1px;
}

.gs-border-0 {
  border-width: 0;
}

.gs-border-t {
  border-top-width: 1px;
}

.gs-border-none {
  border-style: none;
}

.gs-border-beige-500 {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 211 / var(--tw-border-opacity) );
}

.gs-border-beige-400 {
  --tw-border-opacity: 1;
  border-color: rgb(160 160 158 / var(--tw-border-opacity) );
}

.gs-border-stone-800 {
  --tw-border-opacity: 1;
  border-color: rgb(41 37 36 / var(--tw-border-opacity) );
}

.gs-border-stone-500 {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.gs-border-blueslate {
  --tw-border-opacity: 1;
  border-color: rgb(56 91 111 / var(--tw-border-opacity) );
}

.gs-border-beige-600 {
  --tw-border-opacity: 1;
  border-color: rgb(122 122 122 / var(--tw-border-opacity) );
}

.gs-border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
}

.gs-border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.gs-bg-beige-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 252 251 / var(--tw-bg-opacity) );
}

.gs-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.gs-bg-beige-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 217 211 / var(--tw-bg-opacity) );
}

.gs-bg-beige-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity) );
}

.gs-bg-beige-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 238 234 / var(--tw-bg-opacity) );
}

.gs-bg-blueslate {
  --tw-bg-opacity: 1;
  background-color: rgb(56 91 111 / var(--tw-bg-opacity) );
}

.gs-bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity) );
}

.gs-bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity) );
}

.gs-bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.gs-from-green-100 {
  --tw-gradient-from: #dcfce7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dcfce700);
}

.gs-to-cyan-100 {
  --tw-gradient-to: #cffafe;
}

.gs-p-10 {
  padding: 2.5rem;
}

.gs-p-6 {
  padding: 1.5rem;
}

.gs-p-2 {
  padding: .5rem;
}

.gs-p-5 {
  padding: 1.25rem;
}

.gs-p-1 {
  padding: .25rem;
}

.gs-p-8 {
  padding: 2rem;
}

.gs-py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.gs-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.gs-py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.gs-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.gs-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.gs-px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.gs-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.gs-px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.gs-px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.gs-pt-8 {
  padding-top: 2rem;
}

.gs-pb-4 {
  padding-bottom: 1rem;
}

.gs-pt-4 {
  padding-top: 1rem;
}

.gs-pl-5 {
  padding-left: 1.25rem;
}

.gs-pl-12 {
  padding-left: 3rem;
}

.gs-pr-3 {
  padding-right: .75rem;
}

.gs-text-center {
  text-align: center;
}

.gs-text-right {
  text-align: right;
}

.gs-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.gs-text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.gs-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.gs-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.gs-text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.gs-text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.gs-text-\[15px\] {
  font-size: 15px;
}

.gs-text-\[16px\] {
  font-size: 16px;
}

.gs-font-medium {
  font-weight: 500;
}

.gs-font-bold {
  font-weight: 700;
}

.gs-text-twilight-900 {
  --tw-text-opacity: 1;
  color: rgb(10 36 48 / var(--tw-text-opacity) );
}

.gs-text-beige-400 {
  --tw-text-opacity: 1;
  color: rgb(160 160 158 / var(--tw-text-opacity) );
}

.gs-text-granite-500 {
  --tw-text-opacity: 1;
  color: rgb(71 71 71 / var(--tw-text-opacity) );
}

.gs-text-stone-300 {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity) );
}

.gs-text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity) );
}

.gs-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.gs-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.gs-text-beige-600 {
  --tw-text-opacity: 1;
  color: rgb(122 122 122 / var(--tw-text-opacity) );
}

.gs-text-stone-600 {
  --tw-text-opacity: 1;
  color: rgb(87 83 78 / var(--tw-text-opacity) );
}

.gs-text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity) );
}

.gs-text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.gs-underline {
  text-decoration-line: underline;
}

.gs-opacity-0 {
  opacity: 0;
}

.gs-opacity-100 {
  opacity: 1;
}

.gs-shadow-\[0_-3px_5px_3px_rgba\(0\,0\,0\,0\.1\)\] {
  --tw-shadow: 0 -3px 5px 3px #0000001a;
  --tw-shadow-colored: 0 -3px 5px 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.gs-shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.gs-ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.gs-ring-gray-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity) );
}

.gs-ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.gs-drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.gs-transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.gs-duration-100 {
  transition-duration: .1s;
}

.gs-duration-75 {
  transition-duration: 75ms;
}

.gs-ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.gs-ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.first\:gs-rounded-t-xl:first-child {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.checked\:gs-bg-gray-800:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity) );
}

.hover\:gs-cursor-pointer:hover {
  cursor: pointer;
}

.hover\:gs-border-stone-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.hover\:gs-border-gray-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.hover\:gs-bg-blueslate-light:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(90 129 136 / var(--tw-bg-opacity) );
}

.hover\:gs-bg-beige-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity) );
}

.hover\:gs-bg-gradient-to-r:hover {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.hover\:gs-from-orange-400:hover {
  --tw-gradient-from: #fb923c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fb923c00);
}

.hover\:gs-to-pink-400:hover {
  --tw-gradient-to: #f472b6;
}

.hover\:gs-text-stone-500:hover {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity) );
}

.hover\:gs-text-twilight-900:hover {
  --tw-text-opacity: 1;
  color: rgb(10 36 48 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity) );
}

.hover\:gs-shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:gs-shadow-orange-400\/50:hover {
  --tw-shadow-color: #fb923c80;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:gs-ring-2:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:gs-border-stone-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.focus\:gs-outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.disabled\:gs-border-beige-500:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 211 / var(--tw-border-opacity) );
}

.disabled\:gs-text-stone-300:disabled {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity) );
}

@media (min-width: 640px) {
  .sm\:gs-pt-0 {
    padding-top: 0;
  }
}

@media (min-width: 768px) {
  .md\:gs-w-1\/2 {
    width: 50%;
  }

  .md\:gs-flex-row {
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  .lg\:gs-relative {
    position: relative;
  }

  .lg\:gs-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:gs-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:gs-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:gs-mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:gs-block {
    display: block;
  }

  .lg\:gs-flex {
    display: flex;
  }

  .lg\:gs-inline-flex {
    display: inline-flex;
  }

  .lg\:gs-hidden {
    display: none;
  }

  .lg\:gs-h-full {
    height: 100%;
  }

  .lg\:gs-w-fit {
    width: fit-content;
  }

  .lg\:gs-w-96 {
    width: 24rem;
  }

  .lg\:gs-max-w-\[260px\] {
    max-width: 260px;
  }

  .lg\:gs-basis-1\/2 {
    flex-basis: 50%;
  }

  .lg\:gs-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:gs-flex-row {
    flex-direction: row;
  }

  .lg\:gs-items-center {
    align-items: center;
  }

  .lg\:gs-justify-center {
    justify-content: center;
  }

  .lg\:gs-gap-4 {
    gap: 1rem;
  }

  .lg\:gs-rounded-xl {
    border-radius: .75rem;
  }

  .lg\:gs-border {
    border-width: 1px;
  }

  .lg\:gs-border-beige-500 {
    --tw-border-opacity: 1;
    border-color: rgb(220 217 211 / var(--tw-border-opacity) );
  }

  .lg\:gs-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  }

  .lg\:gs-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:gs-pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:gs-pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:gs-text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:gs-text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:gs-shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:gs-drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:last\:gs-rounded-b-xl:last-child {
    border-bottom-left-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:gs-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .\32 xl\:gs-col-span-1 {
    grid-column: span 1 / span 1;
  }
}

.pagination-arrow:disabled .arrow-left path[data-v-42d72f], .pagination-arrow:disabled .arrow-right path[data-v-42d72f] {
  fill: #dcd9d3;
}

.pagination-arrow .arrow-left path[data-v-42d72f], .pagination-arrow .arrow-right path[data-v-42d72f] {
  fill: #474747;
}

.job-tag[data-v-126eb2] {
  border-color: #a1a19f;
}

.result-detail .job-tag[data-v-126eb2] {
  border: none;
  padding: .25rem 0;
}

.primary-btn[data-v-22bdd2] {
  color: #f9f7f4;
  background-color: #0a2430;
}

.search-result[data-v-bcd488] {
  margin-bottom: .1rem;
}

.gs-detail-body[data-v-2f9d12] {
  padding-bottom: 30px !important;
}

.gs-mt-16[data-v-2f9d12] {
  margin-top: 4rem !important;
}

p[data-v-067f3c]:not(:last-child):not(:only-child):not(:last-of-type) {
  margin-bottom: 0 !important;
}

.container[data-v-d3e37633] {
  box-sizing: initial;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  overflow: hidden;
}

.spinner[data-v-d3e37633] {
  box-sizing: initial;
  width: 23px;
  height: 23px;
  border: 2px solid #a1a1a1;
  border-right-color: #0000;
  border-radius: 50%;
  animation: spin-d3e37633 .9s linear infinite;
}

@keyframes spin-d3e37633 {
  to {
    transform: rotate(360deg);
  }
}

.v3-infinite-loading[data-v-4bdee133] {
  width: 100%;
  height: 44px;
}

.state-error[data-v-4bdee133] {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.retry[data-v-4bdee133] {
  width: 60px;
  color: inherit;
  cursor: pointer;
  background: none;
  border: 2px solid;
  border-radius: 5px;
  outline: none;
  margin-top: 8px;
  padding: 2px 6px 4px;
  font-family: inherit;
  font-size: 14px;
}

.retry[data-v-4bdee133]:hover {
  opacity: .8;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.gs-container {
  width: 100%;
}

@media (min-width: 640px) {
  .gs-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .gs-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .gs-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .gs-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .gs-container {
    max-width: 1536px;
  }
}

.gs-prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.gs-prose :where([class~="lead"]):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.gs-prose :where(a):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.gs-prose :where(strong):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.gs-prose :where(ol):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: decimal;
}

.gs-prose :where(ol[type="A"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.gs-prose :where(ol[type="a"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.gs-prose :where(ol[type="A" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.gs-prose :where(ol[type="a" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.gs-prose :where(ol[type="I"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-roman;
}

.gs-prose :where(ol[type="i"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-roman;
}

.gs-prose :where(ol[type="I" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-roman;
}

.gs-prose :where(ol[type="i" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-roman;
}

.gs-prose :where(ol[type="1"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: decimal;
}

.gs-prose :where(ul):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: disc;
}

.gs-prose :where(ol > li):not(:where([class~="gs-not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.gs-prose :where(ul > li):not(:where([class~="gs-not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.gs-prose :where(hr):not(:where([class~="gs-not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.gs-prose :where(blockquote):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.gs-prose :where(blockquote p:first-of-type):not(:where([class~="gs-not-prose"] *)):before {
  content: open-quote;
}

.gs-prose :where(blockquote p:last-of-type):not(:where([class~="gs-not-prose"] *)):after {
  content: close-quote;
}

.gs-prose :where(h1):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.gs-prose :where(h1 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 900;
}

.gs-prose :where(h2):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.gs-prose :where(h2 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 800;
}

.gs-prose :where(h3):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.gs-prose :where(h3 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 700;
}

.gs-prose :where(h4):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.gs-prose :where(h4 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 700;
}

.gs-prose :where(figure > *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.gs-prose :where(figcaption):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.gs-prose :where(code):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.gs-prose :where(code):not(:where([class~="gs-not-prose"] *)):before, .gs-prose :where(code):not(:where([class~="gs-not-prose"] *)):after {
  content: "`";
}

.gs-prose :where(a code):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-links);
}

.gs-prose :where(pre):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)):before, .gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)):after {
  content: none;
}

.gs-prose :where(table):not(:where([class~="gs-not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.gs-prose :where(thead):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.gs-prose :where(thead th):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.gs-prose :where(tbody tr):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.gs-prose :where(tbody tr:last-child):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 0;
}

.gs-prose :where(tbody td):not(:where([class~="gs-not-prose"] *)) {
  vertical-align: baseline;
  padding: .571429em;
}

.gs-prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.gs-prose :where(p):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.gs-prose :where(img):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(video):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(figure):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(h2 code):not(:where([class~="gs-not-prose"] *)) {
  font-size: .875em;
}

.gs-prose :where(h3 code):not(:where([class~="gs-not-prose"] *)) {
  font-size: .9em;
}

.gs-prose :where(li):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.gs-prose :where(ol > li):not(:where([class~="gs-not-prose"] *)) {
  padding-left: .375em;
}

.gs-prose :where(ul > li):not(:where([class~="gs-not-prose"] *)) {
  padding-left: .375em;
}

.gs-prose > :where(ul > li p):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.gs-prose > :where(ul > li > :first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
}

.gs-prose > :where(ul > li > :last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.gs-prose > :where(ol > li > :first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
}

.gs-prose > :where(ol > li > :last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.gs-prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.gs-prose :where(hr + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h2 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h3 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h4 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(thead th:first-child):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 0;
}

.gs-prose :where(thead th:last-child):not(:where([class~="gs-not-prose"] *)) {
  padding-right: 0;
}

.gs-prose :where(tbody td:first-child):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 0;
}

.gs-prose :where(tbody td:last-child):not(:where([class~="gs-not-prose"] *)) {
  padding-right: 0;
}

.gs-prose > :where(:first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose > :where(:last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 0;
}

.gs-static {
  position: static;
}

.gs-fixed {
  position: fixed;
}

.gs-absolute {
  position: absolute;
}

.gs-relative {
  position: relative;
}

.gs-sticky {
  position: sticky;
}

.gs-inset-y-0 {
  top: 0;
  bottom: 0;
}

.gs-inset-y-1 {
  top: .25rem;
  bottom: .25rem;
}

.gs-top-0 {
  top: 0;
}

.gs-left-0 {
  left: 0;
}

.gs-right-2 {
  right: .5rem;
}

.gs-top-2 {
  top: .5rem;
}

.gs-bottom-0 {
  bottom: 0;
}

.gs-right-3 {
  right: .75rem;
}

.gs-top-3 {
  top: .75rem;
}

.gs-left-3 {
  left: .75rem;
}

.gs-left-3\.5 {
  left: .875rem;
}

.gs-z-20 {
  z-index: 20;
}

.gs-z-10 {
  z-index: 10;
}

.gs-z-0 {
  z-index: 0;
}

.gs-col-span-2 {
  grid-column: span 2 / span 2;
}

.gs-col-span-1 {
  grid-column: span 1 / span 1;
}

.gs-m-6 {
  margin: 1.5rem;
}

.gs-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.-gs-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.gs-my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.gs-my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.gs-mt-10 {
  margin-top: 2.5rem;
}

.gs-mt-14 {
  margin-top: 3.5rem;
}

.gs-mt-5 {
  margin-top: 1.25rem;
}

.gs-mt-2 {
  margin-top: .5rem;
}

.gs-mt-1 {
  margin-top: .25rem;
}

.gs-mt-4 {
  margin-top: 1rem;
}

.gs-mr-4 {
  margin-right: 1rem;
}

.gs-mt-6 {
  margin-top: 1.5rem;
}

.gs-ml-2 {
  margin-left: .5rem;
}

.gs-mt-7 {
  margin-top: 1.75rem;
}

.gs-mt-3 {
  margin-top: .75rem;
}

.gs-mr-2 {
  margin-right: .5rem;
}

.gs-ml-5 {
  margin-left: 1.25rem;
}

.gs-ml-3 {
  margin-left: .75rem;
}

.gs-mt-16 {
  margin-top: 4rem;
}

.gs--mt-16 {
  margin-top: -4rem;
}

.gs-block {
  display: block;
}

.gs-flex {
  display: flex;
}

.gs-inline-flex {
  display: inline-flex;
}

.gs-grid {
  display: grid;
}

.gs-hidden {
  display: none;
}

.gs-h-14 {
  height: 3.5rem;
}

.gs-h-11 {
  height: 2.75rem;
}

.gs-h-2 {
  height: .5rem;
}

.gs-h-5 {
  height: 1.25rem;
}

.gs-h-12 {
  height: 3rem;
}

.gs-h-3 {
  height: .75rem;
}

.gs-h-16 {
  height: 4rem;
}

.gs-h-screen {
  height: 100vh;
}

.gs-h-\[calc\(100vh_-_10\.5rem\)\] {
  height: calc(100vh - 10.5rem);
}

.gs-h-\[56px\] {
  height: 56px;
}

.gs-h-1 {
  height: .25rem;
}

.gs-max-h-screen {
  max-height: 100vh;
}

.gs-max-h-\[calc\(100vh_-_2rem\)\] {
  max-height: calc(100vh - 2rem);
}

.gs-max-h-full {
  max-height: 100%;
}

.gs-max-h-\[calc\(100vh_-_26rem\)\] {
  max-height: calc(100vh - 26rem);
}

.gs-min-h-screen {
  min-height: 100vh;
}

.gs-w-14 {
  width: 3.5rem;
}

.gs-w-20 {
  width: 5rem;
}

.gs-w-full {
  width: 100%;
}

.gs-w-3 {
  width: .75rem;
}

.gs-w-\[71px\] {
  width: 71px;
}

.gs-w-11 {
  width: 2.75rem;
}

.gs-max-w-md {
  max-width: 28rem;
}

.gs-max-w-none {
  max-width: none;
}

.gs-flex-1 {
  flex: 1;
}

.gs-flex-none {
  flex: none;
}

.gs-shrink-0 {
  flex-shrink: 0;
}

.gs-basis-full {
  flex-basis: 100%;
}

.gs-basis-1\/2 {
  flex-basis: 50%;
}

.gs-basis-2\/5 {
  flex-basis: 40%;
}

.gs-basis-3\/5 {
  flex-basis: 60%;
}

.gs-scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.gs-scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.gs-transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

@keyframes gs-pulse {
  50% {
    opacity: .5;
  }
}

.gs-animate-pulse {
  animation: gs-pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
}

.gs-cursor-pointer {
  cursor: pointer;
}

.gs-list-inside {
  list-style-position: inside;
}

.gs-list-disc {
  list-style-type: disc;
}

.gs-appearance-none {
  appearance: none;
}

.gs-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.gs-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.gs-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.gs-flex-col {
  flex-direction: column;
}

.gs-flex-wrap {
  flex-wrap: wrap;
}

.gs-items-start {
  align-items: flex-start;
}

.gs-items-end {
  align-items: flex-end;
}

.gs-items-center {
  align-items: center;
}

.gs-justify-start {
  justify-content: flex-start;
}

.gs-justify-end {
  justify-content: flex-end;
}

.gs-justify-center {
  justify-content: center;
}

.gs-justify-between {
  justify-content: space-between;
}

.gs-gap-4 {
  gap: 1rem;
}

.gs-gap-2 {
  gap: .5rem;
}

.gs-gap-5 {
  gap: 1.25rem;
}

.gs-gap-x-1 {
  column-gap: .25rem;
}

.gs-gap-y-4 {
  row-gap: 1rem;
}

.gs-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse) );
}

.gs-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse) );
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse) );
}

.gs-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse) );
}

.gs-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse) );
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse) );
}

.gs-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse) ) );
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse) );
}

.gs-overflow-y-auto {
  overflow-y: auto;
}

.gs-overflow-y-hidden {
  overflow-y: hidden;
}

.gs-rounded-xl {
  border-radius: .75rem;
}

.gs-rounded-md {
  border-radius: .375rem;
}

.gs-rounded-full {
  border-radius: 9999px;
}

.gs-rounded {
  border-radius: .25rem;
}

.gs-rounded-3xl {
  border-radius: 1.5rem;
}

.gs-rounded-sm {
  border-radius: .125rem;
}

.gs-rounded-lg {
  border-radius: .5rem;
}

.gs-rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.gs-border {
  border-width: 1px;
}

.gs-border-0 {
  border-width: 0;
}

.gs-border-t {
  border-top-width: 1px;
}

.gs-border-none {
  border-style: none;
}

.gs-border-beige-500 {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 211 / var(--tw-border-opacity) );
}

.gs-border-beige-400 {
  --tw-border-opacity: 1;
  border-color: rgb(160 160 158 / var(--tw-border-opacity) );
}

.gs-border-stone-800 {
  --tw-border-opacity: 1;
  border-color: rgb(41 37 36 / var(--tw-border-opacity) );
}

.gs-border-stone-500 {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.gs-border-blueslate {
  --tw-border-opacity: 1;
  border-color: rgb(56 91 111 / var(--tw-border-opacity) );
}

.gs-border-beige-600 {
  --tw-border-opacity: 1;
  border-color: rgb(122 122 122 / var(--tw-border-opacity) );
}

.gs-border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
}

.gs-border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.gs-bg-beige-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 252 251 / var(--tw-bg-opacity) );
}

.gs-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.gs-bg-beige-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 217 211 / var(--tw-bg-opacity) );
}

.gs-bg-beige-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity) );
}

.gs-bg-beige-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 238 234 / var(--tw-bg-opacity) );
}

.gs-bg-blueslate {
  --tw-bg-opacity: 1;
  background-color: rgb(56 91 111 / var(--tw-bg-opacity) );
}

.gs-bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity) );
}

.gs-bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity) );
}

.gs-bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.gs-from-green-100 {
  --tw-gradient-from: #dcfce7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dcfce700);
}

.gs-to-cyan-100 {
  --tw-gradient-to: #cffafe;
}

.gs-p-10 {
  padding: 2.5rem;
}

.gs-p-6 {
  padding: 1.5rem;
}

.gs-p-2 {
  padding: .5rem;
}

.gs-p-5 {
  padding: 1.25rem;
}

.gs-p-1 {
  padding: .25rem;
}

.gs-p-8 {
  padding: 2rem;
}

.gs-py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.gs-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.gs-py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.gs-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.gs-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.gs-px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.gs-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.gs-px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.gs-px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.gs-pt-8 {
  padding-top: 2rem;
}

.gs-pb-4 {
  padding-bottom: 1rem;
}

.gs-pt-4 {
  padding-top: 1rem;
}

.gs-pl-5 {
  padding-left: 1.25rem;
}

.gs-pl-12 {
  padding-left: 3rem;
}

.gs-pr-3 {
  padding-right: .75rem;
}

.gs-text-center {
  text-align: center;
}

.gs-text-right {
  text-align: right;
}

.gs-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.gs-text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.gs-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.gs-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.gs-text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.gs-text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.gs-text-\[15px\] {
  font-size: 15px;
}

.gs-text-\[16px\] {
  font-size: 16px;
}

.gs-font-medium {
  font-weight: 500;
}

.gs-font-bold {
  font-weight: 700;
}

.gs-text-twilight-900 {
  --tw-text-opacity: 1;
  color: rgb(10 36 48 / var(--tw-text-opacity) );
}

.gs-text-beige-400 {
  --tw-text-opacity: 1;
  color: rgb(160 160 158 / var(--tw-text-opacity) );
}

.gs-text-granite-500 {
  --tw-text-opacity: 1;
  color: rgb(71 71 71 / var(--tw-text-opacity) );
}

.gs-text-stone-300 {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity) );
}

.gs-text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity) );
}

.gs-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.gs-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.gs-text-beige-600 {
  --tw-text-opacity: 1;
  color: rgb(122 122 122 / var(--tw-text-opacity) );
}

.gs-text-stone-600 {
  --tw-text-opacity: 1;
  color: rgb(87 83 78 / var(--tw-text-opacity) );
}

.gs-text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity) );
}

.gs-text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.gs-underline {
  text-decoration-line: underline;
}

.gs-opacity-0 {
  opacity: 0;
}

.gs-opacity-100 {
  opacity: 1;
}

.gs-shadow-\[0_-3px_5px_3px_rgba\(0\,0\,0\,0\.1\)\] {
  --tw-shadow: 0 -3px 5px 3px #0000001a;
  --tw-shadow-colored: 0 -3px 5px 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.gs-shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.gs-ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.gs-ring-gray-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity) );
}

.gs-ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.gs-drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.gs-transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.gs-duration-100 {
  transition-duration: .1s;
}

.gs-duration-75 {
  transition-duration: 75ms;
}

.gs-ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.gs-ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.search-list[data-v-f9dcb8] {
  background: #f9f7f4;
}

.search-list .result-detail[data-v-f9dcb8] {
  max-height: calc(100vh - 100px);
  top: 96px;
  overflow: hidden;
}

.search-suggestions li[data-v-f9dcb8] {
  font-size: 14px;
  line-height: 1.3;
}

.gs-btn-secondary[data-v-f9dcb8] {
  background-color: #9befc2 !important;
  background-image: linear-gradient(90deg, #9befc2 0%, #85f2d1 50%, #8efffa 100%) !important;
}

.no-jobs-container[data-v-f9dcb8] {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .no-jobs-container[data-v-f9dcb8] {
    width: 660px;
  }
}

.v3-infinite-loading[data-v-f9dcb8] {
  height: 100px;
}

.gagago-dual-ring-loader[data-v-f9dcb8] {
  color: #1c4c5b;
}

.gagago-dual-ring-loader[data-v-f9dcb8], .gagago-dual-ring-loader[data-v-f9dcb8]:after {
  box-sizing: border-box;
}

.gagago-dual-ring-loader[data-v-f9dcb8] {
  width: 80px;
  height: 80px;
  display: inline-block;
}

.gagago-dual-ring-loader[data-v-f9dcb8]:after {
  content: " ";
  width: 64px;
  height: 64px;
  border: 6.4px solid;
  border-color: currentColor #0000;
  border-radius: 50%;
  margin: 8px;
  animation: gagago-dual-ring-loader-f9dcb8 1.2s linear infinite;
  display: block;
}

@keyframes gagago-dual-ring-loader-f9dcb8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.first\:gs-rounded-t-xl:first-child {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.checked\:gs-bg-gray-800:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity) );
}

.hover\:gs-cursor-pointer:hover {
  cursor: pointer;
}

.hover\:gs-border-stone-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.hover\:gs-border-gray-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.hover\:gs-bg-blueslate-light:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(90 129 136 / var(--tw-bg-opacity) );
}

.hover\:gs-bg-beige-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity) );
}

.hover\:gs-bg-gradient-to-r:hover {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.hover\:gs-from-orange-400:hover {
  --tw-gradient-from: #fb923c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fb923c00);
}

.hover\:gs-to-pink-400:hover {
  --tw-gradient-to: #f472b6;
}

.hover\:gs-text-stone-500:hover {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity) );
}

.hover\:gs-text-twilight-900:hover {
  --tw-text-opacity: 1;
  color: rgb(10 36 48 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity) );
}

.hover\:gs-shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:gs-shadow-orange-400\/50:hover {
  --tw-shadow-color: #fb923c80;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:gs-ring-2:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:gs-border-stone-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.focus\:gs-outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.disabled\:gs-border-beige-500:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 211 / var(--tw-border-opacity) );
}

.disabled\:gs-text-stone-300:disabled {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity) );
}

@media (min-width: 640px) {
  .sm\:gs-pt-0 {
    padding-top: 0;
  }
}

@media (min-width: 768px) {
  .md\:gs-w-1\/2 {
    width: 50%;
  }

  .md\:gs-flex-row {
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  .lg\:gs-relative {
    position: relative;
  }

  .lg\:gs-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:gs-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:gs-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:gs-mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:gs-block {
    display: block;
  }

  .lg\:gs-flex {
    display: flex;
  }

  .lg\:gs-inline-flex {
    display: inline-flex;
  }

  .lg\:gs-hidden {
    display: none;
  }

  .lg\:gs-h-full {
    height: 100%;
  }

  .lg\:gs-w-fit {
    width: fit-content;
  }

  .lg\:gs-w-96 {
    width: 24rem;
  }

  .lg\:gs-max-w-\[260px\] {
    max-width: 260px;
  }

  .lg\:gs-basis-1\/2 {
    flex-basis: 50%;
  }

  .lg\:gs-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:gs-flex-row {
    flex-direction: row;
  }

  .lg\:gs-items-center {
    align-items: center;
  }

  .lg\:gs-justify-center {
    justify-content: center;
  }

  .lg\:gs-gap-4 {
    gap: 1rem;
  }

  .lg\:gs-rounded-xl {
    border-radius: .75rem;
  }

  .lg\:gs-border {
    border-width: 1px;
  }

  .lg\:gs-border-beige-500 {
    --tw-border-opacity: 1;
    border-color: rgb(220 217 211 / var(--tw-border-opacity) );
  }

  .lg\:gs-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  }

  .lg\:gs-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:gs-pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:gs-pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:gs-text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:gs-text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:gs-shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:gs-drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:last\:gs-rounded-b-xl:last-child {
    border-bottom-left-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:gs-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .\32 xl\:gs-col-span-1 {
    grid-column: span 1 / span 1;
  }
}

.gs-btn-primary[data-v-f9dcb8] {
  width: 100%;
  white-space: nowrap;
  text-align: center;
  background-color: #fca6ef;
  background-image: linear-gradient(90deg, #fbcd72 0%, #ffaeb4 50%, #fca6ef 100%);
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  padding: .625rem 1.25rem;
  font-size: 18px;
  line-height: 1.1;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.gs-container {
  width: 100%;
}

@media (min-width: 640px) {
  .gs-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .gs-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .gs-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .gs-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .gs-container {
    max-width: 1536px;
  }
}

.gs-prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.gs-prose :where([class~="lead"]):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.gs-prose :where(a):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.gs-prose :where(strong):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.gs-prose :where(ol):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: decimal;
}

.gs-prose :where(ol[type="A"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.gs-prose :where(ol[type="a"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.gs-prose :where(ol[type="A" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.gs-prose :where(ol[type="a" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.gs-prose :where(ol[type="I"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-roman;
}

.gs-prose :where(ol[type="i"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-roman;
}

.gs-prose :where(ol[type="I" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-roman;
}

.gs-prose :where(ol[type="i" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-roman;
}

.gs-prose :where(ol[type="1"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: decimal;
}

.gs-prose :where(ul):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: disc;
}

.gs-prose :where(ol > li):not(:where([class~="gs-not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.gs-prose :where(ul > li):not(:where([class~="gs-not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.gs-prose :where(hr):not(:where([class~="gs-not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.gs-prose :where(blockquote):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.gs-prose :where(blockquote p:first-of-type):not(:where([class~="gs-not-prose"] *)):before {
  content: open-quote;
}

.gs-prose :where(blockquote p:last-of-type):not(:where([class~="gs-not-prose"] *)):after {
  content: close-quote;
}

.gs-prose :where(h1):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.gs-prose :where(h1 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 900;
}

.gs-prose :where(h2):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.gs-prose :where(h2 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 800;
}

.gs-prose :where(h3):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.gs-prose :where(h3 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 700;
}

.gs-prose :where(h4):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.gs-prose :where(h4 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 700;
}

.gs-prose :where(figure > *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.gs-prose :where(figcaption):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.gs-prose :where(code):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.gs-prose :where(code):not(:where([class~="gs-not-prose"] *)):before, .gs-prose :where(code):not(:where([class~="gs-not-prose"] *)):after {
  content: "`";
}

.gs-prose :where(a code):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-links);
}

.gs-prose :where(pre):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)):before, .gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)):after {
  content: none;
}

.gs-prose :where(table):not(:where([class~="gs-not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.gs-prose :where(thead):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.gs-prose :where(thead th):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.gs-prose :where(tbody tr):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.gs-prose :where(tbody tr:last-child):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 0;
}

.gs-prose :where(tbody td):not(:where([class~="gs-not-prose"] *)) {
  vertical-align: baseline;
  padding: .571429em;
}

.gs-prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.gs-prose :where(p):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.gs-prose :where(img):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(video):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(figure):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(h2 code):not(:where([class~="gs-not-prose"] *)) {
  font-size: .875em;
}

.gs-prose :where(h3 code):not(:where([class~="gs-not-prose"] *)) {
  font-size: .9em;
}

.gs-prose :where(li):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.gs-prose :where(ol > li):not(:where([class~="gs-not-prose"] *)) {
  padding-left: .375em;
}

.gs-prose :where(ul > li):not(:where([class~="gs-not-prose"] *)) {
  padding-left: .375em;
}

.gs-prose > :where(ul > li p):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.gs-prose > :where(ul > li > :first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
}

.gs-prose > :where(ul > li > :last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.gs-prose > :where(ol > li > :first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
}

.gs-prose > :where(ol > li > :last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.gs-prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.gs-prose :where(hr + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h2 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h3 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h4 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(thead th:first-child):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 0;
}

.gs-prose :where(thead th:last-child):not(:where([class~="gs-not-prose"] *)) {
  padding-right: 0;
}

.gs-prose :where(tbody td:first-child):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 0;
}

.gs-prose :where(tbody td:last-child):not(:where([class~="gs-not-prose"] *)) {
  padding-right: 0;
}

.gs-prose > :where(:first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose > :where(:last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 0;
}

.gm-style-iw button[aria-label="Close"] {
  pointer-events: none;
  opacity: 0;
}

.gs-static {
  position: static;
}

.gs-fixed {
  position: fixed;
}

.gs-absolute {
  position: absolute;
}

.gs-relative {
  position: relative;
}

.gs-sticky {
  position: sticky;
}

.gs-inset-y-0 {
  top: 0;
  bottom: 0;
}

.gs-inset-y-1 {
  top: .25rem;
  bottom: .25rem;
}

.gs-top-0 {
  top: 0;
}

.gs-left-0 {
  left: 0;
}

.gs-right-2 {
  right: .5rem;
}

.gs-top-2 {
  top: .5rem;
}

.gs-bottom-0 {
  bottom: 0;
}

.gs-right-3 {
  right: .75rem;
}

.gs-top-3 {
  top: .75rem;
}

.gs-left-3 {
  left: .75rem;
}

.gs-left-3\.5 {
  left: .875rem;
}

.gs-z-20 {
  z-index: 20;
}

.gs-z-10 {
  z-index: 10;
}

.gs-z-0 {
  z-index: 0;
}

.gs-col-span-2 {
  grid-column: span 2 / span 2;
}

.gs-col-span-1 {
  grid-column: span 1 / span 1;
}

.gs-m-6 {
  margin: 1.5rem;
}

.gs-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.-gs-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.gs-my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.gs-my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.gs-mt-10 {
  margin-top: 2.5rem;
}

.gs-mt-14 {
  margin-top: 3.5rem;
}

.gs-mt-5 {
  margin-top: 1.25rem;
}

.gs-mt-2 {
  margin-top: .5rem;
}

.gs-mt-1 {
  margin-top: .25rem;
}

.gs-mt-4 {
  margin-top: 1rem;
}

.gs-mr-4 {
  margin-right: 1rem;
}

.gs-mt-6 {
  margin-top: 1.5rem;
}

.gs-ml-2 {
  margin-left: .5rem;
}

.gs-mt-7 {
  margin-top: 1.75rem;
}

.gs-mt-3 {
  margin-top: .75rem;
}

.gs-mr-2 {
  margin-right: .5rem;
}

.gs-ml-5 {
  margin-left: 1.25rem;
}

.gs-ml-3 {
  margin-left: .75rem;
}

.gs-mt-16 {
  margin-top: 4rem;
}

.gs--mt-16 {
  margin-top: -4rem;
}

.gs-block {
  display: block;
}

.gs-flex {
  display: flex;
}

.gs-inline-flex {
  display: inline-flex;
}

.gs-grid {
  display: grid;
}

.gs-hidden {
  display: none;
}

.gs-h-14 {
  height: 3.5rem;
}

.gs-h-11 {
  height: 2.75rem;
}

.gs-h-2 {
  height: .5rem;
}

.gs-h-5 {
  height: 1.25rem;
}

.gs-h-12 {
  height: 3rem;
}

.gs-h-3 {
  height: .75rem;
}

.gs-h-16 {
  height: 4rem;
}

.gs-h-screen {
  height: 100vh;
}

.gs-h-\[calc\(100vh_-_10\.5rem\)\] {
  height: calc(100vh - 10.5rem);
}

.gs-h-\[56px\] {
  height: 56px;
}

.gs-h-1 {
  height: .25rem;
}

.gs-max-h-screen {
  max-height: 100vh;
}

.gs-max-h-\[calc\(100vh_-_2rem\)\] {
  max-height: calc(100vh - 2rem);
}

.gs-max-h-full {
  max-height: 100%;
}

.gs-max-h-\[calc\(100vh_-_26rem\)\] {
  max-height: calc(100vh - 26rem);
}

.gs-min-h-screen {
  min-height: 100vh;
}

.gs-w-14 {
  width: 3.5rem;
}

.gs-w-20 {
  width: 5rem;
}

.gs-w-full {
  width: 100%;
}

.gs-w-3 {
  width: .75rem;
}

.gs-w-\[71px\] {
  width: 71px;
}

.gs-w-11 {
  width: 2.75rem;
}

.gs-max-w-md {
  max-width: 28rem;
}

.gs-max-w-none {
  max-width: none;
}

.gs-flex-1 {
  flex: 1;
}

.gs-flex-none {
  flex: none;
}

.gs-shrink-0 {
  flex-shrink: 0;
}

.gs-basis-full {
  flex-basis: 100%;
}

.gs-basis-1\/2 {
  flex-basis: 50%;
}

.gs-basis-2\/5 {
  flex-basis: 40%;
}

.gs-basis-3\/5 {
  flex-basis: 60%;
}

.gs-scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.gs-scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.gs-transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

@keyframes gs-pulse {
  50% {
    opacity: .5;
  }
}

.gs-animate-pulse {
  animation: gs-pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
}

.gs-cursor-pointer {
  cursor: pointer;
}

.gs-list-inside {
  list-style-position: inside;
}

.gs-list-disc {
  list-style-type: disc;
}

.gs-appearance-none {
  appearance: none;
}

.gs-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.gs-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.gs-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.gs-flex-col {
  flex-direction: column;
}

.gs-flex-wrap {
  flex-wrap: wrap;
}

.gs-items-start {
  align-items: flex-start;
}

.gs-items-end {
  align-items: flex-end;
}

.gs-items-center {
  align-items: center;
}

.gs-justify-start {
  justify-content: flex-start;
}

.gs-justify-end {
  justify-content: flex-end;
}

.gs-justify-center {
  justify-content: center;
}

.gs-justify-between {
  justify-content: space-between;
}

.gs-gap-4 {
  gap: 1rem;
}

.gs-gap-2 {
  gap: .5rem;
}

.gs-gap-5 {
  gap: 1.25rem;
}

.gs-gap-x-1 {
  column-gap: .25rem;
}

.gs-gap-y-4 {
  row-gap: 1rem;
}

.gs-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse) );
}

.gs-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse) );
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse) );
}

.gs-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse) );
}

.gs-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse) );
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse) );
}

.gs-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse) ) );
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse) );
}

.gs-overflow-y-auto {
  overflow-y: auto;
}

.gs-overflow-y-hidden {
  overflow-y: hidden;
}

.gs-rounded-xl {
  border-radius: .75rem;
}

.gs-rounded-md {
  border-radius: .375rem;
}

.gs-rounded-full {
  border-radius: 9999px;
}

.gs-rounded {
  border-radius: .25rem;
}

.gs-rounded-3xl {
  border-radius: 1.5rem;
}

.gs-rounded-sm {
  border-radius: .125rem;
}

.gs-rounded-lg {
  border-radius: .5rem;
}

.gs-rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.gs-border {
  border-width: 1px;
}

.gs-border-0 {
  border-width: 0;
}

.gs-border-t {
  border-top-width: 1px;
}

.gs-border-none {
  border-style: none;
}

.gs-border-beige-500 {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 211 / var(--tw-border-opacity) );
}

.gs-border-beige-400 {
  --tw-border-opacity: 1;
  border-color: rgb(160 160 158 / var(--tw-border-opacity) );
}

.gs-border-stone-800 {
  --tw-border-opacity: 1;
  border-color: rgb(41 37 36 / var(--tw-border-opacity) );
}

.gs-border-stone-500 {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.gs-border-blueslate {
  --tw-border-opacity: 1;
  border-color: rgb(56 91 111 / var(--tw-border-opacity) );
}

.gs-border-beige-600 {
  --tw-border-opacity: 1;
  border-color: rgb(122 122 122 / var(--tw-border-opacity) );
}

.gs-border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
}

.gs-border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.gs-bg-beige-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 252 251 / var(--tw-bg-opacity) );
}

.gs-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.gs-bg-beige-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 217 211 / var(--tw-bg-opacity) );
}

.gs-bg-beige-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity) );
}

.gs-bg-beige-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 238 234 / var(--tw-bg-opacity) );
}

.gs-bg-blueslate {
  --tw-bg-opacity: 1;
  background-color: rgb(56 91 111 / var(--tw-bg-opacity) );
}

.gs-bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity) );
}

.gs-bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity) );
}

.gs-bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.gs-from-green-100 {
  --tw-gradient-from: #dcfce7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dcfce700);
}

.gs-to-cyan-100 {
  --tw-gradient-to: #cffafe;
}

.gs-p-10 {
  padding: 2.5rem;
}

.gs-p-6 {
  padding: 1.5rem;
}

.gs-p-2 {
  padding: .5rem;
}

.gs-p-5 {
  padding: 1.25rem;
}

.gs-p-1 {
  padding: .25rem;
}

.gs-p-8 {
  padding: 2rem;
}

.gs-py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.gs-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.gs-py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.gs-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.gs-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.gs-px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.gs-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.gs-px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.gs-px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.gs-pt-8 {
  padding-top: 2rem;
}

.gs-pb-4 {
  padding-bottom: 1rem;
}

.gs-pt-4 {
  padding-top: 1rem;
}

.gs-pl-5 {
  padding-left: 1.25rem;
}

.gs-pl-12 {
  padding-left: 3rem;
}

.gs-pr-3 {
  padding-right: .75rem;
}

.gs-text-center {
  text-align: center;
}

.gs-text-right {
  text-align: right;
}

.gs-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.gs-text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.gs-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.gs-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.gs-text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.gs-text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.gs-text-\[15px\] {
  font-size: 15px;
}

.gs-text-\[16px\] {
  font-size: 16px;
}

.gs-font-medium {
  font-weight: 500;
}

.gs-font-bold {
  font-weight: 700;
}

.gs-text-twilight-900 {
  --tw-text-opacity: 1;
  color: rgb(10 36 48 / var(--tw-text-opacity) );
}

.gs-text-beige-400 {
  --tw-text-opacity: 1;
  color: rgb(160 160 158 / var(--tw-text-opacity) );
}

.gs-text-granite-500 {
  --tw-text-opacity: 1;
  color: rgb(71 71 71 / var(--tw-text-opacity) );
}

.gs-text-stone-300 {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity) );
}

.gs-text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity) );
}

.gs-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.gs-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.gs-text-beige-600 {
  --tw-text-opacity: 1;
  color: rgb(122 122 122 / var(--tw-text-opacity) );
}

.gs-text-stone-600 {
  --tw-text-opacity: 1;
  color: rgb(87 83 78 / var(--tw-text-opacity) );
}

.gs-text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity) );
}

.gs-text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.gs-underline {
  text-decoration-line: underline;
}

.gs-opacity-0 {
  opacity: 0;
}

.gs-opacity-100 {
  opacity: 1;
}

.gs-shadow-\[0_-3px_5px_3px_rgba\(0\,0\,0\,0\.1\)\] {
  --tw-shadow: 0 -3px 5px 3px #0000001a;
  --tw-shadow-colored: 0 -3px 5px 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.gs-shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.gs-ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.gs-ring-gray-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity) );
}

.gs-ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.gs-drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.gs-transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.gs-duration-100 {
  transition-duration: .1s;
}

.gs-duration-75 {
  transition-duration: 75ms;
}

.gs-ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.gs-ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.gagago-map-loading-overlay {
  z-index: 100;
  background: #0003;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.gagago-map-loading-ring {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.gagago-map-loading-ring div {
  box-sizing: border-box;
  width: 64px;
  height: 64px;
  border: 8px solid #0000;
  border-top-color: #fff;
  border-radius: 50%;
  margin: 8px;
  animation: gagago-map-loading-ring 1.2s cubic-bezier(.5, 0, .5, 1) infinite;
  display: block;
  position: absolute;
}

.gagago-map-loading-ring div:nth-child(1) {
  animation-delay: -.45s;
}

.gagago-map-loading-ring div:nth-child(2) {
  animation-delay: -.3s;
}

.gagago-map-loading-ring div:nth-child(3) {
  animation-delay: -.15s;
}

@keyframes gagago-map-loading-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.first\:gs-rounded-t-xl:first-child {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.checked\:gs-bg-gray-800:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity) );
}

.hover\:gs-cursor-pointer:hover {
  cursor: pointer;
}

.hover\:gs-border-stone-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.hover\:gs-border-gray-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.hover\:gs-bg-blueslate-light:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(90 129 136 / var(--tw-bg-opacity) );
}

.hover\:gs-bg-beige-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity) );
}

.hover\:gs-bg-gradient-to-r:hover {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.hover\:gs-from-orange-400:hover {
  --tw-gradient-from: #fb923c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fb923c00);
}

.hover\:gs-to-pink-400:hover {
  --tw-gradient-to: #f472b6;
}

.hover\:gs-text-stone-500:hover {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity) );
}

.hover\:gs-text-twilight-900:hover {
  --tw-text-opacity: 1;
  color: rgb(10 36 48 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity) );
}

.hover\:gs-shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:gs-shadow-orange-400\/50:hover {
  --tw-shadow-color: #fb923c80;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:gs-ring-2:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:gs-border-stone-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.focus\:gs-outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.disabled\:gs-border-beige-500:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 211 / var(--tw-border-opacity) );
}

.disabled\:gs-text-stone-300:disabled {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity) );
}

@media (min-width: 640px) {
  .sm\:gs-pt-0 {
    padding-top: 0;
  }
}

@media (min-width: 768px) {
  .md\:gs-w-1\/2 {
    width: 50%;
  }

  .md\:gs-flex-row {
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  .lg\:gs-relative {
    position: relative;
  }

  .lg\:gs-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:gs-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:gs-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:gs-mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:gs-block {
    display: block;
  }

  .lg\:gs-flex {
    display: flex;
  }

  .lg\:gs-inline-flex {
    display: inline-flex;
  }

  .lg\:gs-hidden {
    display: none;
  }

  .lg\:gs-h-full {
    height: 100%;
  }

  .lg\:gs-w-fit {
    width: fit-content;
  }

  .lg\:gs-w-96 {
    width: 24rem;
  }

  .lg\:gs-max-w-\[260px\] {
    max-width: 260px;
  }

  .lg\:gs-basis-1\/2 {
    flex-basis: 50%;
  }

  .lg\:gs-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:gs-flex-row {
    flex-direction: row;
  }

  .lg\:gs-items-center {
    align-items: center;
  }

  .lg\:gs-justify-center {
    justify-content: center;
  }

  .lg\:gs-gap-4 {
    gap: 1rem;
  }

  .lg\:gs-rounded-xl {
    border-radius: .75rem;
  }

  .lg\:gs-border {
    border-width: 1px;
  }

  .lg\:gs-border-beige-500 {
    --tw-border-opacity: 1;
    border-color: rgb(220 217 211 / var(--tw-border-opacity) );
  }

  .lg\:gs-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  }

  .lg\:gs-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:gs-pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:gs-pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:gs-text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:gs-text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:gs-shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:gs-drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:last\:gs-rounded-b-xl:last-child {
    border-bottom-left-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:gs-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .\32 xl\:gs-col-span-1 {
    grid-column: span 1 / span 1;
  }
}

.input-error[data-v-ac0110] {
  border: 1px solid red !important;
}

.text-red[data-v-ac0110] {
  color: red !important;
}

.overlay[data-v-ac0110] {
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity) );
  --tw-bg-opacity: .5;
  height: 100%;
  width: 100%;
  z-index: 999;
  position: fixed;
  inset: 0;
}

.dialog[data-v-ac0110] {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity) );
  max-width: 100%;
  width: 40rem;
  border-radius: .3rem;
  margin-top: 2.5rem;
  margin-left: auto;
  margin-right: auto;
}

.content-inner[data-v-ac0110] {
  padding: 3rem 1.75rem;
}

.title[data-v-ac0110] {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity) );
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  font-family: Athletics, sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
  display: flex;
}

#alertName[data-v-ac0110] {
  color: #474747;
  border: 1px solid #dcd9d3;
  border-radius: 5px;
  padding: .5rem 2rem .5rem .8rem;
  font-family: DM Sans, sans-serif;
  font-size: .875rem;
}

#alertName[data-v-ac0110]::placeholder {
  color: #a0a09e;
}

#alertFreq[data-v-ac0110] {
  color: #474747;
  appearance: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #dcd9d3;
  border-radius: 5px;
  padding: .5rem 2rem .5rem .8rem;
  font-family: DM Sans, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

.description[data-v-ac0110] {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity) );
  margin-bottom: 1rem;
  font-family: DM Sans, sans-serif;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.25rem;
}

.footer[data-v-ac0110] {
  border-top: 1px solid #dcd9d3;
  justify-content: flex-end;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
}

.footer-inner[data-v-ac0110] {
  padding: 0 1.75rem;
}

.cancel[data-v-ac0110] {
  border-radius: .75rem;
  margin-right: 1rem;
  font-family: DM Sans, sans-serif;
  font-weight: 500;
}

.cancel[data-v-ac0110]:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.cancel[data-v-ac0110] {
  padding: .75rem 1rem;
}

.cancel[data-v-ac0110]:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity) );
  --tw-ring-opacity: .5;
}

.cancel[data-v-ac0110] {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity) );
  user-select: none;
}

.cancel[data-v-ac0110]:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity) );
}

.confirm[data-v-ac0110] {
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  color: #fff;
  background-color: #0a2430;
  border: 1px solid #0000;
  border-radius: 25px;
  padding: .7rem 2.9rem;
  font-family: DM Sans, sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

.confirm[data-v-ac0110]:hover {
  color: #0a2430;
  background-image: linear-gradient(to left, #8efffa 0%, #85f2d1 52.2%, #9befc2 100%);
}

.info[data-v-ac0110] {
  display: inline-block;
  position: relative;
}

.info .info-message[data-v-ac0110] {
  visibility: hidden;
  color: #fff;
  z-index: 1;
  width: 18rem;
  background-color: #0a2430;
  border-radius: 5px;
  margin-left: -240px;
  padding: 1rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5rem;
  position: absolute;
  top: 200%;
  left: 50%;
}

.info .info-message[data-v-ac0110]:after {
  content: " ";
  border: 5px solid #0000;
  border-bottom-color: #000;
  margin-left: -5px;
  position: absolute;
  bottom: 100%;
  left: 83%;
}

@media (min-width: 576px) {
  .info .info-message[data-v-ac0110] {
    width: 25rem;
    margin-left: -200px;
  }

  .info .info-message[data-v-ac0110]:after {
    left: 50%;
  }
}

.info:hover .info-message[data-v-ac0110] {
  visibility: visible;
}

.overlay[data-v-25350d] {
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity) );
  --tw-bg-opacity: .5;
  height: 100%;
  width: 100%;
  z-index: 10;
  position: fixed;
  inset: 0;
}

.dialog[data-v-25350d] {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity) );
  max-width: 100%;
  width: 31rem;
  border-radius: .3rem;
  margin-top: 2.5rem;
  margin-left: auto;
  margin-right: auto;
}

.content[data-v-25350d] {
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: .3rem;
  flex-direction: column;
  display: flex;
  position: relative;
}

.dialog-header[data-v-25350d] {
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: 3rem 1rem 1rem;
  display: flex;
}

.dialog-body[data-v-25350d] {
  flex-direction: column;
  flex: auto;
  align-items: center;
  padding: 1rem 0 0;
  display: flex;
  position: relative;
}

.dialog-header.square[data-v-25350d] {
  background-image: url("mask-square-background.png");
  background-size: cover;
}

.dialog-footer[data-v-25350d] {
  flex-flow: column wrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: .75rem .75rem 2rem;
  display: flex;
}

.dialog-footer > [data-v-25350d] {
  margin: .25rem;
}

.continue[data-v-25350d] {
  color: #fff;
  background-color: #0a2430;
  padding: .65rem 2rem;
  font-size: 1.125rem;
  font-weight: 500;
}

#view-searches[data-v-25350d] {
  color: #a0a09e;
  background-color: #0000;
  padding: .375rem .75rem;
  font-size: .875rem;
  font-weight: 400;
}

.continue[data-v-25350d], #view-searches[data-v-25350d] {
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid #0000;
  border-radius: 25px;
  font-family: DM Sans, sans-serif;
  line-height: 1.5;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

.title[data-v-25350d] {
  --tw-text-opacity: 1;
  color: #0a2430;
  margin-bottom: .5rem;
  font-family: Athletics, sans-serif;
  font-size: calc(1.3rem + .6vw);
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .title[data-v-25350d] {
    font-size: 2.25rem;
  }
}

.description[data-v-25350d] {
  text-align: center;
  color: #474747;
  margin-bottom: 0;
  font-family: DM Sans, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
}

.img-fluid[data-v-25350d] {
  max-width: 100%;
  height: auto;
}

.overlay[data-v-944cca] {
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity) );
  --tw-bg-opacity: .5;
  height: 100%;
  width: 100%;
  z-index: 10;
  position: fixed;
  inset: 0;
}

.dialog[data-v-944cca] {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity) );
  max-width: 100%;
  width: 31rem;
  border-radius: .3rem;
  margin-top: 2.5rem;
  margin-left: auto;
  margin-right: auto;
}

.content[data-v-944cca] {
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: .3rem;
  flex-direction: column;
  display: flex;
  position: relative;
}

.dialog-header[data-v-944cca] {
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: 3rem 1rem 1rem;
  display: flex;
}

.dialog-body[data-v-944cca] {
  flex-direction: column;
  flex: auto;
  align-items: center;
  padding: 1rem 0 0;
  display: flex;
  position: relative;
}

.dialog-header.square[data-v-944cca] {
  background-image: url("mask-square-background.png");
  background-size: cover;
}

.dialog-footer[data-v-944cca] {
  flex-flow: column wrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: .75rem .75rem 2rem;
  display: flex;
}

.dialog-footer-buttons[data-v-944cca] {
  flex-flow: wrap;
  gap: 10px;
  display: flex;
}

.dialog-footer > [data-v-944cca] {
  margin: .25rem;
}

.continue[data-v-944cca] {
  color: #fff;
  background-color: #0a2430;
  padding: .65rem 2rem;
  font-size: 1.125rem;
  font-weight: 500;
}

.register[data-v-944cca] {
  color: #fff;
  background-color: #757575;
  padding: .65rem 2rem;
  font-size: 1.125rem;
  font-weight: 500;
}

#view-searches[data-v-944cca] {
  color: #a0a09e;
  background-color: #0000;
  padding: .375rem .75rem;
  font-size: .875rem;
  font-weight: 400;
}

.continue[data-v-944cca], .register[data-v-944cca], #view-searches[data-v-944cca] {
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid #0000;
  border-radius: 25px;
  font-family: DM Sans, sans-serif;
  line-height: 1.5;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

.title[data-v-944cca] {
  --tw-text-opacity: 1;
  color: #0a2430;
  margin-bottom: .5rem;
  font-family: Athletics, sans-serif;
  font-size: calc(1.3rem + .6vw);
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .title[data-v-944cca] {
    font-size: 2.25rem;
  }
}

.description[data-v-944cca] {
  text-align: center;
  color: #474747;
  margin-bottom: 0;
  font-family: DM Sans, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
}

.img-fluid[data-v-944cca] {
  max-width: 100%;
  height: auto;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.gs-container {
  width: 100%;
}

@media (min-width: 640px) {
  .gs-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .gs-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .gs-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .gs-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .gs-container {
    max-width: 1536px;
  }
}

.gs-prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.gs-prose :where([class~="lead"]):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.gs-prose :where(a):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.gs-prose :where(strong):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.gs-prose :where(ol):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: decimal;
}

.gs-prose :where(ol[type="A"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.gs-prose :where(ol[type="a"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.gs-prose :where(ol[type="A" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.gs-prose :where(ol[type="a" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.gs-prose :where(ol[type="I"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-roman;
}

.gs-prose :where(ol[type="i"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-roman;
}

.gs-prose :where(ol[type="I" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-roman;
}

.gs-prose :where(ol[type="i" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-roman;
}

.gs-prose :where(ol[type="1"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: decimal;
}

.gs-prose :where(ul):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: disc;
}

.gs-prose :where(ol > li):not(:where([class~="gs-not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.gs-prose :where(ul > li):not(:where([class~="gs-not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.gs-prose :where(hr):not(:where([class~="gs-not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.gs-prose :where(blockquote):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.gs-prose :where(blockquote p:first-of-type):not(:where([class~="gs-not-prose"] *)):before {
  content: open-quote;
}

.gs-prose :where(blockquote p:last-of-type):not(:where([class~="gs-not-prose"] *)):after {
  content: close-quote;
}

.gs-prose :where(h1):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.gs-prose :where(h1 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 900;
}

.gs-prose :where(h2):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.gs-prose :where(h2 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 800;
}

.gs-prose :where(h3):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.gs-prose :where(h3 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 700;
}

.gs-prose :where(h4):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.gs-prose :where(h4 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 700;
}

.gs-prose :where(figure > *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.gs-prose :where(figcaption):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.gs-prose :where(code):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.gs-prose :where(code):not(:where([class~="gs-not-prose"] *)):before, .gs-prose :where(code):not(:where([class~="gs-not-prose"] *)):after {
  content: "`";
}

.gs-prose :where(a code):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-links);
}

.gs-prose :where(pre):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)):before, .gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)):after {
  content: none;
}

.gs-prose :where(table):not(:where([class~="gs-not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.gs-prose :where(thead):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.gs-prose :where(thead th):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.gs-prose :where(tbody tr):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.gs-prose :where(tbody tr:last-child):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 0;
}

.gs-prose :where(tbody td):not(:where([class~="gs-not-prose"] *)) {
  vertical-align: baseline;
  padding: .571429em;
}

.gs-prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.gs-prose :where(p):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.gs-prose :where(img):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(video):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(figure):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(h2 code):not(:where([class~="gs-not-prose"] *)) {
  font-size: .875em;
}

.gs-prose :where(h3 code):not(:where([class~="gs-not-prose"] *)) {
  font-size: .9em;
}

.gs-prose :where(li):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.gs-prose :where(ol > li):not(:where([class~="gs-not-prose"] *)) {
  padding-left: .375em;
}

.gs-prose :where(ul > li):not(:where([class~="gs-not-prose"] *)) {
  padding-left: .375em;
}

.gs-prose > :where(ul > li p):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.gs-prose > :where(ul > li > :first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
}

.gs-prose > :where(ul > li > :last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.gs-prose > :where(ol > li > :first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
}

.gs-prose > :where(ol > li > :last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.gs-prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.gs-prose :where(hr + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h2 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h3 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h4 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(thead th:first-child):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 0;
}

.gs-prose :where(thead th:last-child):not(:where([class~="gs-not-prose"] *)) {
  padding-right: 0;
}

.gs-prose :where(tbody td:first-child):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 0;
}

.gs-prose :where(tbody td:last-child):not(:where([class~="gs-not-prose"] *)) {
  padding-right: 0;
}

.gs-prose > :where(:first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose > :where(:last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 0;
}

.gs-static {
  position: static;
}

.gs-fixed {
  position: fixed;
}

.gs-absolute {
  position: absolute;
}

.gs-relative {
  position: relative;
}

.gs-sticky {
  position: sticky;
}

.gs-inset-y-0 {
  top: 0;
  bottom: 0;
}

.gs-inset-y-1 {
  top: .25rem;
  bottom: .25rem;
}

.gs-top-0 {
  top: 0;
}

.gs-left-0 {
  left: 0;
}

.gs-right-2 {
  right: .5rem;
}

.gs-top-2 {
  top: .5rem;
}

.gs-bottom-0 {
  bottom: 0;
}

.gs-right-3 {
  right: .75rem;
}

.gs-top-3 {
  top: .75rem;
}

.gs-left-3 {
  left: .75rem;
}

.gs-left-3\.5 {
  left: .875rem;
}

.gs-z-20 {
  z-index: 20;
}

.gs-z-10 {
  z-index: 10;
}

.gs-z-0 {
  z-index: 0;
}

.gs-col-span-2 {
  grid-column: span 2 / span 2;
}

.gs-col-span-1 {
  grid-column: span 1 / span 1;
}

.gs-m-6 {
  margin: 1.5rem;
}

.gs-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.-gs-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.gs-my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.gs-my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.gs-mt-10 {
  margin-top: 2.5rem;
}

.gs-mt-14 {
  margin-top: 3.5rem;
}

.gs-mt-5 {
  margin-top: 1.25rem;
}

.gs-mt-2 {
  margin-top: .5rem;
}

.gs-mt-1 {
  margin-top: .25rem;
}

.gs-mt-4 {
  margin-top: 1rem;
}

.gs-mr-4 {
  margin-right: 1rem;
}

.gs-mt-6 {
  margin-top: 1.5rem;
}

.gs-ml-2 {
  margin-left: .5rem;
}

.gs-mt-7 {
  margin-top: 1.75rem;
}

.gs-mt-3 {
  margin-top: .75rem;
}

.gs-mr-2 {
  margin-right: .5rem;
}

.gs-ml-5 {
  margin-left: 1.25rem;
}

.gs-ml-3 {
  margin-left: .75rem;
}

.gs-mt-16 {
  margin-top: 4rem;
}

.gs--mt-16 {
  margin-top: -4rem;
}

.gs-block {
  display: block;
}

.gs-flex {
  display: flex;
}

.gs-inline-flex {
  display: inline-flex;
}

.gs-grid {
  display: grid;
}

.gs-hidden {
  display: none;
}

.gs-h-14 {
  height: 3.5rem;
}

.gs-h-11 {
  height: 2.75rem;
}

.gs-h-2 {
  height: .5rem;
}

.gs-h-5 {
  height: 1.25rem;
}

.gs-h-12 {
  height: 3rem;
}

.gs-h-3 {
  height: .75rem;
}

.gs-h-16 {
  height: 4rem;
}

.gs-h-screen {
  height: 100vh;
}

.gs-h-\[calc\(100vh_-_10\.5rem\)\] {
  height: calc(100vh - 10.5rem);
}

.gs-h-\[56px\] {
  height: 56px;
}

.gs-h-1 {
  height: .25rem;
}

.gs-max-h-screen {
  max-height: 100vh;
}

.gs-max-h-\[calc\(100vh_-_2rem\)\] {
  max-height: calc(100vh - 2rem);
}

.gs-max-h-full {
  max-height: 100%;
}

.gs-max-h-\[calc\(100vh_-_26rem\)\] {
  max-height: calc(100vh - 26rem);
}

.gs-min-h-screen {
  min-height: 100vh;
}

.gs-w-14 {
  width: 3.5rem;
}

.gs-w-20 {
  width: 5rem;
}

.gs-w-full {
  width: 100%;
}

.gs-w-3 {
  width: .75rem;
}

.gs-w-\[71px\] {
  width: 71px;
}

.gs-w-11 {
  width: 2.75rem;
}

.gs-max-w-md {
  max-width: 28rem;
}

.gs-max-w-none {
  max-width: none;
}

.gs-flex-1 {
  flex: 1;
}

.gs-flex-none {
  flex: none;
}

.gs-shrink-0 {
  flex-shrink: 0;
}

.gs-basis-full {
  flex-basis: 100%;
}

.gs-basis-1\/2 {
  flex-basis: 50%;
}

.gs-basis-2\/5 {
  flex-basis: 40%;
}

.gs-basis-3\/5 {
  flex-basis: 60%;
}

.gs-scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.gs-scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.gs-transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

@keyframes gs-pulse {
  50% {
    opacity: .5;
  }
}

.gs-animate-pulse {
  animation: gs-pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
}

.gs-cursor-pointer {
  cursor: pointer;
}

.gs-list-inside {
  list-style-position: inside;
}

.gs-list-disc {
  list-style-type: disc;
}

.gs-appearance-none {
  appearance: none;
}

.gs-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.gs-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.gs-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.gs-flex-col {
  flex-direction: column;
}

.gs-flex-wrap {
  flex-wrap: wrap;
}

.gs-items-start {
  align-items: flex-start;
}

.gs-items-end {
  align-items: flex-end;
}

.gs-items-center {
  align-items: center;
}

.gs-justify-start {
  justify-content: flex-start;
}

.gs-justify-end {
  justify-content: flex-end;
}

.gs-justify-center {
  justify-content: center;
}

.gs-justify-between {
  justify-content: space-between;
}

.gs-gap-4 {
  gap: 1rem;
}

.gs-gap-2 {
  gap: .5rem;
}

.gs-gap-5 {
  gap: 1.25rem;
}

.gs-gap-x-1 {
  column-gap: .25rem;
}

.gs-gap-y-4 {
  row-gap: 1rem;
}

.gs-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse) );
}

.gs-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse) );
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse) );
}

.gs-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse) );
}

.gs-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse) );
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse) );
}

.gs-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse) ) );
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse) );
}

.gs-overflow-y-auto {
  overflow-y: auto;
}

.gs-overflow-y-hidden {
  overflow-y: hidden;
}

.gs-rounded-xl {
  border-radius: .75rem;
}

.gs-rounded-md {
  border-radius: .375rem;
}

.gs-rounded-full {
  border-radius: 9999px;
}

.gs-rounded {
  border-radius: .25rem;
}

.gs-rounded-3xl {
  border-radius: 1.5rem;
}

.gs-rounded-sm {
  border-radius: .125rem;
}

.gs-rounded-lg {
  border-radius: .5rem;
}

.gs-rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.gs-border {
  border-width: 1px;
}

.gs-border-0 {
  border-width: 0;
}

.gs-border-t {
  border-top-width: 1px;
}

.gs-border-none {
  border-style: none;
}

.gs-border-beige-500 {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 211 / var(--tw-border-opacity) );
}

.gs-border-beige-400 {
  --tw-border-opacity: 1;
  border-color: rgb(160 160 158 / var(--tw-border-opacity) );
}

.gs-border-stone-800 {
  --tw-border-opacity: 1;
  border-color: rgb(41 37 36 / var(--tw-border-opacity) );
}

.gs-border-stone-500 {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.gs-border-blueslate {
  --tw-border-opacity: 1;
  border-color: rgb(56 91 111 / var(--tw-border-opacity) );
}

.gs-border-beige-600 {
  --tw-border-opacity: 1;
  border-color: rgb(122 122 122 / var(--tw-border-opacity) );
}

.gs-border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
}

.gs-border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.gs-bg-beige-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 252 251 / var(--tw-bg-opacity) );
}

.gs-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.gs-bg-beige-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 217 211 / var(--tw-bg-opacity) );
}

.gs-bg-beige-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity) );
}

.gs-bg-beige-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 238 234 / var(--tw-bg-opacity) );
}

.gs-bg-blueslate {
  --tw-bg-opacity: 1;
  background-color: rgb(56 91 111 / var(--tw-bg-opacity) );
}

.gs-bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity) );
}

.gs-bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity) );
}

.gs-bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.gs-from-green-100 {
  --tw-gradient-from: #dcfce7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dcfce700);
}

.gs-to-cyan-100 {
  --tw-gradient-to: #cffafe;
}

.gs-p-10 {
  padding: 2.5rem;
}

.gs-p-6 {
  padding: 1.5rem;
}

.gs-p-2 {
  padding: .5rem;
}

.gs-p-5 {
  padding: 1.25rem;
}

.gs-p-1 {
  padding: .25rem;
}

.gs-p-8 {
  padding: 2rem;
}

.gs-py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.gs-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.gs-py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.gs-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.gs-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.gs-px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.gs-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.gs-px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.gs-px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.gs-pt-8 {
  padding-top: 2rem;
}

.gs-pb-4 {
  padding-bottom: 1rem;
}

.gs-pt-4 {
  padding-top: 1rem;
}

.gs-pl-5 {
  padding-left: 1.25rem;
}

.gs-pl-12 {
  padding-left: 3rem;
}

.gs-pr-3 {
  padding-right: .75rem;
}

.gs-text-center {
  text-align: center;
}

.gs-text-right {
  text-align: right;
}

.gs-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.gs-text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.gs-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.gs-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.gs-text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.gs-text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.gs-text-\[15px\] {
  font-size: 15px;
}

.gs-text-\[16px\] {
  font-size: 16px;
}

.gs-font-medium {
  font-weight: 500;
}

.gs-font-bold {
  font-weight: 700;
}

.gs-text-twilight-900 {
  --tw-text-opacity: 1;
  color: rgb(10 36 48 / var(--tw-text-opacity) );
}

.gs-text-beige-400 {
  --tw-text-opacity: 1;
  color: rgb(160 160 158 / var(--tw-text-opacity) );
}

.gs-text-granite-500 {
  --tw-text-opacity: 1;
  color: rgb(71 71 71 / var(--tw-text-opacity) );
}

.gs-text-stone-300 {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity) );
}

.gs-text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity) );
}

.gs-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.gs-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.gs-text-beige-600 {
  --tw-text-opacity: 1;
  color: rgb(122 122 122 / var(--tw-text-opacity) );
}

.gs-text-stone-600 {
  --tw-text-opacity: 1;
  color: rgb(87 83 78 / var(--tw-text-opacity) );
}

.gs-text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity) );
}

.gs-text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.gs-underline {
  text-decoration-line: underline;
}

.gs-opacity-0 {
  opacity: 0;
}

.gs-opacity-100 {
  opacity: 1;
}

.gs-shadow-\[0_-3px_5px_3px_rgba\(0\,0\,0\,0\.1\)\] {
  --tw-shadow: 0 -3px 5px 3px #0000001a;
  --tw-shadow-colored: 0 -3px 5px 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.gs-shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.gs-ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.gs-ring-gray-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity) );
}

.gs-ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.gs-drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.gs-transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.gs-duration-100 {
  transition-duration: .1s;
}

.gs-duration-75 {
  transition-duration: 75ms;
}

.gs-ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.gs-ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.search-suggestions li {
  font-size: 14px;
  line-height: 1.3;
}

.gs-btn-secondary {
  background-color: #9befc2 !important;
  background-image: linear-gradient(90deg, #9befc2 0%, #85f2d1 50%, #8efffa 100%) !important;
}

.no-jobs-container {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .no-jobs-container {
    width: 660px;
  }
}

.first\:gs-rounded-t-xl:first-child {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.checked\:gs-bg-gray-800:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity) );
}

.hover\:gs-cursor-pointer:hover {
  cursor: pointer;
}

.hover\:gs-border-stone-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.hover\:gs-border-gray-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.hover\:gs-bg-blueslate-light:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(90 129 136 / var(--tw-bg-opacity) );
}

.hover\:gs-bg-beige-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity) );
}

.hover\:gs-bg-gradient-to-r:hover {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.hover\:gs-from-orange-400:hover {
  --tw-gradient-from: #fb923c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fb923c00);
}

.hover\:gs-to-pink-400:hover {
  --tw-gradient-to: #f472b6;
}

.hover\:gs-text-stone-500:hover {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity) );
}

.hover\:gs-text-twilight-900:hover {
  --tw-text-opacity: 1;
  color: rgb(10 36 48 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity) );
}

.hover\:gs-shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:gs-shadow-orange-400\/50:hover {
  --tw-shadow-color: #fb923c80;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:gs-ring-2:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:gs-border-stone-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.focus\:gs-outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.disabled\:gs-border-beige-500:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 211 / var(--tw-border-opacity) );
}

.disabled\:gs-text-stone-300:disabled {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity) );
}

@media (min-width: 640px) {
  .sm\:gs-pt-0 {
    padding-top: 0;
  }
}

@media (min-width: 768px) {
  .md\:gs-w-1\/2 {
    width: 50%;
  }

  .md\:gs-flex-row {
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  .lg\:gs-relative {
    position: relative;
  }

  .lg\:gs-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:gs-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:gs-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:gs-mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:gs-block {
    display: block;
  }

  .lg\:gs-flex {
    display: flex;
  }

  .lg\:gs-inline-flex {
    display: inline-flex;
  }

  .lg\:gs-hidden {
    display: none;
  }

  .lg\:gs-h-full {
    height: 100%;
  }

  .lg\:gs-w-fit {
    width: fit-content;
  }

  .lg\:gs-w-96 {
    width: 24rem;
  }

  .lg\:gs-max-w-\[260px\] {
    max-width: 260px;
  }

  .lg\:gs-basis-1\/2 {
    flex-basis: 50%;
  }

  .lg\:gs-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:gs-flex-row {
    flex-direction: row;
  }

  .lg\:gs-items-center {
    align-items: center;
  }

  .lg\:gs-justify-center {
    justify-content: center;
  }

  .lg\:gs-gap-4 {
    gap: 1rem;
  }

  .lg\:gs-rounded-xl {
    border-radius: .75rem;
  }

  .lg\:gs-border {
    border-width: 1px;
  }

  .lg\:gs-border-beige-500 {
    --tw-border-opacity: 1;
    border-color: rgb(220 217 211 / var(--tw-border-opacity) );
  }

  .lg\:gs-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  }

  .lg\:gs-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:gs-pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:gs-pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:gs-text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:gs-text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:gs-shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:gs-drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:last\:gs-rounded-b-xl:last-child {
    border-bottom-left-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:gs-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .\32 xl\:gs-col-span-1 {
    grid-column: span 1 / span 1;
  }
}

.gs-btn-primary[data-v-71b988] {
  width: 100%;
  white-space: nowrap;
  text-align: center;
  background-color: #fca6ef;
  background-image: linear-gradient(90deg, #fbcd72 0%, #ffaeb4 50%, #fca6ef 100%);
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  padding: .625rem 1.25rem;
  font-size: 18px;
  line-height: 1.1;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.gs-container {
  width: 100%;
}

@media (min-width: 640px) {
  .gs-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .gs-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .gs-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .gs-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .gs-container {
    max-width: 1536px;
  }
}

.gs-prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.gs-prose :where([class~="lead"]):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.gs-prose :where(a):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.gs-prose :where(strong):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.gs-prose :where(ol):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: decimal;
}

.gs-prose :where(ol[type="A"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.gs-prose :where(ol[type="a"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.gs-prose :where(ol[type="A" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.gs-prose :where(ol[type="a" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.gs-prose :where(ol[type="I"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-roman;
}

.gs-prose :where(ol[type="i"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-roman;
}

.gs-prose :where(ol[type="I" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-roman;
}

.gs-prose :where(ol[type="i" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-roman;
}

.gs-prose :where(ol[type="1"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: decimal;
}

.gs-prose :where(ul):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: disc;
}

.gs-prose :where(ol > li):not(:where([class~="gs-not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.gs-prose :where(ul > li):not(:where([class~="gs-not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.gs-prose :where(hr):not(:where([class~="gs-not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.gs-prose :where(blockquote):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.gs-prose :where(blockquote p:first-of-type):not(:where([class~="gs-not-prose"] *)):before {
  content: open-quote;
}

.gs-prose :where(blockquote p:last-of-type):not(:where([class~="gs-not-prose"] *)):after {
  content: close-quote;
}

.gs-prose :where(h1):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.gs-prose :where(h1 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 900;
}

.gs-prose :where(h2):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.gs-prose :where(h2 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 800;
}

.gs-prose :where(h3):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.gs-prose :where(h3 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 700;
}

.gs-prose :where(h4):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.gs-prose :where(h4 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 700;
}

.gs-prose :where(figure > *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.gs-prose :where(figcaption):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.gs-prose :where(code):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.gs-prose :where(code):not(:where([class~="gs-not-prose"] *)):before, .gs-prose :where(code):not(:where([class~="gs-not-prose"] *)):after {
  content: "`";
}

.gs-prose :where(a code):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-links);
}

.gs-prose :where(pre):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)):before, .gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)):after {
  content: none;
}

.gs-prose :where(table):not(:where([class~="gs-not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.gs-prose :where(thead):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.gs-prose :where(thead th):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.gs-prose :where(tbody tr):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.gs-prose :where(tbody tr:last-child):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 0;
}

.gs-prose :where(tbody td):not(:where([class~="gs-not-prose"] *)) {
  vertical-align: baseline;
  padding: .571429em;
}

.gs-prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.gs-prose :where(p):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.gs-prose :where(img):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(video):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(figure):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(h2 code):not(:where([class~="gs-not-prose"] *)) {
  font-size: .875em;
}

.gs-prose :where(h3 code):not(:where([class~="gs-not-prose"] *)) {
  font-size: .9em;
}

.gs-prose :where(li):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.gs-prose :where(ol > li):not(:where([class~="gs-not-prose"] *)) {
  padding-left: .375em;
}

.gs-prose :where(ul > li):not(:where([class~="gs-not-prose"] *)) {
  padding-left: .375em;
}

.gs-prose > :where(ul > li p):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.gs-prose > :where(ul > li > :first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
}

.gs-prose > :where(ul > li > :last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.gs-prose > :where(ol > li > :first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
}

.gs-prose > :where(ol > li > :last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.gs-prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.gs-prose :where(hr + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h2 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h3 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h4 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(thead th:first-child):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 0;
}

.gs-prose :where(thead th:last-child):not(:where([class~="gs-not-prose"] *)) {
  padding-right: 0;
}

.gs-prose :where(tbody td:first-child):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 0;
}

.gs-prose :where(tbody td:last-child):not(:where([class~="gs-not-prose"] *)) {
  padding-right: 0;
}

.gs-prose > :where(:first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose > :where(:last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 0;
}

.heading {
  font-family: DM Sans, sans-serif;
  font-size: 1.313rem;
}

.gs-static {
  position: static;
}

.gs-fixed {
  position: fixed;
}

.gs-absolute {
  position: absolute;
}

.gs-relative {
  position: relative;
}

.gs-sticky {
  position: sticky;
}

.gs-inset-y-0 {
  top: 0;
  bottom: 0;
}

.gs-inset-y-1 {
  top: .25rem;
  bottom: .25rem;
}

.gs-top-0 {
  top: 0;
}

.gs-left-0 {
  left: 0;
}

.gs-right-2 {
  right: .5rem;
}

.gs-top-2 {
  top: .5rem;
}

.gs-bottom-0 {
  bottom: 0;
}

.gs-right-3 {
  right: .75rem;
}

.gs-top-3 {
  top: .75rem;
}

.gs-left-3 {
  left: .75rem;
}

.gs-left-3\.5 {
  left: .875rem;
}

.gs-z-20 {
  z-index: 20;
}

.gs-z-10 {
  z-index: 10;
}

.gs-z-0 {
  z-index: 0;
}

.gs-col-span-2 {
  grid-column: span 2 / span 2;
}

.gs-col-span-1 {
  grid-column: span 1 / span 1;
}

.gs-m-6 {
  margin: 1.5rem;
}

.gs-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.-gs-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.gs-my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.gs-my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.gs-mt-10 {
  margin-top: 2.5rem;
}

.gs-mt-14 {
  margin-top: 3.5rem;
}

.gs-mt-5 {
  margin-top: 1.25rem;
}

.gs-mt-2 {
  margin-top: .5rem;
}

.gs-mt-1 {
  margin-top: .25rem;
}

.gs-mt-4 {
  margin-top: 1rem;
}

.gs-mr-4 {
  margin-right: 1rem;
}

.gs-mt-6 {
  margin-top: 1.5rem;
}

.gs-ml-2 {
  margin-left: .5rem;
}

.gs-mt-7 {
  margin-top: 1.75rem;
}

.gs-mt-3 {
  margin-top: .75rem;
}

.gs-mr-2 {
  margin-right: .5rem;
}

.gs-ml-5 {
  margin-left: 1.25rem;
}

.gs-ml-3 {
  margin-left: .75rem;
}

.gs-mt-16 {
  margin-top: 4rem;
}

.gs--mt-16 {
  margin-top: -4rem;
}

.gs-block {
  display: block;
}

.gs-flex {
  display: flex;
}

.gs-inline-flex {
  display: inline-flex;
}

.gs-grid {
  display: grid;
}

.gs-hidden {
  display: none;
}

.gs-h-14 {
  height: 3.5rem;
}

.gs-h-11 {
  height: 2.75rem;
}

.gs-h-2 {
  height: .5rem;
}

.gs-h-5 {
  height: 1.25rem;
}

.gs-h-12 {
  height: 3rem;
}

.gs-h-3 {
  height: .75rem;
}

.gs-h-16 {
  height: 4rem;
}

.gs-h-screen {
  height: 100vh;
}

.gs-h-\[calc\(100vh_-_10\.5rem\)\] {
  height: calc(100vh - 10.5rem);
}

.gs-h-\[56px\] {
  height: 56px;
}

.gs-h-1 {
  height: .25rem;
}

.gs-max-h-screen {
  max-height: 100vh;
}

.gs-max-h-\[calc\(100vh_-_2rem\)\] {
  max-height: calc(100vh - 2rem);
}

.gs-max-h-full {
  max-height: 100%;
}

.gs-max-h-\[calc\(100vh_-_26rem\)\] {
  max-height: calc(100vh - 26rem);
}

.gs-min-h-screen {
  min-height: 100vh;
}

.gs-w-14 {
  width: 3.5rem;
}

.gs-w-20 {
  width: 5rem;
}

.gs-w-full {
  width: 100%;
}

.gs-w-3 {
  width: .75rem;
}

.gs-w-\[71px\] {
  width: 71px;
}

.gs-w-11 {
  width: 2.75rem;
}

.gs-max-w-md {
  max-width: 28rem;
}

.gs-max-w-none {
  max-width: none;
}

.gs-flex-1 {
  flex: 1;
}

.gs-flex-none {
  flex: none;
}

.gs-shrink-0 {
  flex-shrink: 0;
}

.gs-basis-full {
  flex-basis: 100%;
}

.gs-basis-1\/2 {
  flex-basis: 50%;
}

.gs-basis-2\/5 {
  flex-basis: 40%;
}

.gs-basis-3\/5 {
  flex-basis: 60%;
}

.gs-scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.gs-scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.gs-transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

@keyframes gs-pulse {
  50% {
    opacity: .5;
  }
}

.gs-animate-pulse {
  animation: gs-pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
}

.gs-cursor-pointer {
  cursor: pointer;
}

.gs-list-inside {
  list-style-position: inside;
}

.gs-list-disc {
  list-style-type: disc;
}

.gs-appearance-none {
  appearance: none;
}

.gs-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.gs-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.gs-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.gs-flex-col {
  flex-direction: column;
}

.gs-flex-wrap {
  flex-wrap: wrap;
}

.gs-items-start {
  align-items: flex-start;
}

.gs-items-end {
  align-items: flex-end;
}

.gs-items-center {
  align-items: center;
}

.gs-justify-start {
  justify-content: flex-start;
}

.gs-justify-end {
  justify-content: flex-end;
}

.gs-justify-center {
  justify-content: center;
}

.gs-justify-between {
  justify-content: space-between;
}

.gs-gap-4 {
  gap: 1rem;
}

.gs-gap-2 {
  gap: .5rem;
}

.gs-gap-5 {
  gap: 1.25rem;
}

.gs-gap-x-1 {
  column-gap: .25rem;
}

.gs-gap-y-4 {
  row-gap: 1rem;
}

.gs-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse) );
}

.gs-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse) );
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse) );
}

.gs-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse) );
}

.gs-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse) );
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse) );
}

.gs-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse) ) );
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse) );
}

.gs-overflow-y-auto {
  overflow-y: auto;
}

.gs-overflow-y-hidden {
  overflow-y: hidden;
}

.gs-rounded-xl {
  border-radius: .75rem;
}

.gs-rounded-md {
  border-radius: .375rem;
}

.gs-rounded-full {
  border-radius: 9999px;
}

.gs-rounded {
  border-radius: .25rem;
}

.gs-rounded-3xl {
  border-radius: 1.5rem;
}

.gs-rounded-sm {
  border-radius: .125rem;
}

.gs-rounded-lg {
  border-radius: .5rem;
}

.gs-rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.gs-border {
  border-width: 1px;
}

.gs-border-0 {
  border-width: 0;
}

.gs-border-t {
  border-top-width: 1px;
}

.gs-border-none {
  border-style: none;
}

.gs-border-beige-500 {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 211 / var(--tw-border-opacity) );
}

.gs-border-beige-400 {
  --tw-border-opacity: 1;
  border-color: rgb(160 160 158 / var(--tw-border-opacity) );
}

.gs-border-stone-800 {
  --tw-border-opacity: 1;
  border-color: rgb(41 37 36 / var(--tw-border-opacity) );
}

.gs-border-stone-500 {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.gs-border-blueslate {
  --tw-border-opacity: 1;
  border-color: rgb(56 91 111 / var(--tw-border-opacity) );
}

.gs-border-beige-600 {
  --tw-border-opacity: 1;
  border-color: rgb(122 122 122 / var(--tw-border-opacity) );
}

.gs-border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
}

.gs-border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.gs-bg-beige-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 252 251 / var(--tw-bg-opacity) );
}

.gs-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.gs-bg-beige-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 217 211 / var(--tw-bg-opacity) );
}

.gs-bg-beige-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity) );
}

.gs-bg-beige-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 238 234 / var(--tw-bg-opacity) );
}

.gs-bg-blueslate {
  --tw-bg-opacity: 1;
  background-color: rgb(56 91 111 / var(--tw-bg-opacity) );
}

.gs-bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity) );
}

.gs-bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity) );
}

.gs-bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.gs-from-green-100 {
  --tw-gradient-from: #dcfce7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dcfce700);
}

.gs-to-cyan-100 {
  --tw-gradient-to: #cffafe;
}

.gs-p-10 {
  padding: 2.5rem;
}

.gs-p-6 {
  padding: 1.5rem;
}

.gs-p-2 {
  padding: .5rem;
}

.gs-p-5 {
  padding: 1.25rem;
}

.gs-p-1 {
  padding: .25rem;
}

.gs-p-8 {
  padding: 2rem;
}

.gs-py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.gs-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.gs-py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.gs-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.gs-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.gs-px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.gs-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.gs-px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.gs-px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.gs-pt-8 {
  padding-top: 2rem;
}

.gs-pb-4 {
  padding-bottom: 1rem;
}

.gs-pt-4 {
  padding-top: 1rem;
}

.gs-pl-5 {
  padding-left: 1.25rem;
}

.gs-pl-12 {
  padding-left: 3rem;
}

.gs-pr-3 {
  padding-right: .75rem;
}

.gs-text-center {
  text-align: center;
}

.gs-text-right {
  text-align: right;
}

.gs-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.gs-text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.gs-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.gs-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.gs-text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.gs-text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.gs-text-\[15px\] {
  font-size: 15px;
}

.gs-text-\[16px\] {
  font-size: 16px;
}

.gs-font-medium {
  font-weight: 500;
}

.gs-font-bold {
  font-weight: 700;
}

.gs-text-twilight-900 {
  --tw-text-opacity: 1;
  color: rgb(10 36 48 / var(--tw-text-opacity) );
}

.gs-text-beige-400 {
  --tw-text-opacity: 1;
  color: rgb(160 160 158 / var(--tw-text-opacity) );
}

.gs-text-granite-500 {
  --tw-text-opacity: 1;
  color: rgb(71 71 71 / var(--tw-text-opacity) );
}

.gs-text-stone-300 {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity) );
}

.gs-text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity) );
}

.gs-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.gs-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.gs-text-beige-600 {
  --tw-text-opacity: 1;
  color: rgb(122 122 122 / var(--tw-text-opacity) );
}

.gs-text-stone-600 {
  --tw-text-opacity: 1;
  color: rgb(87 83 78 / var(--tw-text-opacity) );
}

.gs-text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity) );
}

.gs-text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.gs-underline {
  text-decoration-line: underline;
}

.gs-opacity-0 {
  opacity: 0;
}

.gs-opacity-100 {
  opacity: 1;
}

.gs-shadow-\[0_-3px_5px_3px_rgba\(0\,0\,0\,0\.1\)\] {
  --tw-shadow: 0 -3px 5px 3px #0000001a;
  --tw-shadow-colored: 0 -3px 5px 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.gs-shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.gs-ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.gs-ring-gray-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity) );
}

.gs-ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.gs-drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.gs-transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.gs-duration-100 {
  transition-duration: .1s;
}

.gs-duration-75 {
  transition-duration: 75ms;
}

.gs-ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.gs-ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

@font-face {
  font-family: Graphik;
  src: url("Graphik-Regular.woff2") format("woff2"), url("Graphik-Regular.woff") format("woff"), url("Graphik-Regular.ttf") format("truetype"), url("Graphik-Regular.svg#Graphik-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Graphik;
  src: url("Graphik-Medium.woff2") format("woff2"), url("Graphik-Medium.woff") format("woff"), url("Graphik-Medium.ttf") format("truetype"), url("Graphik-Medium.svg#Graphik-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Athletics;
  src: url("Athletics-Bold.woff2") format("woff2"), url("Athletics-Bold.woff") format("woff"), url("Athletics-Bold.ttf") format("truetype"), url("Athletics-Bold.svg#Athletics-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Athletics;
  src: url("Athletics-Medium.woff2") format("woff2"), url("Athletics-Medium.woff") format("woff"), url("Athletics-Medium.ttf") format("truetype"), url("Athletics-Medium.svg#Athletics-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Athletics;
  src: url("Athletics-Regular.woff2") format("woff2"), url("Athletics-Regular.woff") format("woff"), url("Athletics-Regular.ttf") format("truetype"), url("Athletics-Regular.svg#Athletics-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DM Sans;
  src: url("DMSans-Bold.woff2") format("woff2"), url("DMSans-Bold.woff") format("woff"), url("DMSans-Bold.ttf") format("truetype"), url("DMSans-Bold.svg#DMSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DM Sans;
  src: url("DMSans-Medium.woff2") format("woff2"), url("DMSans-Medium.woff") format("woff"), url("DMSans-Medium.ttf") format("truetype"), url("DMSans-Medium.svg#DMSans-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DM Sans;
  src: url("DMSans-Regular.woff2") format("woff2"), url("DMSans-Regular.woff") format("woff"), url("DMSans-Regular.ttf") format("truetype"), url("DMSans-Regular.svg#DMSans-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.first\:gs-rounded-t-xl:first-child {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.checked\:gs-bg-gray-800:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity) );
}

.hover\:gs-cursor-pointer:hover {
  cursor: pointer;
}

.hover\:gs-border-stone-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.hover\:gs-border-gray-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.hover\:gs-bg-blueslate-light:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(90 129 136 / var(--tw-bg-opacity) );
}

.hover\:gs-bg-beige-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity) );
}

.hover\:gs-bg-gradient-to-r:hover {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.hover\:gs-from-orange-400:hover {
  --tw-gradient-from: #fb923c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fb923c00);
}

.hover\:gs-to-pink-400:hover {
  --tw-gradient-to: #f472b6;
}

.hover\:gs-text-stone-500:hover {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity) );
}

.hover\:gs-text-twilight-900:hover {
  --tw-text-opacity: 1;
  color: rgb(10 36 48 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity) );
}

.hover\:gs-shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:gs-shadow-orange-400\/50:hover {
  --tw-shadow-color: #fb923c80;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:gs-ring-2:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:gs-border-stone-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.focus\:gs-outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.disabled\:gs-border-beige-500:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 211 / var(--tw-border-opacity) );
}

.disabled\:gs-text-stone-300:disabled {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity) );
}

@media (min-width: 640px) {
  .sm\:gs-pt-0 {
    padding-top: 0;
  }
}

@media (min-width: 768px) {
  .md\:gs-w-1\/2 {
    width: 50%;
  }

  .md\:gs-flex-row {
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  .lg\:gs-relative {
    position: relative;
  }

  .lg\:gs-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:gs-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:gs-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:gs-mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:gs-block {
    display: block;
  }

  .lg\:gs-flex {
    display: flex;
  }

  .lg\:gs-inline-flex {
    display: inline-flex;
  }

  .lg\:gs-hidden {
    display: none;
  }

  .lg\:gs-h-full {
    height: 100%;
  }

  .lg\:gs-w-fit {
    width: fit-content;
  }

  .lg\:gs-w-96 {
    width: 24rem;
  }

  .lg\:gs-max-w-\[260px\] {
    max-width: 260px;
  }

  .lg\:gs-basis-1\/2 {
    flex-basis: 50%;
  }

  .lg\:gs-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:gs-flex-row {
    flex-direction: row;
  }

  .lg\:gs-items-center {
    align-items: center;
  }

  .lg\:gs-justify-center {
    justify-content: center;
  }

  .lg\:gs-gap-4 {
    gap: 1rem;
  }

  .lg\:gs-rounded-xl {
    border-radius: .75rem;
  }

  .lg\:gs-border {
    border-width: 1px;
  }

  .lg\:gs-border-beige-500 {
    --tw-border-opacity: 1;
    border-color: rgb(220 217 211 / var(--tw-border-opacity) );
  }

  .lg\:gs-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  }

  .lg\:gs-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:gs-pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:gs-pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:gs-text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:gs-text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:gs-shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:gs-drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:last\:gs-rounded-b-xl:last-child {
    border-bottom-left-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:gs-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .\32 xl\:gs-col-span-1 {
    grid-column: span 1 / span 1;
  }
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.gs-container {
  width: 100%;
}

@media (min-width: 640px) {
  .gs-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .gs-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .gs-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .gs-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .gs-container {
    max-width: 1536px;
  }
}

.gs-prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.gs-prose :where([class~="lead"]):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.gs-prose :where(a):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.gs-prose :where(strong):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.gs-prose :where(ol):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: decimal;
}

.gs-prose :where(ol[type="A"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.gs-prose :where(ol[type="a"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.gs-prose :where(ol[type="A" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.gs-prose :where(ol[type="a" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.gs-prose :where(ol[type="I"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-roman;
}

.gs-prose :where(ol[type="i"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-roman;
}

.gs-prose :where(ol[type="I" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-roman;
}

.gs-prose :where(ol[type="i" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-roman;
}

.gs-prose :where(ol[type="1"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: decimal;
}

.gs-prose :where(ul):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: disc;
}

.gs-prose :where(ol > li):not(:where([class~="gs-not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.gs-prose :where(ul > li):not(:where([class~="gs-not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.gs-prose :where(hr):not(:where([class~="gs-not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.gs-prose :where(blockquote):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.gs-prose :where(blockquote p:first-of-type):not(:where([class~="gs-not-prose"] *)):before {
  content: open-quote;
}

.gs-prose :where(blockquote p:last-of-type):not(:where([class~="gs-not-prose"] *)):after {
  content: close-quote;
}

.gs-prose :where(h1):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.gs-prose :where(h1 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 900;
}

.gs-prose :where(h2):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.gs-prose :where(h2 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 800;
}

.gs-prose :where(h3):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.gs-prose :where(h3 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 700;
}

.gs-prose :where(h4):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.gs-prose :where(h4 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 700;
}

.gs-prose :where(figure > *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.gs-prose :where(figcaption):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.gs-prose :where(code):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.gs-prose :where(code):not(:where([class~="gs-not-prose"] *)):before, .gs-prose :where(code):not(:where([class~="gs-not-prose"] *)):after {
  content: "`";
}

.gs-prose :where(a code):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-links);
}

.gs-prose :where(pre):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)):before, .gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)):after {
  content: none;
}

.gs-prose :where(table):not(:where([class~="gs-not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.gs-prose :where(thead):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.gs-prose :where(thead th):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.gs-prose :where(tbody tr):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.gs-prose :where(tbody tr:last-child):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 0;
}

.gs-prose :where(tbody td):not(:where([class~="gs-not-prose"] *)) {
  vertical-align: baseline;
  padding: .571429em;
}

.gs-prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.gs-prose :where(p):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.gs-prose :where(img):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(video):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(figure):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(h2 code):not(:where([class~="gs-not-prose"] *)) {
  font-size: .875em;
}

.gs-prose :where(h3 code):not(:where([class~="gs-not-prose"] *)) {
  font-size: .9em;
}

.gs-prose :where(li):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.gs-prose :where(ol > li):not(:where([class~="gs-not-prose"] *)) {
  padding-left: .375em;
}

.gs-prose :where(ul > li):not(:where([class~="gs-not-prose"] *)) {
  padding-left: .375em;
}

.gs-prose > :where(ul > li p):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.gs-prose > :where(ul > li > :first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
}

.gs-prose > :where(ul > li > :last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.gs-prose > :where(ol > li > :first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
}

.gs-prose > :where(ol > li > :last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.gs-prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.gs-prose :where(hr + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h2 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h3 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h4 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(thead th:first-child):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 0;
}

.gs-prose :where(thead th:last-child):not(:where([class~="gs-not-prose"] *)) {
  padding-right: 0;
}

.gs-prose :where(tbody td:first-child):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 0;
}

.gs-prose :where(tbody td:last-child):not(:where([class~="gs-not-prose"] *)) {
  padding-right: 0;
}

.gs-prose > :where(:first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose > :where(:last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 0;
}

.heading {
  font-family: DM Sans, sans-serif;
  font-size: 1.313rem;
}

.gs-static {
  position: static;
}

.gs-fixed {
  position: fixed;
}

.gs-absolute {
  position: absolute;
}

.gs-relative {
  position: relative;
}

.gs-sticky {
  position: sticky;
}

.gs-inset-y-0 {
  top: 0;
  bottom: 0;
}

.gs-inset-y-1 {
  top: .25rem;
  bottom: .25rem;
}

.gs-top-0 {
  top: 0;
}

.gs-left-0 {
  left: 0;
}

.gs-right-2 {
  right: .5rem;
}

.gs-top-2 {
  top: .5rem;
}

.gs-bottom-0 {
  bottom: 0;
}

.gs-right-3 {
  right: .75rem;
}

.gs-top-3 {
  top: .75rem;
}

.gs-left-3 {
  left: .75rem;
}

.gs-left-3\.5 {
  left: .875rem;
}

.gs-z-20 {
  z-index: 20;
}

.gs-z-10 {
  z-index: 10;
}

.gs-z-0 {
  z-index: 0;
}

.gs-col-span-2 {
  grid-column: span 2 / span 2;
}

.gs-col-span-1 {
  grid-column: span 1 / span 1;
}

.gs-m-6 {
  margin: 1.5rem;
}

.gs-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.-gs-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.gs-my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.gs-my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.gs-mt-10 {
  margin-top: 2.5rem;
}

.gs-mt-14 {
  margin-top: 3.5rem;
}

.gs-mt-5 {
  margin-top: 1.25rem;
}

.gs-mt-2 {
  margin-top: .5rem;
}

.gs-mt-1 {
  margin-top: .25rem;
}

.gs-mt-4 {
  margin-top: 1rem;
}

.gs-mr-4 {
  margin-right: 1rem;
}

.gs-mt-6 {
  margin-top: 1.5rem;
}

.gs-ml-2 {
  margin-left: .5rem;
}

.gs-mt-7 {
  margin-top: 1.75rem;
}

.gs-mt-3 {
  margin-top: .75rem;
}

.gs-mr-2 {
  margin-right: .5rem;
}

.gs-ml-5 {
  margin-left: 1.25rem;
}

.gs-ml-3 {
  margin-left: .75rem;
}

.gs-mt-16 {
  margin-top: 4rem;
}

.gs--mt-16 {
  margin-top: -4rem;
}

.gs-block {
  display: block;
}

.gs-flex {
  display: flex;
}

.gs-inline-flex {
  display: inline-flex;
}

.gs-grid {
  display: grid;
}

.gs-hidden {
  display: none;
}

.gs-h-14 {
  height: 3.5rem;
}

.gs-h-11 {
  height: 2.75rem;
}

.gs-h-2 {
  height: .5rem;
}

.gs-h-5 {
  height: 1.25rem;
}

.gs-h-12 {
  height: 3rem;
}

.gs-h-3 {
  height: .75rem;
}

.gs-h-16 {
  height: 4rem;
}

.gs-h-screen {
  height: 100vh;
}

.gs-h-\[calc\(100vh_-_10\.5rem\)\] {
  height: calc(100vh - 10.5rem);
}

.gs-h-\[56px\] {
  height: 56px;
}

.gs-h-1 {
  height: .25rem;
}

.gs-max-h-screen {
  max-height: 100vh;
}

.gs-max-h-\[calc\(100vh_-_2rem\)\] {
  max-height: calc(100vh - 2rem);
}

.gs-max-h-full {
  max-height: 100%;
}

.gs-max-h-\[calc\(100vh_-_26rem\)\] {
  max-height: calc(100vh - 26rem);
}

.gs-min-h-screen {
  min-height: 100vh;
}

.gs-w-14 {
  width: 3.5rem;
}

.gs-w-20 {
  width: 5rem;
}

.gs-w-full {
  width: 100%;
}

.gs-w-3 {
  width: .75rem;
}

.gs-w-\[71px\] {
  width: 71px;
}

.gs-w-11 {
  width: 2.75rem;
}

.gs-max-w-md {
  max-width: 28rem;
}

.gs-max-w-none {
  max-width: none;
}

.gs-flex-1 {
  flex: 1;
}

.gs-flex-none {
  flex: none;
}

.gs-shrink-0 {
  flex-shrink: 0;
}

.gs-basis-full {
  flex-basis: 100%;
}

.gs-basis-1\/2 {
  flex-basis: 50%;
}

.gs-basis-2\/5 {
  flex-basis: 40%;
}

.gs-basis-3\/5 {
  flex-basis: 60%;
}

.gs-scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.gs-scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.gs-transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

@keyframes gs-pulse {
  50% {
    opacity: .5;
  }
}

.gs-animate-pulse {
  animation: gs-pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
}

.gs-cursor-pointer {
  cursor: pointer;
}

.gs-list-inside {
  list-style-position: inside;
}

.gs-list-disc {
  list-style-type: disc;
}

.gs-appearance-none {
  appearance: none;
}

.gs-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.gs-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.gs-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.gs-flex-col {
  flex-direction: column;
}

.gs-flex-wrap {
  flex-wrap: wrap;
}

.gs-items-start {
  align-items: flex-start;
}

.gs-items-end {
  align-items: flex-end;
}

.gs-items-center {
  align-items: center;
}

.gs-justify-start {
  justify-content: flex-start;
}

.gs-justify-end {
  justify-content: flex-end;
}

.gs-justify-center {
  justify-content: center;
}

.gs-justify-between {
  justify-content: space-between;
}

.gs-gap-4 {
  gap: 1rem;
}

.gs-gap-2 {
  gap: .5rem;
}

.gs-gap-5 {
  gap: 1.25rem;
}

.gs-gap-x-1 {
  column-gap: .25rem;
}

.gs-gap-y-4 {
  row-gap: 1rem;
}

.gs-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse) );
}

.gs-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse) );
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse) );
}

.gs-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse) );
}

.gs-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse) );
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse) );
}

.gs-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse) ) );
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse) );
}

.gs-overflow-y-auto {
  overflow-y: auto;
}

.gs-overflow-y-hidden {
  overflow-y: hidden;
}

.gs-rounded-xl {
  border-radius: .75rem;
}

.gs-rounded-md {
  border-radius: .375rem;
}

.gs-rounded-full {
  border-radius: 9999px;
}

.gs-rounded {
  border-radius: .25rem;
}

.gs-rounded-3xl {
  border-radius: 1.5rem;
}

.gs-rounded-sm {
  border-radius: .125rem;
}

.gs-rounded-lg {
  border-radius: .5rem;
}

.gs-rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.gs-border {
  border-width: 1px;
}

.gs-border-0 {
  border-width: 0;
}

.gs-border-t {
  border-top-width: 1px;
}

.gs-border-none {
  border-style: none;
}

.gs-border-beige-500 {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 211 / var(--tw-border-opacity) );
}

.gs-border-beige-400 {
  --tw-border-opacity: 1;
  border-color: rgb(160 160 158 / var(--tw-border-opacity) );
}

.gs-border-stone-800 {
  --tw-border-opacity: 1;
  border-color: rgb(41 37 36 / var(--tw-border-opacity) );
}

.gs-border-stone-500 {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.gs-border-blueslate {
  --tw-border-opacity: 1;
  border-color: rgb(56 91 111 / var(--tw-border-opacity) );
}

.gs-border-beige-600 {
  --tw-border-opacity: 1;
  border-color: rgb(122 122 122 / var(--tw-border-opacity) );
}

.gs-border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
}

.gs-border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.gs-bg-beige-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 252 251 / var(--tw-bg-opacity) );
}

.gs-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.gs-bg-beige-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 217 211 / var(--tw-bg-opacity) );
}

.gs-bg-beige-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity) );
}

.gs-bg-beige-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 238 234 / var(--tw-bg-opacity) );
}

.gs-bg-blueslate {
  --tw-bg-opacity: 1;
  background-color: rgb(56 91 111 / var(--tw-bg-opacity) );
}

.gs-bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity) );
}

.gs-bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity) );
}

.gs-bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.gs-from-green-100 {
  --tw-gradient-from: #dcfce7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dcfce700);
}

.gs-to-cyan-100 {
  --tw-gradient-to: #cffafe;
}

.gs-p-10 {
  padding: 2.5rem;
}

.gs-p-6 {
  padding: 1.5rem;
}

.gs-p-2 {
  padding: .5rem;
}

.gs-p-5 {
  padding: 1.25rem;
}

.gs-p-1 {
  padding: .25rem;
}

.gs-p-8 {
  padding: 2rem;
}

.gs-py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.gs-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.gs-py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.gs-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.gs-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.gs-px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.gs-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.gs-px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.gs-px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.gs-pt-8 {
  padding-top: 2rem;
}

.gs-pb-4 {
  padding-bottom: 1rem;
}

.gs-pt-4 {
  padding-top: 1rem;
}

.gs-pl-5 {
  padding-left: 1.25rem;
}

.gs-pl-12 {
  padding-left: 3rem;
}

.gs-pr-3 {
  padding-right: .75rem;
}

.gs-text-center {
  text-align: center;
}

.gs-text-right {
  text-align: right;
}

.gs-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.gs-text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.gs-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.gs-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.gs-text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.gs-text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.gs-text-\[15px\] {
  font-size: 15px;
}

.gs-text-\[16px\] {
  font-size: 16px;
}

.gs-font-medium {
  font-weight: 500;
}

.gs-font-bold {
  font-weight: 700;
}

.gs-text-twilight-900 {
  --tw-text-opacity: 1;
  color: rgb(10 36 48 / var(--tw-text-opacity) );
}

.gs-text-beige-400 {
  --tw-text-opacity: 1;
  color: rgb(160 160 158 / var(--tw-text-opacity) );
}

.gs-text-granite-500 {
  --tw-text-opacity: 1;
  color: rgb(71 71 71 / var(--tw-text-opacity) );
}

.gs-text-stone-300 {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity) );
}

.gs-text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity) );
}

.gs-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.gs-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.gs-text-beige-600 {
  --tw-text-opacity: 1;
  color: rgb(122 122 122 / var(--tw-text-opacity) );
}

.gs-text-stone-600 {
  --tw-text-opacity: 1;
  color: rgb(87 83 78 / var(--tw-text-opacity) );
}

.gs-text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity) );
}

.gs-text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.gs-underline {
  text-decoration-line: underline;
}

.gs-opacity-0 {
  opacity: 0;
}

.gs-opacity-100 {
  opacity: 1;
}

.gs-shadow-\[0_-3px_5px_3px_rgba\(0\,0\,0\,0\.1\)\] {
  --tw-shadow: 0 -3px 5px 3px #0000001a;
  --tw-shadow-colored: 0 -3px 5px 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.gs-shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.gs-ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.gs-ring-gray-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity) );
}

.gs-ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.gs-drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.gs-transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.gs-duration-100 {
  transition-duration: .1s;
}

.gs-duration-75 {
  transition-duration: 75ms;
}

.gs-ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.gs-ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

@font-face {
  font-family: Graphik;
  src: url("Graphik-Regular.woff2") format("woff2"), url("Graphik-Regular.woff") format("woff"), url("Graphik-Regular.ttf") format("truetype"), url("Graphik-Regular.svg#Graphik-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Graphik;
  src: url("Graphik-Medium.woff2") format("woff2"), url("Graphik-Medium.woff") format("woff"), url("Graphik-Medium.ttf") format("truetype"), url("Graphik-Medium.svg#Graphik-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Athletics;
  src: url("Athletics-Bold.woff2") format("woff2"), url("Athletics-Bold.woff") format("woff"), url("Athletics-Bold.ttf") format("truetype"), url("Athletics-Bold.svg#Athletics-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Athletics;
  src: url("Athletics-Medium.woff2") format("woff2"), url("Athletics-Medium.woff") format("woff"), url("Athletics-Medium.ttf") format("truetype"), url("Athletics-Medium.svg#Athletics-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Athletics;
  src: url("Athletics-Regular.woff2") format("woff2"), url("Athletics-Regular.woff") format("woff"), url("Athletics-Regular.ttf") format("truetype"), url("Athletics-Regular.svg#Athletics-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DM Sans;
  src: url("DMSans-Bold.woff2") format("woff2"), url("DMSans-Bold.woff") format("woff"), url("DMSans-Bold.ttf") format("truetype"), url("DMSans-Bold.svg#DMSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DM Sans;
  src: url("DMSans-Medium.woff2") format("woff2"), url("DMSans-Medium.woff") format("woff"), url("DMSans-Medium.ttf") format("truetype"), url("DMSans-Medium.svg#DMSans-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DM Sans;
  src: url("DMSans-Regular.woff2") format("woff2"), url("DMSans-Regular.woff") format("woff"), url("DMSans-Regular.ttf") format("truetype"), url("DMSans-Regular.svg#DMSans-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.first\:gs-rounded-t-xl:first-child {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.checked\:gs-bg-gray-800:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity) );
}

.hover\:gs-cursor-pointer:hover {
  cursor: pointer;
}

.hover\:gs-border-stone-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.hover\:gs-border-gray-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.hover\:gs-bg-blueslate-light:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(90 129 136 / var(--tw-bg-opacity) );
}

.hover\:gs-bg-beige-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity) );
}

.hover\:gs-bg-gradient-to-r:hover {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.hover\:gs-from-orange-400:hover {
  --tw-gradient-from: #fb923c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fb923c00);
}

.hover\:gs-to-pink-400:hover {
  --tw-gradient-to: #f472b6;
}

.hover\:gs-text-stone-500:hover {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity) );
}

.hover\:gs-text-twilight-900:hover {
  --tw-text-opacity: 1;
  color: rgb(10 36 48 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity) );
}

.hover\:gs-shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:gs-shadow-orange-400\/50:hover {
  --tw-shadow-color: #fb923c80;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:gs-ring-2:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:gs-border-stone-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.focus\:gs-outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.disabled\:gs-border-beige-500:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 211 / var(--tw-border-opacity) );
}

.disabled\:gs-text-stone-300:disabled {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity) );
}

@media (min-width: 640px) {
  .sm\:gs-pt-0 {
    padding-top: 0;
  }
}

@media (min-width: 768px) {
  .md\:gs-w-1\/2 {
    width: 50%;
  }

  .md\:gs-flex-row {
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  .lg\:gs-relative {
    position: relative;
  }

  .lg\:gs-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:gs-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:gs-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:gs-mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:gs-block {
    display: block;
  }

  .lg\:gs-flex {
    display: flex;
  }

  .lg\:gs-inline-flex {
    display: inline-flex;
  }

  .lg\:gs-hidden {
    display: none;
  }

  .lg\:gs-h-full {
    height: 100%;
  }

  .lg\:gs-w-fit {
    width: fit-content;
  }

  .lg\:gs-w-96 {
    width: 24rem;
  }

  .lg\:gs-max-w-\[260px\] {
    max-width: 260px;
  }

  .lg\:gs-basis-1\/2 {
    flex-basis: 50%;
  }

  .lg\:gs-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:gs-flex-row {
    flex-direction: row;
  }

  .lg\:gs-items-center {
    align-items: center;
  }

  .lg\:gs-justify-center {
    justify-content: center;
  }

  .lg\:gs-gap-4 {
    gap: 1rem;
  }

  .lg\:gs-rounded-xl {
    border-radius: .75rem;
  }

  .lg\:gs-border {
    border-width: 1px;
  }

  .lg\:gs-border-beige-500 {
    --tw-border-opacity: 1;
    border-color: rgb(220 217 211 / var(--tw-border-opacity) );
  }

  .lg\:gs-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  }

  .lg\:gs-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:gs-pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:gs-pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:gs-text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:gs-text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:gs-shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:gs-drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:last\:gs-rounded-b-xl:last-child {
    border-bottom-left-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:gs-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .\32 xl\:gs-col-span-1 {
    grid-column: span 1 / span 1;
  }
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.gs-container {
  width: 100%;
}

@media (min-width: 640px) {
  .gs-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .gs-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .gs-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .gs-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .gs-container {
    max-width: 1536px;
  }
}

.gs-prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.gs-prose :where([class~="lead"]):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.gs-prose :where(a):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.gs-prose :where(strong):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.gs-prose :where(ol):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: decimal;
}

.gs-prose :where(ol[type="A"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.gs-prose :where(ol[type="a"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.gs-prose :where(ol[type="A" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.gs-prose :where(ol[type="a" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.gs-prose :where(ol[type="I"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-roman;
}

.gs-prose :where(ol[type="i"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-roman;
}

.gs-prose :where(ol[type="I" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-roman;
}

.gs-prose :where(ol[type="i" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-roman;
}

.gs-prose :where(ol[type="1"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: decimal;
}

.gs-prose :where(ul):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: disc;
}

.gs-prose :where(ol > li):not(:where([class~="gs-not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.gs-prose :where(ul > li):not(:where([class~="gs-not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.gs-prose :where(hr):not(:where([class~="gs-not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.gs-prose :where(blockquote):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.gs-prose :where(blockquote p:first-of-type):not(:where([class~="gs-not-prose"] *)):before {
  content: open-quote;
}

.gs-prose :where(blockquote p:last-of-type):not(:where([class~="gs-not-prose"] *)):after {
  content: close-quote;
}

.gs-prose :where(h1):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.gs-prose :where(h1 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 900;
}

.gs-prose :where(h2):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.gs-prose :where(h2 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 800;
}

.gs-prose :where(h3):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.gs-prose :where(h3 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 700;
}

.gs-prose :where(h4):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.gs-prose :where(h4 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 700;
}

.gs-prose :where(figure > *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.gs-prose :where(figcaption):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.gs-prose :where(code):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.gs-prose :where(code):not(:where([class~="gs-not-prose"] *)):before, .gs-prose :where(code):not(:where([class~="gs-not-prose"] *)):after {
  content: "`";
}

.gs-prose :where(a code):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-links);
}

.gs-prose :where(pre):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)):before, .gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)):after {
  content: none;
}

.gs-prose :where(table):not(:where([class~="gs-not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.gs-prose :where(thead):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.gs-prose :where(thead th):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.gs-prose :where(tbody tr):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.gs-prose :where(tbody tr:last-child):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 0;
}

.gs-prose :where(tbody td):not(:where([class~="gs-not-prose"] *)) {
  vertical-align: baseline;
  padding: .571429em;
}

.gs-prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.gs-prose :where(p):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.gs-prose :where(img):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(video):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(figure):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(h2 code):not(:where([class~="gs-not-prose"] *)) {
  font-size: .875em;
}

.gs-prose :where(h3 code):not(:where([class~="gs-not-prose"] *)) {
  font-size: .9em;
}

.gs-prose :where(li):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.gs-prose :where(ol > li):not(:where([class~="gs-not-prose"] *)) {
  padding-left: .375em;
}

.gs-prose :where(ul > li):not(:where([class~="gs-not-prose"] *)) {
  padding-left: .375em;
}

.gs-prose > :where(ul > li p):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.gs-prose > :where(ul > li > :first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
}

.gs-prose > :where(ul > li > :last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.gs-prose > :where(ol > li > :first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
}

.gs-prose > :where(ol > li > :last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.gs-prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.gs-prose :where(hr + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h2 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h3 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h4 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(thead th:first-child):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 0;
}

.gs-prose :where(thead th:last-child):not(:where([class~="gs-not-prose"] *)) {
  padding-right: 0;
}

.gs-prose :where(tbody td:first-child):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 0;
}

.gs-prose :where(tbody td:last-child):not(:where([class~="gs-not-prose"] *)) {
  padding-right: 0;
}

.gs-prose > :where(:first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose > :where(:last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 0;
}

.heading {
  font-family: DM Sans, sans-serif;
  font-size: 1.313rem;
}

.gs-static {
  position: static;
}

.gs-fixed {
  position: fixed;
}

.gs-absolute {
  position: absolute;
}

.gs-relative {
  position: relative;
}

.gs-sticky {
  position: sticky;
}

.gs-inset-y-0 {
  top: 0;
  bottom: 0;
}

.gs-inset-y-1 {
  top: .25rem;
  bottom: .25rem;
}

.gs-top-0 {
  top: 0;
}

.gs-left-0 {
  left: 0;
}

.gs-right-2 {
  right: .5rem;
}

.gs-top-2 {
  top: .5rem;
}

.gs-bottom-0 {
  bottom: 0;
}

.gs-right-3 {
  right: .75rem;
}

.gs-top-3 {
  top: .75rem;
}

.gs-left-3 {
  left: .75rem;
}

.gs-left-3\.5 {
  left: .875rem;
}

.gs-z-20 {
  z-index: 20;
}

.gs-z-10 {
  z-index: 10;
}

.gs-z-0 {
  z-index: 0;
}

.gs-col-span-2 {
  grid-column: span 2 / span 2;
}

.gs-col-span-1 {
  grid-column: span 1 / span 1;
}

.gs-m-6 {
  margin: 1.5rem;
}

.gs-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.-gs-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.gs-my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.gs-my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.gs-mt-10 {
  margin-top: 2.5rem;
}

.gs-mt-14 {
  margin-top: 3.5rem;
}

.gs-mt-5 {
  margin-top: 1.25rem;
}

.gs-mt-2 {
  margin-top: .5rem;
}

.gs-mt-1 {
  margin-top: .25rem;
}

.gs-mt-4 {
  margin-top: 1rem;
}

.gs-mr-4 {
  margin-right: 1rem;
}

.gs-mt-6 {
  margin-top: 1.5rem;
}

.gs-ml-2 {
  margin-left: .5rem;
}

.gs-mt-7 {
  margin-top: 1.75rem;
}

.gs-mt-3 {
  margin-top: .75rem;
}

.gs-mr-2 {
  margin-right: .5rem;
}

.gs-ml-5 {
  margin-left: 1.25rem;
}

.gs-ml-3 {
  margin-left: .75rem;
}

.gs-mt-16 {
  margin-top: 4rem;
}

.gs--mt-16 {
  margin-top: -4rem;
}

.gs-block {
  display: block;
}

.gs-flex {
  display: flex;
}

.gs-inline-flex {
  display: inline-flex;
}

.gs-grid {
  display: grid;
}

.gs-hidden {
  display: none;
}

.gs-h-14 {
  height: 3.5rem;
}

.gs-h-11 {
  height: 2.75rem;
}

.gs-h-2 {
  height: .5rem;
}

.gs-h-5 {
  height: 1.25rem;
}

.gs-h-12 {
  height: 3rem;
}

.gs-h-3 {
  height: .75rem;
}

.gs-h-16 {
  height: 4rem;
}

.gs-h-screen {
  height: 100vh;
}

.gs-h-\[calc\(100vh_-_10\.5rem\)\] {
  height: calc(100vh - 10.5rem);
}

.gs-h-\[56px\] {
  height: 56px;
}

.gs-h-1 {
  height: .25rem;
}

.gs-max-h-screen {
  max-height: 100vh;
}

.gs-max-h-\[calc\(100vh_-_2rem\)\] {
  max-height: calc(100vh - 2rem);
}

.gs-max-h-full {
  max-height: 100%;
}

.gs-max-h-\[calc\(100vh_-_26rem\)\] {
  max-height: calc(100vh - 26rem);
}

.gs-min-h-screen {
  min-height: 100vh;
}

.gs-w-14 {
  width: 3.5rem;
}

.gs-w-20 {
  width: 5rem;
}

.gs-w-full {
  width: 100%;
}

.gs-w-3 {
  width: .75rem;
}

.gs-w-\[71px\] {
  width: 71px;
}

.gs-w-11 {
  width: 2.75rem;
}

.gs-max-w-md {
  max-width: 28rem;
}

.gs-max-w-none {
  max-width: none;
}

.gs-flex-1 {
  flex: 1;
}

.gs-flex-none {
  flex: none;
}

.gs-shrink-0 {
  flex-shrink: 0;
}

.gs-basis-full {
  flex-basis: 100%;
}

.gs-basis-1\/2 {
  flex-basis: 50%;
}

.gs-basis-2\/5 {
  flex-basis: 40%;
}

.gs-basis-3\/5 {
  flex-basis: 60%;
}

.gs-scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.gs-scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.gs-transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

@keyframes gs-pulse {
  50% {
    opacity: .5;
  }
}

.gs-animate-pulse {
  animation: gs-pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
}

.gs-cursor-pointer {
  cursor: pointer;
}

.gs-list-inside {
  list-style-position: inside;
}

.gs-list-disc {
  list-style-type: disc;
}

.gs-appearance-none {
  appearance: none;
}

.gs-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.gs-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.gs-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.gs-flex-col {
  flex-direction: column;
}

.gs-flex-wrap {
  flex-wrap: wrap;
}

.gs-items-start {
  align-items: flex-start;
}

.gs-items-end {
  align-items: flex-end;
}

.gs-items-center {
  align-items: center;
}

.gs-justify-start {
  justify-content: flex-start;
}

.gs-justify-end {
  justify-content: flex-end;
}

.gs-justify-center {
  justify-content: center;
}

.gs-justify-between {
  justify-content: space-between;
}

.gs-gap-4 {
  gap: 1rem;
}

.gs-gap-2 {
  gap: .5rem;
}

.gs-gap-5 {
  gap: 1.25rem;
}

.gs-gap-x-1 {
  column-gap: .25rem;
}

.gs-gap-y-4 {
  row-gap: 1rem;
}

.gs-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse) );
}

.gs-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse) );
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse) );
}

.gs-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse) );
}

.gs-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse) );
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse) );
}

.gs-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse) ) );
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse) );
}

.gs-overflow-y-auto {
  overflow-y: auto;
}

.gs-overflow-y-hidden {
  overflow-y: hidden;
}

.gs-rounded-xl {
  border-radius: .75rem;
}

.gs-rounded-md {
  border-radius: .375rem;
}

.gs-rounded-full {
  border-radius: 9999px;
}

.gs-rounded {
  border-radius: .25rem;
}

.gs-rounded-3xl {
  border-radius: 1.5rem;
}

.gs-rounded-sm {
  border-radius: .125rem;
}

.gs-rounded-lg {
  border-radius: .5rem;
}

.gs-rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.gs-border {
  border-width: 1px;
}

.gs-border-0 {
  border-width: 0;
}

.gs-border-t {
  border-top-width: 1px;
}

.gs-border-none {
  border-style: none;
}

.gs-border-beige-500 {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 211 / var(--tw-border-opacity) );
}

.gs-border-beige-400 {
  --tw-border-opacity: 1;
  border-color: rgb(160 160 158 / var(--tw-border-opacity) );
}

.gs-border-stone-800 {
  --tw-border-opacity: 1;
  border-color: rgb(41 37 36 / var(--tw-border-opacity) );
}

.gs-border-stone-500 {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.gs-border-blueslate {
  --tw-border-opacity: 1;
  border-color: rgb(56 91 111 / var(--tw-border-opacity) );
}

.gs-border-beige-600 {
  --tw-border-opacity: 1;
  border-color: rgb(122 122 122 / var(--tw-border-opacity) );
}

.gs-border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
}

.gs-border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.gs-bg-beige-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 252 251 / var(--tw-bg-opacity) );
}

.gs-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.gs-bg-beige-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 217 211 / var(--tw-bg-opacity) );
}

.gs-bg-beige-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity) );
}

.gs-bg-beige-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 238 234 / var(--tw-bg-opacity) );
}

.gs-bg-blueslate {
  --tw-bg-opacity: 1;
  background-color: rgb(56 91 111 / var(--tw-bg-opacity) );
}

.gs-bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity) );
}

.gs-bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity) );
}

.gs-bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.gs-from-green-100 {
  --tw-gradient-from: #dcfce7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dcfce700);
}

.gs-to-cyan-100 {
  --tw-gradient-to: #cffafe;
}

.gs-p-10 {
  padding: 2.5rem;
}

.gs-p-6 {
  padding: 1.5rem;
}

.gs-p-2 {
  padding: .5rem;
}

.gs-p-5 {
  padding: 1.25rem;
}

.gs-p-1 {
  padding: .25rem;
}

.gs-p-8 {
  padding: 2rem;
}

.gs-py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.gs-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.gs-py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.gs-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.gs-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.gs-px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.gs-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.gs-px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.gs-px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.gs-pt-8 {
  padding-top: 2rem;
}

.gs-pb-4 {
  padding-bottom: 1rem;
}

.gs-pt-4 {
  padding-top: 1rem;
}

.gs-pl-5 {
  padding-left: 1.25rem;
}

.gs-pl-12 {
  padding-left: 3rem;
}

.gs-pr-3 {
  padding-right: .75rem;
}

.gs-text-center {
  text-align: center;
}

.gs-text-right {
  text-align: right;
}

.gs-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.gs-text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.gs-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.gs-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.gs-text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.gs-text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.gs-text-\[15px\] {
  font-size: 15px;
}

.gs-text-\[16px\] {
  font-size: 16px;
}

.gs-font-medium {
  font-weight: 500;
}

.gs-font-bold {
  font-weight: 700;
}

.gs-text-twilight-900 {
  --tw-text-opacity: 1;
  color: rgb(10 36 48 / var(--tw-text-opacity) );
}

.gs-text-beige-400 {
  --tw-text-opacity: 1;
  color: rgb(160 160 158 / var(--tw-text-opacity) );
}

.gs-text-granite-500 {
  --tw-text-opacity: 1;
  color: rgb(71 71 71 / var(--tw-text-opacity) );
}

.gs-text-stone-300 {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity) );
}

.gs-text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity) );
}

.gs-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.gs-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.gs-text-beige-600 {
  --tw-text-opacity: 1;
  color: rgb(122 122 122 / var(--tw-text-opacity) );
}

.gs-text-stone-600 {
  --tw-text-opacity: 1;
  color: rgb(87 83 78 / var(--tw-text-opacity) );
}

.gs-text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity) );
}

.gs-text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.gs-underline {
  text-decoration-line: underline;
}

.gs-opacity-0 {
  opacity: 0;
}

.gs-opacity-100 {
  opacity: 1;
}

.gs-shadow-\[0_-3px_5px_3px_rgba\(0\,0\,0\,0\.1\)\] {
  --tw-shadow: 0 -3px 5px 3px #0000001a;
  --tw-shadow-colored: 0 -3px 5px 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.gs-shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.gs-ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.gs-ring-gray-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity) );
}

.gs-ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.gs-drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.gs-transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.gs-duration-100 {
  transition-duration: .1s;
}

.gs-duration-75 {
  transition-duration: 75ms;
}

.gs-ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.gs-ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

@font-face {
  font-family: Graphik;
  src: url("Graphik-Regular.woff2") format("woff2"), url("Graphik-Regular.woff") format("woff"), url("Graphik-Regular.ttf") format("truetype"), url("Graphik-Regular.svg#Graphik-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Graphik;
  src: url("Graphik-Medium.woff2") format("woff2"), url("Graphik-Medium.woff") format("woff"), url("Graphik-Medium.ttf") format("truetype"), url("Graphik-Medium.svg#Graphik-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Athletics;
  src: url("Athletics-Bold.woff2") format("woff2"), url("Athletics-Bold.woff") format("woff"), url("Athletics-Bold.ttf") format("truetype"), url("Athletics-Bold.svg#Athletics-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Athletics;
  src: url("Athletics-Medium.woff2") format("woff2"), url("Athletics-Medium.woff") format("woff"), url("Athletics-Medium.ttf") format("truetype"), url("Athletics-Medium.svg#Athletics-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Athletics;
  src: url("Athletics-Regular.woff2") format("woff2"), url("Athletics-Regular.woff") format("woff"), url("Athletics-Regular.ttf") format("truetype"), url("Athletics-Regular.svg#Athletics-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DM Sans;
  src: url("DMSans-Bold.woff2") format("woff2"), url("DMSans-Bold.woff") format("woff"), url("DMSans-Bold.ttf") format("truetype"), url("DMSans-Bold.svg#DMSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DM Sans;
  src: url("DMSans-Medium.woff2") format("woff2"), url("DMSans-Medium.woff") format("woff"), url("DMSans-Medium.ttf") format("truetype"), url("DMSans-Medium.svg#DMSans-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DM Sans;
  src: url("DMSans-Regular.woff2") format("woff2"), url("DMSans-Regular.woff") format("woff"), url("DMSans-Regular.ttf") format("truetype"), url("DMSans-Regular.svg#DMSans-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.first\:gs-rounded-t-xl:first-child {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.checked\:gs-bg-gray-800:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity) );
}

.hover\:gs-cursor-pointer:hover {
  cursor: pointer;
}

.hover\:gs-border-stone-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.hover\:gs-border-gray-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.hover\:gs-bg-blueslate-light:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(90 129 136 / var(--tw-bg-opacity) );
}

.hover\:gs-bg-beige-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity) );
}

.hover\:gs-bg-gradient-to-r:hover {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.hover\:gs-from-orange-400:hover {
  --tw-gradient-from: #fb923c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fb923c00);
}

.hover\:gs-to-pink-400:hover {
  --tw-gradient-to: #f472b6;
}

.hover\:gs-text-stone-500:hover {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity) );
}

.hover\:gs-text-twilight-900:hover {
  --tw-text-opacity: 1;
  color: rgb(10 36 48 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity) );
}

.hover\:gs-shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:gs-shadow-orange-400\/50:hover {
  --tw-shadow-color: #fb923c80;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:gs-ring-2:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:gs-border-stone-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.focus\:gs-outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.disabled\:gs-border-beige-500:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 211 / var(--tw-border-opacity) );
}

.disabled\:gs-text-stone-300:disabled {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity) );
}

@media (min-width: 640px) {
  .sm\:gs-pt-0 {
    padding-top: 0;
  }
}

@media (min-width: 768px) {
  .md\:gs-w-1\/2 {
    width: 50%;
  }

  .md\:gs-flex-row {
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  .lg\:gs-relative {
    position: relative;
  }

  .lg\:gs-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:gs-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:gs-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:gs-mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:gs-block {
    display: block;
  }

  .lg\:gs-flex {
    display: flex;
  }

  .lg\:gs-inline-flex {
    display: inline-flex;
  }

  .lg\:gs-hidden {
    display: none;
  }

  .lg\:gs-h-full {
    height: 100%;
  }

  .lg\:gs-w-fit {
    width: fit-content;
  }

  .lg\:gs-w-96 {
    width: 24rem;
  }

  .lg\:gs-max-w-\[260px\] {
    max-width: 260px;
  }

  .lg\:gs-basis-1\/2 {
    flex-basis: 50%;
  }

  .lg\:gs-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:gs-flex-row {
    flex-direction: row;
  }

  .lg\:gs-items-center {
    align-items: center;
  }

  .lg\:gs-justify-center {
    justify-content: center;
  }

  .lg\:gs-gap-4 {
    gap: 1rem;
  }

  .lg\:gs-rounded-xl {
    border-radius: .75rem;
  }

  .lg\:gs-border {
    border-width: 1px;
  }

  .lg\:gs-border-beige-500 {
    --tw-border-opacity: 1;
    border-color: rgb(220 217 211 / var(--tw-border-opacity) );
  }

  .lg\:gs-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  }

  .lg\:gs-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:gs-pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:gs-pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:gs-text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:gs-text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:gs-shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:gs-drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:last\:gs-rounded-b-xl:last-child {
    border-bottom-left-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:gs-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .\32 xl\:gs-col-span-1 {
    grid-column: span 1 / span 1;
  }
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.gs-container {
  width: 100%;
}

@media (min-width: 640px) {
  .gs-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .gs-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .gs-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .gs-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .gs-container {
    max-width: 1536px;
  }
}

.gs-prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.gs-prose :where([class~="lead"]):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.gs-prose :where(a):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.gs-prose :where(strong):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.gs-prose :where(ol):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: decimal;
}

.gs-prose :where(ol[type="A"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.gs-prose :where(ol[type="a"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.gs-prose :where(ol[type="A" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.gs-prose :where(ol[type="a" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.gs-prose :where(ol[type="I"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-roman;
}

.gs-prose :where(ol[type="i"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-roman;
}

.gs-prose :where(ol[type="I" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-roman;
}

.gs-prose :where(ol[type="i" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-roman;
}

.gs-prose :where(ol[type="1"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: decimal;
}

.gs-prose :where(ul):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: disc;
}

.gs-prose :where(ol > li):not(:where([class~="gs-not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.gs-prose :where(ul > li):not(:where([class~="gs-not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.gs-prose :where(hr):not(:where([class~="gs-not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.gs-prose :where(blockquote):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.gs-prose :where(blockquote p:first-of-type):not(:where([class~="gs-not-prose"] *)):before {
  content: open-quote;
}

.gs-prose :where(blockquote p:last-of-type):not(:where([class~="gs-not-prose"] *)):after {
  content: close-quote;
}

.gs-prose :where(h1):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.gs-prose :where(h1 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 900;
}

.gs-prose :where(h2):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.gs-prose :where(h2 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 800;
}

.gs-prose :where(h3):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.gs-prose :where(h3 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 700;
}

.gs-prose :where(h4):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.gs-prose :where(h4 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 700;
}

.gs-prose :where(figure > *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.gs-prose :where(figcaption):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.gs-prose :where(code):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.gs-prose :where(code):not(:where([class~="gs-not-prose"] *)):before, .gs-prose :where(code):not(:where([class~="gs-not-prose"] *)):after {
  content: "`";
}

.gs-prose :where(a code):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-links);
}

.gs-prose :where(pre):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)):before, .gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)):after {
  content: none;
}

.gs-prose :where(table):not(:where([class~="gs-not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.gs-prose :where(thead):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.gs-prose :where(thead th):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.gs-prose :where(tbody tr):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.gs-prose :where(tbody tr:last-child):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 0;
}

.gs-prose :where(tbody td):not(:where([class~="gs-not-prose"] *)) {
  vertical-align: baseline;
  padding: .571429em;
}

.gs-prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.gs-prose :where(p):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.gs-prose :where(img):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(video):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(figure):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(h2 code):not(:where([class~="gs-not-prose"] *)) {
  font-size: .875em;
}

.gs-prose :where(h3 code):not(:where([class~="gs-not-prose"] *)) {
  font-size: .9em;
}

.gs-prose :where(li):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.gs-prose :where(ol > li):not(:where([class~="gs-not-prose"] *)) {
  padding-left: .375em;
}

.gs-prose :where(ul > li):not(:where([class~="gs-not-prose"] *)) {
  padding-left: .375em;
}

.gs-prose > :where(ul > li p):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.gs-prose > :where(ul > li > :first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
}

.gs-prose > :where(ul > li > :last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.gs-prose > :where(ol > li > :first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
}

.gs-prose > :where(ol > li > :last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.gs-prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.gs-prose :where(hr + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h2 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h3 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h4 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(thead th:first-child):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 0;
}

.gs-prose :where(thead th:last-child):not(:where([class~="gs-not-prose"] *)) {
  padding-right: 0;
}

.gs-prose :where(tbody td:first-child):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 0;
}

.gs-prose :where(tbody td:last-child):not(:where([class~="gs-not-prose"] *)) {
  padding-right: 0;
}

.gs-prose > :where(:first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose > :where(:last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 0;
}

.heading {
  font-family: DM Sans, sans-serif;
  font-size: 1.313rem;
}

.gs-static {
  position: static;
}

.gs-fixed {
  position: fixed;
}

.gs-absolute {
  position: absolute;
}

.gs-relative {
  position: relative;
}

.gs-sticky {
  position: sticky;
}

.gs-inset-y-0 {
  top: 0;
  bottom: 0;
}

.gs-inset-y-1 {
  top: .25rem;
  bottom: .25rem;
}

.gs-top-0 {
  top: 0;
}

.gs-left-0 {
  left: 0;
}

.gs-right-2 {
  right: .5rem;
}

.gs-top-2 {
  top: .5rem;
}

.gs-bottom-0 {
  bottom: 0;
}

.gs-right-3 {
  right: .75rem;
}

.gs-top-3 {
  top: .75rem;
}

.gs-left-3 {
  left: .75rem;
}

.gs-left-3\.5 {
  left: .875rem;
}

.gs-z-20 {
  z-index: 20;
}

.gs-z-10 {
  z-index: 10;
}

.gs-z-0 {
  z-index: 0;
}

.gs-col-span-2 {
  grid-column: span 2 / span 2;
}

.gs-col-span-1 {
  grid-column: span 1 / span 1;
}

.gs-m-6 {
  margin: 1.5rem;
}

.gs-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.-gs-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.gs-my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.gs-my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.gs-mt-10 {
  margin-top: 2.5rem;
}

.gs-mt-14 {
  margin-top: 3.5rem;
}

.gs-mt-5 {
  margin-top: 1.25rem;
}

.gs-mt-2 {
  margin-top: .5rem;
}

.gs-mt-1 {
  margin-top: .25rem;
}

.gs-mt-4 {
  margin-top: 1rem;
}

.gs-mr-4 {
  margin-right: 1rem;
}

.gs-mt-6 {
  margin-top: 1.5rem;
}

.gs-ml-2 {
  margin-left: .5rem;
}

.gs-mt-7 {
  margin-top: 1.75rem;
}

.gs-mt-3 {
  margin-top: .75rem;
}

.gs-mr-2 {
  margin-right: .5rem;
}

.gs-ml-5 {
  margin-left: 1.25rem;
}

.gs-ml-3 {
  margin-left: .75rem;
}

.gs-mt-16 {
  margin-top: 4rem;
}

.gs--mt-16 {
  margin-top: -4rem;
}

.gs-block {
  display: block;
}

.gs-flex {
  display: flex;
}

.gs-inline-flex {
  display: inline-flex;
}

.gs-grid {
  display: grid;
}

.gs-hidden {
  display: none;
}

.gs-h-14 {
  height: 3.5rem;
}

.gs-h-11 {
  height: 2.75rem;
}

.gs-h-2 {
  height: .5rem;
}

.gs-h-5 {
  height: 1.25rem;
}

.gs-h-12 {
  height: 3rem;
}

.gs-h-3 {
  height: .75rem;
}

.gs-h-16 {
  height: 4rem;
}

.gs-h-screen {
  height: 100vh;
}

.gs-h-\[calc\(100vh_-_10\.5rem\)\] {
  height: calc(100vh - 10.5rem);
}

.gs-h-\[56px\] {
  height: 56px;
}

.gs-h-1 {
  height: .25rem;
}

.gs-max-h-screen {
  max-height: 100vh;
}

.gs-max-h-\[calc\(100vh_-_2rem\)\] {
  max-height: calc(100vh - 2rem);
}

.gs-max-h-full {
  max-height: 100%;
}

.gs-max-h-\[calc\(100vh_-_26rem\)\] {
  max-height: calc(100vh - 26rem);
}

.gs-min-h-screen {
  min-height: 100vh;
}

.gs-w-14 {
  width: 3.5rem;
}

.gs-w-20 {
  width: 5rem;
}

.gs-w-full {
  width: 100%;
}

.gs-w-3 {
  width: .75rem;
}

.gs-w-\[71px\] {
  width: 71px;
}

.gs-w-11 {
  width: 2.75rem;
}

.gs-max-w-md {
  max-width: 28rem;
}

.gs-max-w-none {
  max-width: none;
}

.gs-flex-1 {
  flex: 1;
}

.gs-flex-none {
  flex: none;
}

.gs-shrink-0 {
  flex-shrink: 0;
}

.gs-basis-full {
  flex-basis: 100%;
}

.gs-basis-1\/2 {
  flex-basis: 50%;
}

.gs-basis-2\/5 {
  flex-basis: 40%;
}

.gs-basis-3\/5 {
  flex-basis: 60%;
}

.gs-scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.gs-scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.gs-transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

@keyframes gs-pulse {
  50% {
    opacity: .5;
  }
}

.gs-animate-pulse {
  animation: gs-pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
}

.gs-cursor-pointer {
  cursor: pointer;
}

.gs-list-inside {
  list-style-position: inside;
}

.gs-list-disc {
  list-style-type: disc;
}

.gs-appearance-none {
  appearance: none;
}

.gs-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.gs-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.gs-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.gs-flex-col {
  flex-direction: column;
}

.gs-flex-wrap {
  flex-wrap: wrap;
}

.gs-items-start {
  align-items: flex-start;
}

.gs-items-end {
  align-items: flex-end;
}

.gs-items-center {
  align-items: center;
}

.gs-justify-start {
  justify-content: flex-start;
}

.gs-justify-end {
  justify-content: flex-end;
}

.gs-justify-center {
  justify-content: center;
}

.gs-justify-between {
  justify-content: space-between;
}

.gs-gap-4 {
  gap: 1rem;
}

.gs-gap-2 {
  gap: .5rem;
}

.gs-gap-5 {
  gap: 1.25rem;
}

.gs-gap-x-1 {
  column-gap: .25rem;
}

.gs-gap-y-4 {
  row-gap: 1rem;
}

.gs-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse) );
}

.gs-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse) );
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse) );
}

.gs-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse) );
}

.gs-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse) );
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse) );
}

.gs-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse) ) );
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse) );
}

.gs-overflow-y-auto {
  overflow-y: auto;
}

.gs-overflow-y-hidden {
  overflow-y: hidden;
}

.gs-rounded-xl {
  border-radius: .75rem;
}

.gs-rounded-md {
  border-radius: .375rem;
}

.gs-rounded-full {
  border-radius: 9999px;
}

.gs-rounded {
  border-radius: .25rem;
}

.gs-rounded-3xl {
  border-radius: 1.5rem;
}

.gs-rounded-sm {
  border-radius: .125rem;
}

.gs-rounded-lg {
  border-radius: .5rem;
}

.gs-rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.gs-border {
  border-width: 1px;
}

.gs-border-0 {
  border-width: 0;
}

.gs-border-t {
  border-top-width: 1px;
}

.gs-border-none {
  border-style: none;
}

.gs-border-beige-500 {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 211 / var(--tw-border-opacity) );
}

.gs-border-beige-400 {
  --tw-border-opacity: 1;
  border-color: rgb(160 160 158 / var(--tw-border-opacity) );
}

.gs-border-stone-800 {
  --tw-border-opacity: 1;
  border-color: rgb(41 37 36 / var(--tw-border-opacity) );
}

.gs-border-stone-500 {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.gs-border-blueslate {
  --tw-border-opacity: 1;
  border-color: rgb(56 91 111 / var(--tw-border-opacity) );
}

.gs-border-beige-600 {
  --tw-border-opacity: 1;
  border-color: rgb(122 122 122 / var(--tw-border-opacity) );
}

.gs-border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
}

.gs-border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.gs-bg-beige-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 252 251 / var(--tw-bg-opacity) );
}

.gs-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.gs-bg-beige-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 217 211 / var(--tw-bg-opacity) );
}

.gs-bg-beige-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity) );
}

.gs-bg-beige-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 238 234 / var(--tw-bg-opacity) );
}

.gs-bg-blueslate {
  --tw-bg-opacity: 1;
  background-color: rgb(56 91 111 / var(--tw-bg-opacity) );
}

.gs-bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity) );
}

.gs-bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity) );
}

.gs-bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.gs-from-green-100 {
  --tw-gradient-from: #dcfce7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dcfce700);
}

.gs-to-cyan-100 {
  --tw-gradient-to: #cffafe;
}

.gs-p-10 {
  padding: 2.5rem;
}

.gs-p-6 {
  padding: 1.5rem;
}

.gs-p-2 {
  padding: .5rem;
}

.gs-p-5 {
  padding: 1.25rem;
}

.gs-p-1 {
  padding: .25rem;
}

.gs-p-8 {
  padding: 2rem;
}

.gs-py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.gs-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.gs-py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.gs-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.gs-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.gs-px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.gs-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.gs-px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.gs-px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.gs-pt-8 {
  padding-top: 2rem;
}

.gs-pb-4 {
  padding-bottom: 1rem;
}

.gs-pt-4 {
  padding-top: 1rem;
}

.gs-pl-5 {
  padding-left: 1.25rem;
}

.gs-pl-12 {
  padding-left: 3rem;
}

.gs-pr-3 {
  padding-right: .75rem;
}

.gs-text-center {
  text-align: center;
}

.gs-text-right {
  text-align: right;
}

.gs-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.gs-text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.gs-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.gs-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.gs-text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.gs-text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.gs-text-\[15px\] {
  font-size: 15px;
}

.gs-text-\[16px\] {
  font-size: 16px;
}

.gs-font-medium {
  font-weight: 500;
}

.gs-font-bold {
  font-weight: 700;
}

.gs-text-twilight-900 {
  --tw-text-opacity: 1;
  color: rgb(10 36 48 / var(--tw-text-opacity) );
}

.gs-text-beige-400 {
  --tw-text-opacity: 1;
  color: rgb(160 160 158 / var(--tw-text-opacity) );
}

.gs-text-granite-500 {
  --tw-text-opacity: 1;
  color: rgb(71 71 71 / var(--tw-text-opacity) );
}

.gs-text-stone-300 {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity) );
}

.gs-text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity) );
}

.gs-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.gs-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.gs-text-beige-600 {
  --tw-text-opacity: 1;
  color: rgb(122 122 122 / var(--tw-text-opacity) );
}

.gs-text-stone-600 {
  --tw-text-opacity: 1;
  color: rgb(87 83 78 / var(--tw-text-opacity) );
}

.gs-text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity) );
}

.gs-text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.gs-underline {
  text-decoration-line: underline;
}

.gs-opacity-0 {
  opacity: 0;
}

.gs-opacity-100 {
  opacity: 1;
}

.gs-shadow-\[0_-3px_5px_3px_rgba\(0\,0\,0\,0\.1\)\] {
  --tw-shadow: 0 -3px 5px 3px #0000001a;
  --tw-shadow-colored: 0 -3px 5px 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.gs-shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.gs-ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.gs-ring-gray-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity) );
}

.gs-ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.gs-drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.gs-transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.gs-duration-100 {
  transition-duration: .1s;
}

.gs-duration-75 {
  transition-duration: 75ms;
}

.gs-ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.gs-ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

@font-face {
  font-family: Graphik;
  src: url("Graphik-Regular.woff2") format("woff2"), url("Graphik-Regular.woff") format("woff"), url("Graphik-Regular.ttf") format("truetype"), url("Graphik-Regular.svg#Graphik-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Graphik;
  src: url("Graphik-Medium.woff2") format("woff2"), url("Graphik-Medium.woff") format("woff"), url("Graphik-Medium.ttf") format("truetype"), url("Graphik-Medium.svg#Graphik-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Athletics;
  src: url("Athletics-Bold.woff2") format("woff2"), url("Athletics-Bold.woff") format("woff"), url("Athletics-Bold.ttf") format("truetype"), url("Athletics-Bold.svg#Athletics-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Athletics;
  src: url("Athletics-Medium.woff2") format("woff2"), url("Athletics-Medium.woff") format("woff"), url("Athletics-Medium.ttf") format("truetype"), url("Athletics-Medium.svg#Athletics-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Athletics;
  src: url("Athletics-Regular.woff2") format("woff2"), url("Athletics-Regular.woff") format("woff"), url("Athletics-Regular.ttf") format("truetype"), url("Athletics-Regular.svg#Athletics-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DM Sans;
  src: url("DMSans-Bold.woff2") format("woff2"), url("DMSans-Bold.woff") format("woff"), url("DMSans-Bold.ttf") format("truetype"), url("DMSans-Bold.svg#DMSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DM Sans;
  src: url("DMSans-Medium.woff2") format("woff2"), url("DMSans-Medium.woff") format("woff"), url("DMSans-Medium.ttf") format("truetype"), url("DMSans-Medium.svg#DMSans-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DM Sans;
  src: url("DMSans-Regular.woff2") format("woff2"), url("DMSans-Regular.woff") format("woff"), url("DMSans-Regular.ttf") format("truetype"), url("DMSans-Regular.svg#DMSans-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.candidate-dash .result-item {
  border-color: #efeeea;
  border-top-style: none;
  border-top-width: medium;
  border-left-style: none;
  border-left-width: medium;
  border-right-style: none;
  border-right-width: medium;
  margin-bottom: 0;
}

.candidate-dash .result-item .result-title {
  font-family: DM Sans, sans-serif;
  font-size: 1rem;
  font-weight: 500;
}

.first\:gs-rounded-t-xl:first-child {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.checked\:gs-bg-gray-800:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity) );
}

.hover\:gs-cursor-pointer:hover {
  cursor: pointer;
}

.hover\:gs-border-stone-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.hover\:gs-border-gray-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.hover\:gs-bg-blueslate-light:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(90 129 136 / var(--tw-bg-opacity) );
}

.hover\:gs-bg-beige-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity) );
}

.hover\:gs-bg-gradient-to-r:hover {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.hover\:gs-from-orange-400:hover {
  --tw-gradient-from: #fb923c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fb923c00);
}

.hover\:gs-to-pink-400:hover {
  --tw-gradient-to: #f472b6;
}

.hover\:gs-text-stone-500:hover {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity) );
}

.hover\:gs-text-twilight-900:hover {
  --tw-text-opacity: 1;
  color: rgb(10 36 48 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity) );
}

.hover\:gs-shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:gs-shadow-orange-400\/50:hover {
  --tw-shadow-color: #fb923c80;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:gs-ring-2:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:gs-border-stone-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.focus\:gs-outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.disabled\:gs-border-beige-500:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 211 / var(--tw-border-opacity) );
}

.disabled\:gs-text-stone-300:disabled {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity) );
}

@media (min-width: 640px) {
  .sm\:gs-pt-0 {
    padding-top: 0;
  }
}

@media (min-width: 768px) {
  .md\:gs-w-1\/2 {
    width: 50%;
  }

  .md\:gs-flex-row {
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  .lg\:gs-relative {
    position: relative;
  }

  .lg\:gs-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:gs-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:gs-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:gs-mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:gs-block {
    display: block;
  }

  .lg\:gs-flex {
    display: flex;
  }

  .lg\:gs-inline-flex {
    display: inline-flex;
  }

  .lg\:gs-hidden {
    display: none;
  }

  .lg\:gs-h-full {
    height: 100%;
  }

  .lg\:gs-w-fit {
    width: fit-content;
  }

  .lg\:gs-w-96 {
    width: 24rem;
  }

  .lg\:gs-max-w-\[260px\] {
    max-width: 260px;
  }

  .lg\:gs-basis-1\/2 {
    flex-basis: 50%;
  }

  .lg\:gs-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:gs-flex-row {
    flex-direction: row;
  }

  .lg\:gs-items-center {
    align-items: center;
  }

  .lg\:gs-justify-center {
    justify-content: center;
  }

  .lg\:gs-gap-4 {
    gap: 1rem;
  }

  .lg\:gs-rounded-xl {
    border-radius: .75rem;
  }

  .lg\:gs-border {
    border-width: 1px;
  }

  .lg\:gs-border-beige-500 {
    --tw-border-opacity: 1;
    border-color: rgb(220 217 211 / var(--tw-border-opacity) );
  }

  .lg\:gs-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  }

  .lg\:gs-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:gs-pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:gs-pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:gs-text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:gs-text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:gs-shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:gs-drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:last\:gs-rounded-b-xl:last-child {
    border-bottom-left-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:gs-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .\32 xl\:gs-col-span-1 {
    grid-column: span 1 / span 1;
  }
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.gs-container {
  width: 100%;
}

@media (min-width: 640px) {
  .gs-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .gs-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .gs-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .gs-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .gs-container {
    max-width: 1536px;
  }
}

.gs-prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.gs-prose :where([class~="lead"]):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.gs-prose :where(a):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.gs-prose :where(strong):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.gs-prose :where(ol):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: decimal;
}

.gs-prose :where(ol[type="A"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.gs-prose :where(ol[type="a"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.gs-prose :where(ol[type="A" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.gs-prose :where(ol[type="a" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.gs-prose :where(ol[type="I"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-roman;
}

.gs-prose :where(ol[type="i"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-roman;
}

.gs-prose :where(ol[type="I" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-roman;
}

.gs-prose :where(ol[type="i" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-roman;
}

.gs-prose :where(ol[type="1"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: decimal;
}

.gs-prose :where(ul):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: disc;
}

.gs-prose :where(ol > li):not(:where([class~="gs-not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.gs-prose :where(ul > li):not(:where([class~="gs-not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.gs-prose :where(hr):not(:where([class~="gs-not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.gs-prose :where(blockquote):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.gs-prose :where(blockquote p:first-of-type):not(:where([class~="gs-not-prose"] *)):before {
  content: open-quote;
}

.gs-prose :where(blockquote p:last-of-type):not(:where([class~="gs-not-prose"] *)):after {
  content: close-quote;
}

.gs-prose :where(h1):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.gs-prose :where(h1 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 900;
}

.gs-prose :where(h2):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.gs-prose :where(h2 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 800;
}

.gs-prose :where(h3):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.gs-prose :where(h3 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 700;
}

.gs-prose :where(h4):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.gs-prose :where(h4 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 700;
}

.gs-prose :where(figure > *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.gs-prose :where(figcaption):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.gs-prose :where(code):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.gs-prose :where(code):not(:where([class~="gs-not-prose"] *)):before, .gs-prose :where(code):not(:where([class~="gs-not-prose"] *)):after {
  content: "`";
}

.gs-prose :where(a code):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-links);
}

.gs-prose :where(pre):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)):before, .gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)):after {
  content: none;
}

.gs-prose :where(table):not(:where([class~="gs-not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.gs-prose :where(thead):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.gs-prose :where(thead th):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.gs-prose :where(tbody tr):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.gs-prose :where(tbody tr:last-child):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 0;
}

.gs-prose :where(tbody td):not(:where([class~="gs-not-prose"] *)) {
  vertical-align: baseline;
  padding: .571429em;
}

.gs-prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.gs-prose :where(p):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.gs-prose :where(img):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(video):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(figure):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(h2 code):not(:where([class~="gs-not-prose"] *)) {
  font-size: .875em;
}

.gs-prose :where(h3 code):not(:where([class~="gs-not-prose"] *)) {
  font-size: .9em;
}

.gs-prose :where(li):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.gs-prose :where(ol > li):not(:where([class~="gs-not-prose"] *)) {
  padding-left: .375em;
}

.gs-prose :where(ul > li):not(:where([class~="gs-not-prose"] *)) {
  padding-left: .375em;
}

.gs-prose > :where(ul > li p):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.gs-prose > :where(ul > li > :first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
}

.gs-prose > :where(ul > li > :last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.gs-prose > :where(ol > li > :first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
}

.gs-prose > :where(ol > li > :last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.gs-prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.gs-prose :where(hr + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h2 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h3 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h4 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(thead th:first-child):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 0;
}

.gs-prose :where(thead th:last-child):not(:where([class~="gs-not-prose"] *)) {
  padding-right: 0;
}

.gs-prose :where(tbody td:first-child):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 0;
}

.gs-prose :where(tbody td:last-child):not(:where([class~="gs-not-prose"] *)) {
  padding-right: 0;
}

.gs-prose > :where(:first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose > :where(:last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 0;
}

.heading {
  font-family: DM Sans, sans-serif;
  font-size: 1.313rem;
}

.gs-static {
  position: static;
}

.gs-fixed {
  position: fixed;
}

.gs-absolute {
  position: absolute;
}

.gs-relative {
  position: relative;
}

.gs-sticky {
  position: sticky;
}

.gs-inset-y-0 {
  top: 0;
  bottom: 0;
}

.gs-inset-y-1 {
  top: .25rem;
  bottom: .25rem;
}

.gs-top-0 {
  top: 0;
}

.gs-left-0 {
  left: 0;
}

.gs-right-2 {
  right: .5rem;
}

.gs-top-2 {
  top: .5rem;
}

.gs-bottom-0 {
  bottom: 0;
}

.gs-right-3 {
  right: .75rem;
}

.gs-top-3 {
  top: .75rem;
}

.gs-left-3 {
  left: .75rem;
}

.gs-left-3\.5 {
  left: .875rem;
}

.gs-z-20 {
  z-index: 20;
}

.gs-z-10 {
  z-index: 10;
}

.gs-z-0 {
  z-index: 0;
}

.gs-col-span-2 {
  grid-column: span 2 / span 2;
}

.gs-col-span-1 {
  grid-column: span 1 / span 1;
}

.gs-m-6 {
  margin: 1.5rem;
}

.gs-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.-gs-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.gs-my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.gs-my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.gs-mt-10 {
  margin-top: 2.5rem;
}

.gs-mt-14 {
  margin-top: 3.5rem;
}

.gs-mt-5 {
  margin-top: 1.25rem;
}

.gs-mt-2 {
  margin-top: .5rem;
}

.gs-mt-1 {
  margin-top: .25rem;
}

.gs-mt-4 {
  margin-top: 1rem;
}

.gs-mr-4 {
  margin-right: 1rem;
}

.gs-mt-6 {
  margin-top: 1.5rem;
}

.gs-ml-2 {
  margin-left: .5rem;
}

.gs-mt-7 {
  margin-top: 1.75rem;
}

.gs-mt-3 {
  margin-top: .75rem;
}

.gs-mr-2 {
  margin-right: .5rem;
}

.gs-ml-5 {
  margin-left: 1.25rem;
}

.gs-ml-3 {
  margin-left: .75rem;
}

.gs-mt-16 {
  margin-top: 4rem;
}

.gs--mt-16 {
  margin-top: -4rem;
}

.gs-block {
  display: block;
}

.gs-flex {
  display: flex;
}

.gs-inline-flex {
  display: inline-flex;
}

.gs-grid {
  display: grid;
}

.gs-hidden {
  display: none;
}

.gs-h-14 {
  height: 3.5rem;
}

.gs-h-11 {
  height: 2.75rem;
}

.gs-h-2 {
  height: .5rem;
}

.gs-h-5 {
  height: 1.25rem;
}

.gs-h-12 {
  height: 3rem;
}

.gs-h-3 {
  height: .75rem;
}

.gs-h-16 {
  height: 4rem;
}

.gs-h-screen {
  height: 100vh;
}

.gs-h-\[calc\(100vh_-_10\.5rem\)\] {
  height: calc(100vh - 10.5rem);
}

.gs-h-\[56px\] {
  height: 56px;
}

.gs-h-1 {
  height: .25rem;
}

.gs-max-h-screen {
  max-height: 100vh;
}

.gs-max-h-\[calc\(100vh_-_2rem\)\] {
  max-height: calc(100vh - 2rem);
}

.gs-max-h-full {
  max-height: 100%;
}

.gs-max-h-\[calc\(100vh_-_26rem\)\] {
  max-height: calc(100vh - 26rem);
}

.gs-min-h-screen {
  min-height: 100vh;
}

.gs-w-14 {
  width: 3.5rem;
}

.gs-w-20 {
  width: 5rem;
}

.gs-w-full {
  width: 100%;
}

.gs-w-3 {
  width: .75rem;
}

.gs-w-\[71px\] {
  width: 71px;
}

.gs-w-11 {
  width: 2.75rem;
}

.gs-max-w-md {
  max-width: 28rem;
}

.gs-max-w-none {
  max-width: none;
}

.gs-flex-1 {
  flex: 1;
}

.gs-flex-none {
  flex: none;
}

.gs-shrink-0 {
  flex-shrink: 0;
}

.gs-basis-full {
  flex-basis: 100%;
}

.gs-basis-1\/2 {
  flex-basis: 50%;
}

.gs-basis-2\/5 {
  flex-basis: 40%;
}

.gs-basis-3\/5 {
  flex-basis: 60%;
}

.gs-scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.gs-scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.gs-transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

@keyframes gs-pulse {
  50% {
    opacity: .5;
  }
}

.gs-animate-pulse {
  animation: gs-pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
}

.gs-cursor-pointer {
  cursor: pointer;
}

.gs-list-inside {
  list-style-position: inside;
}

.gs-list-disc {
  list-style-type: disc;
}

.gs-appearance-none {
  appearance: none;
}

.gs-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.gs-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.gs-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.gs-flex-col {
  flex-direction: column;
}

.gs-flex-wrap {
  flex-wrap: wrap;
}

.gs-items-start {
  align-items: flex-start;
}

.gs-items-end {
  align-items: flex-end;
}

.gs-items-center {
  align-items: center;
}

.gs-justify-start {
  justify-content: flex-start;
}

.gs-justify-end {
  justify-content: flex-end;
}

.gs-justify-center {
  justify-content: center;
}

.gs-justify-between {
  justify-content: space-between;
}

.gs-gap-4 {
  gap: 1rem;
}

.gs-gap-2 {
  gap: .5rem;
}

.gs-gap-5 {
  gap: 1.25rem;
}

.gs-gap-x-1 {
  column-gap: .25rem;
}

.gs-gap-y-4 {
  row-gap: 1rem;
}

.gs-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse) );
}

.gs-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse) );
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse) );
}

.gs-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse) );
}

.gs-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse) );
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse) );
}

.gs-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse) ) );
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse) );
}

.gs-overflow-y-auto {
  overflow-y: auto;
}

.gs-overflow-y-hidden {
  overflow-y: hidden;
}

.gs-rounded-xl {
  border-radius: .75rem;
}

.gs-rounded-md {
  border-radius: .375rem;
}

.gs-rounded-full {
  border-radius: 9999px;
}

.gs-rounded {
  border-radius: .25rem;
}

.gs-rounded-3xl {
  border-radius: 1.5rem;
}

.gs-rounded-sm {
  border-radius: .125rem;
}

.gs-rounded-lg {
  border-radius: .5rem;
}

.gs-rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.gs-border {
  border-width: 1px;
}

.gs-border-0 {
  border-width: 0;
}

.gs-border-t {
  border-top-width: 1px;
}

.gs-border-none {
  border-style: none;
}

.gs-border-beige-500 {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 211 / var(--tw-border-opacity) );
}

.gs-border-beige-400 {
  --tw-border-opacity: 1;
  border-color: rgb(160 160 158 / var(--tw-border-opacity) );
}

.gs-border-stone-800 {
  --tw-border-opacity: 1;
  border-color: rgb(41 37 36 / var(--tw-border-opacity) );
}

.gs-border-stone-500 {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.gs-border-blueslate {
  --tw-border-opacity: 1;
  border-color: rgb(56 91 111 / var(--tw-border-opacity) );
}

.gs-border-beige-600 {
  --tw-border-opacity: 1;
  border-color: rgb(122 122 122 / var(--tw-border-opacity) );
}

.gs-border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
}

.gs-border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.gs-bg-beige-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 252 251 / var(--tw-bg-opacity) );
}

.gs-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.gs-bg-beige-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 217 211 / var(--tw-bg-opacity) );
}

.gs-bg-beige-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity) );
}

.gs-bg-beige-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 238 234 / var(--tw-bg-opacity) );
}

.gs-bg-blueslate {
  --tw-bg-opacity: 1;
  background-color: rgb(56 91 111 / var(--tw-bg-opacity) );
}

.gs-bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity) );
}

.gs-bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity) );
}

.gs-bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.gs-from-green-100 {
  --tw-gradient-from: #dcfce7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dcfce700);
}

.gs-to-cyan-100 {
  --tw-gradient-to: #cffafe;
}

.gs-p-10 {
  padding: 2.5rem;
}

.gs-p-6 {
  padding: 1.5rem;
}

.gs-p-2 {
  padding: .5rem;
}

.gs-p-5 {
  padding: 1.25rem;
}

.gs-p-1 {
  padding: .25rem;
}

.gs-p-8 {
  padding: 2rem;
}

.gs-py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.gs-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.gs-py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.gs-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.gs-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.gs-px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.gs-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.gs-px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.gs-px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.gs-pt-8 {
  padding-top: 2rem;
}

.gs-pb-4 {
  padding-bottom: 1rem;
}

.gs-pt-4 {
  padding-top: 1rem;
}

.gs-pl-5 {
  padding-left: 1.25rem;
}

.gs-pl-12 {
  padding-left: 3rem;
}

.gs-pr-3 {
  padding-right: .75rem;
}

.gs-text-center {
  text-align: center;
}

.gs-text-right {
  text-align: right;
}

.gs-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.gs-text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.gs-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.gs-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.gs-text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.gs-text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.gs-text-\[15px\] {
  font-size: 15px;
}

.gs-text-\[16px\] {
  font-size: 16px;
}

.gs-font-medium {
  font-weight: 500;
}

.gs-font-bold {
  font-weight: 700;
}

.gs-text-twilight-900 {
  --tw-text-opacity: 1;
  color: rgb(10 36 48 / var(--tw-text-opacity) );
}

.gs-text-beige-400 {
  --tw-text-opacity: 1;
  color: rgb(160 160 158 / var(--tw-text-opacity) );
}

.gs-text-granite-500 {
  --tw-text-opacity: 1;
  color: rgb(71 71 71 / var(--tw-text-opacity) );
}

.gs-text-stone-300 {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity) );
}

.gs-text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity) );
}

.gs-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.gs-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.gs-text-beige-600 {
  --tw-text-opacity: 1;
  color: rgb(122 122 122 / var(--tw-text-opacity) );
}

.gs-text-stone-600 {
  --tw-text-opacity: 1;
  color: rgb(87 83 78 / var(--tw-text-opacity) );
}

.gs-text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity) );
}

.gs-text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.gs-underline {
  text-decoration-line: underline;
}

.gs-opacity-0 {
  opacity: 0;
}

.gs-opacity-100 {
  opacity: 1;
}

.gs-shadow-\[0_-3px_5px_3px_rgba\(0\,0\,0\,0\.1\)\] {
  --tw-shadow: 0 -3px 5px 3px #0000001a;
  --tw-shadow-colored: 0 -3px 5px 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.gs-shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.gs-ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.gs-ring-gray-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity) );
}

.gs-ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.gs-drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.gs-transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.gs-duration-100 {
  transition-duration: .1s;
}

.gs-duration-75 {
  transition-duration: 75ms;
}

.gs-ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.gs-ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

@font-face {
  font-family: Graphik;
  src: url("Graphik-Regular.woff2") format("woff2"), url("Graphik-Regular.woff") format("woff"), url("Graphik-Regular.ttf") format("truetype"), url("Graphik-Regular.svg#Graphik-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Graphik;
  src: url("Graphik-Medium.woff2") format("woff2"), url("Graphik-Medium.woff") format("woff"), url("Graphik-Medium.ttf") format("truetype"), url("Graphik-Medium.svg#Graphik-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Athletics;
  src: url("Athletics-Bold.woff2") format("woff2"), url("Athletics-Bold.woff") format("woff"), url("Athletics-Bold.ttf") format("truetype"), url("Athletics-Bold.svg#Athletics-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Athletics;
  src: url("Athletics-Medium.woff2") format("woff2"), url("Athletics-Medium.woff") format("woff"), url("Athletics-Medium.ttf") format("truetype"), url("Athletics-Medium.svg#Athletics-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Athletics;
  src: url("Athletics-Regular.woff2") format("woff2"), url("Athletics-Regular.woff") format("woff"), url("Athletics-Regular.ttf") format("truetype"), url("Athletics-Regular.svg#Athletics-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DM Sans;
  src: url("DMSans-Bold.woff2") format("woff2"), url("DMSans-Bold.woff") format("woff"), url("DMSans-Bold.ttf") format("truetype"), url("DMSans-Bold.svg#DMSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DM Sans;
  src: url("DMSans-Medium.woff2") format("woff2"), url("DMSans-Medium.woff") format("woff"), url("DMSans-Medium.ttf") format("truetype"), url("DMSans-Medium.svg#DMSans-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DM Sans;
  src: url("DMSans-Regular.woff2") format("woff2"), url("DMSans-Regular.woff") format("woff"), url("DMSans-Regular.ttf") format("truetype"), url("DMSans-Regular.svg#DMSans-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.first\:gs-rounded-t-xl:first-child {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.checked\:gs-bg-gray-800:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity) );
}

.hover\:gs-cursor-pointer:hover {
  cursor: pointer;
}

.hover\:gs-border-stone-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.hover\:gs-border-gray-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.hover\:gs-bg-blueslate-light:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(90 129 136 / var(--tw-bg-opacity) );
}

.hover\:gs-bg-beige-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity) );
}

.hover\:gs-bg-gradient-to-r:hover {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.hover\:gs-from-orange-400:hover {
  --tw-gradient-from: #fb923c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fb923c00);
}

.hover\:gs-to-pink-400:hover {
  --tw-gradient-to: #f472b6;
}

.hover\:gs-text-stone-500:hover {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity) );
}

.hover\:gs-text-twilight-900:hover {
  --tw-text-opacity: 1;
  color: rgb(10 36 48 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity) );
}

.hover\:gs-shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:gs-shadow-orange-400\/50:hover {
  --tw-shadow-color: #fb923c80;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:gs-ring-2:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:gs-border-stone-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.focus\:gs-outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.disabled\:gs-border-beige-500:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 211 / var(--tw-border-opacity) );
}

.disabled\:gs-text-stone-300:disabled {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity) );
}

@media (min-width: 640px) {
  .sm\:gs-pt-0 {
    padding-top: 0;
  }
}

@media (min-width: 768px) {
  .md\:gs-w-1\/2 {
    width: 50%;
  }

  .md\:gs-flex-row {
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  .lg\:gs-relative {
    position: relative;
  }

  .lg\:gs-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:gs-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:gs-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:gs-mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:gs-block {
    display: block;
  }

  .lg\:gs-flex {
    display: flex;
  }

  .lg\:gs-inline-flex {
    display: inline-flex;
  }

  .lg\:gs-hidden {
    display: none;
  }

  .lg\:gs-h-full {
    height: 100%;
  }

  .lg\:gs-w-fit {
    width: fit-content;
  }

  .lg\:gs-w-96 {
    width: 24rem;
  }

  .lg\:gs-max-w-\[260px\] {
    max-width: 260px;
  }

  .lg\:gs-basis-1\/2 {
    flex-basis: 50%;
  }

  .lg\:gs-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:gs-flex-row {
    flex-direction: row;
  }

  .lg\:gs-items-center {
    align-items: center;
  }

  .lg\:gs-justify-center {
    justify-content: center;
  }

  .lg\:gs-gap-4 {
    gap: 1rem;
  }

  .lg\:gs-rounded-xl {
    border-radius: .75rem;
  }

  .lg\:gs-border {
    border-width: 1px;
  }

  .lg\:gs-border-beige-500 {
    --tw-border-opacity: 1;
    border-color: rgb(220 217 211 / var(--tw-border-opacity) );
  }

  .lg\:gs-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  }

  .lg\:gs-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:gs-pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:gs-pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:gs-text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:gs-text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:gs-shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:gs-drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:last\:gs-rounded-b-xl:last-child {
    border-bottom-left-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:gs-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .\32 xl\:gs-col-span-1 {
    grid-column: span 1 / span 1;
  }
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.gs-container {
  width: 100%;
}

@media (min-width: 640px) {
  .gs-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .gs-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .gs-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .gs-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .gs-container {
    max-width: 1536px;
  }
}

.gs-prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.gs-prose :where([class~="lead"]):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.gs-prose :where(a):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.gs-prose :where(strong):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.gs-prose :where(ol):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: decimal;
}

.gs-prose :where(ol[type="A"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.gs-prose :where(ol[type="a"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.gs-prose :where(ol[type="A" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.gs-prose :where(ol[type="a" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.gs-prose :where(ol[type="I"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-roman;
}

.gs-prose :where(ol[type="i"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-roman;
}

.gs-prose :where(ol[type="I" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: upper-roman;
}

.gs-prose :where(ol[type="i" s]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: lower-roman;
}

.gs-prose :where(ol[type="1"]):not(:where([class~="gs-not-prose"] *)) {
  list-style-type: decimal;
}

.gs-prose :where(ul):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: disc;
}

.gs-prose :where(ol > li):not(:where([class~="gs-not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.gs-prose :where(ul > li):not(:where([class~="gs-not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.gs-prose :where(hr):not(:where([class~="gs-not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.gs-prose :where(blockquote):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.gs-prose :where(blockquote p:first-of-type):not(:where([class~="gs-not-prose"] *)):before {
  content: open-quote;
}

.gs-prose :where(blockquote p:last-of-type):not(:where([class~="gs-not-prose"] *)):after {
  content: close-quote;
}

.gs-prose :where(h1):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.gs-prose :where(h1 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 900;
}

.gs-prose :where(h2):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.gs-prose :where(h2 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 800;
}

.gs-prose :where(h3):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.gs-prose :where(h3 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 700;
}

.gs-prose :where(h4):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.gs-prose :where(h4 strong):not(:where([class~="gs-not-prose"] *)) {
  font-weight: 700;
}

.gs-prose :where(figure > *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.gs-prose :where(figcaption):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.gs-prose :where(code):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.gs-prose :where(code):not(:where([class~="gs-not-prose"] *)):before, .gs-prose :where(code):not(:where([class~="gs-not-prose"] *)):after {
  content: "`";
}

.gs-prose :where(a code):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-links);
}

.gs-prose :where(pre):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)):before, .gs-prose :where(pre code):not(:where([class~="gs-not-prose"] *)):after {
  content: none;
}

.gs-prose :where(table):not(:where([class~="gs-not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.gs-prose :where(thead):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.gs-prose :where(thead th):not(:where([class~="gs-not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.gs-prose :where(tbody tr):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.gs-prose :where(tbody tr:last-child):not(:where([class~="gs-not-prose"] *)) {
  border-bottom-width: 0;
}

.gs-prose :where(tbody td):not(:where([class~="gs-not-prose"] *)) {
  vertical-align: baseline;
  padding: .571429em;
}

.gs-prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.gs-prose :where(p):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.gs-prose :where(img):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(video):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(figure):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gs-prose :where(h2 code):not(:where([class~="gs-not-prose"] *)) {
  font-size: .875em;
}

.gs-prose :where(h3 code):not(:where([class~="gs-not-prose"] *)) {
  font-size: .9em;
}

.gs-prose :where(li):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.gs-prose :where(ol > li):not(:where([class~="gs-not-prose"] *)) {
  padding-left: .375em;
}

.gs-prose :where(ul > li):not(:where([class~="gs-not-prose"] *)) {
  padding-left: .375em;
}

.gs-prose > :where(ul > li p):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.gs-prose > :where(ul > li > :first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
}

.gs-prose > :where(ul > li > :last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.gs-prose > :where(ol > li > :first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 1.25em;
}

.gs-prose > :where(ol > li > :last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.gs-prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="gs-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.gs-prose :where(hr + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h2 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h3 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(h4 + *):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose :where(thead th:first-child):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 0;
}

.gs-prose :where(thead th:last-child):not(:where([class~="gs-not-prose"] *)) {
  padding-right: 0;
}

.gs-prose :where(tbody td:first-child):not(:where([class~="gs-not-prose"] *)) {
  padding-left: 0;
}

.gs-prose :where(tbody td:last-child):not(:where([class~="gs-not-prose"] *)) {
  padding-right: 0;
}

.gs-prose > :where(:first-child):not(:where([class~="gs-not-prose"] *)) {
  margin-top: 0;
}

.gs-prose > :where(:last-child):not(:where([class~="gs-not-prose"] *)) {
  margin-bottom: 0;
}

.heading {
  font-family: DM Sans, sans-serif;
  font-size: 1.313rem;
}

.gs-static {
  position: static;
}

.gs-fixed {
  position: fixed;
}

.gs-absolute {
  position: absolute;
}

.gs-relative {
  position: relative;
}

.gs-sticky {
  position: sticky;
}

.gs-inset-y-0 {
  top: 0;
  bottom: 0;
}

.gs-inset-y-1 {
  top: .25rem;
  bottom: .25rem;
}

.gs-top-0 {
  top: 0;
}

.gs-left-0 {
  left: 0;
}

.gs-right-2 {
  right: .5rem;
}

.gs-top-2 {
  top: .5rem;
}

.gs-bottom-0 {
  bottom: 0;
}

.gs-right-3 {
  right: .75rem;
}

.gs-top-3 {
  top: .75rem;
}

.gs-left-3 {
  left: .75rem;
}

.gs-left-3\.5 {
  left: .875rem;
}

.gs-z-20 {
  z-index: 20;
}

.gs-z-10 {
  z-index: 10;
}

.gs-z-0 {
  z-index: 0;
}

.gs-col-span-2 {
  grid-column: span 2 / span 2;
}

.gs-col-span-1 {
  grid-column: span 1 / span 1;
}

.gs-m-6 {
  margin: 1.5rem;
}

.gs-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.-gs-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.gs-my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.gs-my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.gs-mt-10 {
  margin-top: 2.5rem;
}

.gs-mt-14 {
  margin-top: 3.5rem;
}

.gs-mt-5 {
  margin-top: 1.25rem;
}

.gs-mt-2 {
  margin-top: .5rem;
}

.gs-mt-1 {
  margin-top: .25rem;
}

.gs-mt-4 {
  margin-top: 1rem;
}

.gs-mr-4 {
  margin-right: 1rem;
}

.gs-mt-6 {
  margin-top: 1.5rem;
}

.gs-ml-2 {
  margin-left: .5rem;
}

.gs-mt-7 {
  margin-top: 1.75rem;
}

.gs-mt-3 {
  margin-top: .75rem;
}

.gs-mr-2 {
  margin-right: .5rem;
}

.gs-ml-5 {
  margin-left: 1.25rem;
}

.gs-ml-3 {
  margin-left: .75rem;
}

.gs-mt-16 {
  margin-top: 4rem;
}

.gs--mt-16 {
  margin-top: -4rem;
}

.gs-block {
  display: block;
}

.gs-flex {
  display: flex;
}

.gs-inline-flex {
  display: inline-flex;
}

.gs-grid {
  display: grid;
}

.gs-hidden {
  display: none;
}

.gs-h-14 {
  height: 3.5rem;
}

.gs-h-11 {
  height: 2.75rem;
}

.gs-h-2 {
  height: .5rem;
}

.gs-h-5 {
  height: 1.25rem;
}

.gs-h-12 {
  height: 3rem;
}

.gs-h-3 {
  height: .75rem;
}

.gs-h-16 {
  height: 4rem;
}

.gs-h-screen {
  height: 100vh;
}

.gs-h-\[calc\(100vh_-_10\.5rem\)\] {
  height: calc(100vh - 10.5rem);
}

.gs-h-\[56px\] {
  height: 56px;
}

.gs-h-1 {
  height: .25rem;
}

.gs-max-h-screen {
  max-height: 100vh;
}

.gs-max-h-\[calc\(100vh_-_2rem\)\] {
  max-height: calc(100vh - 2rem);
}

.gs-max-h-full {
  max-height: 100%;
}

.gs-max-h-\[calc\(100vh_-_26rem\)\] {
  max-height: calc(100vh - 26rem);
}

.gs-min-h-screen {
  min-height: 100vh;
}

.gs-w-14 {
  width: 3.5rem;
}

.gs-w-20 {
  width: 5rem;
}

.gs-w-full {
  width: 100%;
}

.gs-w-3 {
  width: .75rem;
}

.gs-w-\[71px\] {
  width: 71px;
}

.gs-w-11 {
  width: 2.75rem;
}

.gs-max-w-md {
  max-width: 28rem;
}

.gs-max-w-none {
  max-width: none;
}

.gs-flex-1 {
  flex: 1;
}

.gs-flex-none {
  flex: none;
}

.gs-shrink-0 {
  flex-shrink: 0;
}

.gs-basis-full {
  flex-basis: 100%;
}

.gs-basis-1\/2 {
  flex-basis: 50%;
}

.gs-basis-2\/5 {
  flex-basis: 40%;
}

.gs-basis-3\/5 {
  flex-basis: 60%;
}

.gs-scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.gs-scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.gs-transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

@keyframes gs-pulse {
  50% {
    opacity: .5;
  }
}

.gs-animate-pulse {
  animation: gs-pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
}

.gs-cursor-pointer {
  cursor: pointer;
}

.gs-list-inside {
  list-style-position: inside;
}

.gs-list-disc {
  list-style-type: disc;
}

.gs-appearance-none {
  appearance: none;
}

.gs-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.gs-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.gs-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.gs-flex-col {
  flex-direction: column;
}

.gs-flex-wrap {
  flex-wrap: wrap;
}

.gs-items-start {
  align-items: flex-start;
}

.gs-items-end {
  align-items: flex-end;
}

.gs-items-center {
  align-items: center;
}

.gs-justify-start {
  justify-content: flex-start;
}

.gs-justify-end {
  justify-content: flex-end;
}

.gs-justify-center {
  justify-content: center;
}

.gs-justify-between {
  justify-content: space-between;
}

.gs-gap-4 {
  gap: 1rem;
}

.gs-gap-2 {
  gap: .5rem;
}

.gs-gap-5 {
  gap: 1.25rem;
}

.gs-gap-x-1 {
  column-gap: .25rem;
}

.gs-gap-y-4 {
  row-gap: 1rem;
}

.gs-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse) );
}

.gs-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse) );
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse) );
}

.gs-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse) );
}

.gs-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse) );
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse) );
}

.gs-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.gs-divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse) ) );
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse) );
}

.gs-overflow-y-auto {
  overflow-y: auto;
}

.gs-overflow-y-hidden {
  overflow-y: hidden;
}

.gs-rounded-xl {
  border-radius: .75rem;
}

.gs-rounded-md {
  border-radius: .375rem;
}

.gs-rounded-full {
  border-radius: 9999px;
}

.gs-rounded {
  border-radius: .25rem;
}

.gs-rounded-3xl {
  border-radius: 1.5rem;
}

.gs-rounded-sm {
  border-radius: .125rem;
}

.gs-rounded-lg {
  border-radius: .5rem;
}

.gs-rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.gs-border {
  border-width: 1px;
}

.gs-border-0 {
  border-width: 0;
}

.gs-border-t {
  border-top-width: 1px;
}

.gs-border-none {
  border-style: none;
}

.gs-border-beige-500 {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 211 / var(--tw-border-opacity) );
}

.gs-border-beige-400 {
  --tw-border-opacity: 1;
  border-color: rgb(160 160 158 / var(--tw-border-opacity) );
}

.gs-border-stone-800 {
  --tw-border-opacity: 1;
  border-color: rgb(41 37 36 / var(--tw-border-opacity) );
}

.gs-border-stone-500 {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.gs-border-blueslate {
  --tw-border-opacity: 1;
  border-color: rgb(56 91 111 / var(--tw-border-opacity) );
}

.gs-border-beige-600 {
  --tw-border-opacity: 1;
  border-color: rgb(122 122 122 / var(--tw-border-opacity) );
}

.gs-border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
}

.gs-border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.gs-bg-beige-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 252 251 / var(--tw-bg-opacity) );
}

.gs-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.gs-bg-beige-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 217 211 / var(--tw-bg-opacity) );
}

.gs-bg-beige-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity) );
}

.gs-bg-beige-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 238 234 / var(--tw-bg-opacity) );
}

.gs-bg-blueslate {
  --tw-bg-opacity: 1;
  background-color: rgb(56 91 111 / var(--tw-bg-opacity) );
}

.gs-bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity) );
}

.gs-bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity) );
}

.gs-bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.gs-from-green-100 {
  --tw-gradient-from: #dcfce7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dcfce700);
}

.gs-to-cyan-100 {
  --tw-gradient-to: #cffafe;
}

.gs-p-10 {
  padding: 2.5rem;
}

.gs-p-6 {
  padding: 1.5rem;
}

.gs-p-2 {
  padding: .5rem;
}

.gs-p-5 {
  padding: 1.25rem;
}

.gs-p-1 {
  padding: .25rem;
}

.gs-p-8 {
  padding: 2rem;
}

.gs-py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.gs-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.gs-py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.gs-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.gs-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.gs-px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.gs-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.gs-px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.gs-px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.gs-pt-8 {
  padding-top: 2rem;
}

.gs-pb-4 {
  padding-bottom: 1rem;
}

.gs-pt-4 {
  padding-top: 1rem;
}

.gs-pl-5 {
  padding-left: 1.25rem;
}

.gs-pl-12 {
  padding-left: 3rem;
}

.gs-pr-3 {
  padding-right: .75rem;
}

.gs-text-center {
  text-align: center;
}

.gs-text-right {
  text-align: right;
}

.gs-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.gs-text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.gs-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.gs-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.gs-text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.gs-text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.gs-text-\[15px\] {
  font-size: 15px;
}

.gs-text-\[16px\] {
  font-size: 16px;
}

.gs-font-medium {
  font-weight: 500;
}

.gs-font-bold {
  font-weight: 700;
}

.gs-text-twilight-900 {
  --tw-text-opacity: 1;
  color: rgb(10 36 48 / var(--tw-text-opacity) );
}

.gs-text-beige-400 {
  --tw-text-opacity: 1;
  color: rgb(160 160 158 / var(--tw-text-opacity) );
}

.gs-text-granite-500 {
  --tw-text-opacity: 1;
  color: rgb(71 71 71 / var(--tw-text-opacity) );
}

.gs-text-stone-300 {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity) );
}

.gs-text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity) );
}

.gs-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.gs-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.gs-text-beige-600 {
  --tw-text-opacity: 1;
  color: rgb(122 122 122 / var(--tw-text-opacity) );
}

.gs-text-stone-600 {
  --tw-text-opacity: 1;
  color: rgb(87 83 78 / var(--tw-text-opacity) );
}

.gs-text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity) );
}

.gs-text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.gs-underline {
  text-decoration-line: underline;
}

.gs-opacity-0 {
  opacity: 0;
}

.gs-opacity-100 {
  opacity: 1;
}

.gs-shadow-\[0_-3px_5px_3px_rgba\(0\,0\,0\,0\.1\)\] {
  --tw-shadow: 0 -3px 5px 3px #0000001a;
  --tw-shadow-colored: 0 -3px 5px 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.gs-shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.gs-ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.gs-ring-gray-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity) );
}

.gs-ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.gs-drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.gs-transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.gs-duration-100 {
  transition-duration: .1s;
}

.gs-duration-75 {
  transition-duration: 75ms;
}

.gs-ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.gs-ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

@font-face {
  font-family: Graphik;
  src: url("Graphik-Regular.woff2") format("woff2"), url("Graphik-Regular.woff") format("woff"), url("Graphik-Regular.ttf") format("truetype"), url("Graphik-Regular.svg#Graphik-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Graphik;
  src: url("Graphik-Medium.woff2") format("woff2"), url("Graphik-Medium.woff") format("woff"), url("Graphik-Medium.ttf") format("truetype"), url("Graphik-Medium.svg#Graphik-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Athletics;
  src: url("Athletics-Bold.woff2") format("woff2"), url("Athletics-Bold.woff") format("woff"), url("Athletics-Bold.ttf") format("truetype"), url("Athletics-Bold.svg#Athletics-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Athletics;
  src: url("Athletics-Medium.woff2") format("woff2"), url("Athletics-Medium.woff") format("woff"), url("Athletics-Medium.ttf") format("truetype"), url("Athletics-Medium.svg#Athletics-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Athletics;
  src: url("Athletics-Regular.woff2") format("woff2"), url("Athletics-Regular.woff") format("woff"), url("Athletics-Regular.ttf") format("truetype"), url("Athletics-Regular.svg#Athletics-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DM Sans;
  src: url("DMSans-Bold.woff2") format("woff2"), url("DMSans-Bold.woff") format("woff"), url("DMSans-Bold.ttf") format("truetype"), url("DMSans-Bold.svg#DMSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DM Sans;
  src: url("DMSans-Medium.woff2") format("woff2"), url("DMSans-Medium.woff") format("woff"), url("DMSans-Medium.ttf") format("truetype"), url("DMSans-Medium.svg#DMSans-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DM Sans;
  src: url("DMSans-Regular.woff2") format("woff2"), url("DMSans-Regular.woff") format("woff"), url("DMSans-Regular.ttf") format("truetype"), url("DMSans-Regular.svg#DMSans-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.first\:gs-rounded-t-xl:first-child {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.checked\:gs-bg-gray-800:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity) );
}

.hover\:gs-cursor-pointer:hover {
  cursor: pointer;
}

.hover\:gs-border-stone-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.hover\:gs-border-gray-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.hover\:gs-bg-blueslate-light:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(90 129 136 / var(--tw-bg-opacity) );
}

.hover\:gs-bg-beige-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity) );
}

.hover\:gs-bg-gradient-to-r:hover {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.hover\:gs-from-orange-400:hover {
  --tw-gradient-from: #fb923c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fb923c00);
}

.hover\:gs-to-pink-400:hover {
  --tw-gradient-to: #f472b6;
}

.hover\:gs-text-stone-500:hover {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity) );
}

.hover\:gs-text-twilight-900:hover {
  --tw-text-opacity: 1;
  color: rgb(10 36 48 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.hover\:gs-text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity) );
}

.hover\:gs-shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:gs-shadow-orange-400\/50:hover {
  --tw-shadow-color: #fb923c80;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:gs-ring-2:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:gs-border-stone-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity) );
}

.focus\:gs-outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.disabled\:gs-border-beige-500:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 211 / var(--tw-border-opacity) );
}

.disabled\:gs-text-stone-300:disabled {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity) );
}

@media (min-width: 640px) {
  .sm\:gs-pt-0 {
    padding-top: 0;
  }
}

@media (min-width: 768px) {
  .md\:gs-w-1\/2 {
    width: 50%;
  }

  .md\:gs-flex-row {
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  .lg\:gs-relative {
    position: relative;
  }

  .lg\:gs-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:gs-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:gs-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:gs-mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:gs-block {
    display: block;
  }

  .lg\:gs-flex {
    display: flex;
  }

  .lg\:gs-inline-flex {
    display: inline-flex;
  }

  .lg\:gs-hidden {
    display: none;
  }

  .lg\:gs-h-full {
    height: 100%;
  }

  .lg\:gs-w-fit {
    width: fit-content;
  }

  .lg\:gs-w-96 {
    width: 24rem;
  }

  .lg\:gs-max-w-\[260px\] {
    max-width: 260px;
  }

  .lg\:gs-basis-1\/2 {
    flex-basis: 50%;
  }

  .lg\:gs-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:gs-flex-row {
    flex-direction: row;
  }

  .lg\:gs-items-center {
    align-items: center;
  }

  .lg\:gs-justify-center {
    justify-content: center;
  }

  .lg\:gs-gap-4 {
    gap: 1rem;
  }

  .lg\:gs-rounded-xl {
    border-radius: .75rem;
  }

  .lg\:gs-border {
    border-width: 1px;
  }

  .lg\:gs-border-beige-500 {
    --tw-border-opacity: 1;
    border-color: rgb(220 217 211 / var(--tw-border-opacity) );
  }

  .lg\:gs-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  }

  .lg\:gs-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:gs-pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:gs-pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:gs-text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:gs-text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:gs-shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:gs-drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:last\:gs-rounded-b-xl:last-child {
    border-bottom-left-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:gs-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .\32 xl\:gs-col-span-1 {
    grid-column: span 1 / span 1;
  }
}

.quick-view[data-v-e4cac1] {
  width: 100%;
  z-index: 5;
  background-color: #f9f6f2;
  top: 0;
  right: 0;
}

@media (min-width: 992px) {
  .quick-view[data-v-e4cac1] {
    width: 50%;
    position: fixed;
  }
}

.close-quick-view[data-v-e4cac1] {
  float: right;
  cursor: pointer;
  margin: 10px;
}

.quick-view-inner[data-v-e4cac1] {
  background-color: #fffcfb;
  border-radius: 4px;
  margin: 60px;
}

.resize-observer[data-v-b329ee4c] {
  z-index: -1;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  background-color: #0000;
  border: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.resize-observer[data-v-b329ee4c] object {
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: -1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.v-popper__popper {
  z-index: 10000;
  outline: none;
  top: 0;
  left: 0;
}

.v-popper__popper.v-popper__popper--hidden {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: opacity .15s, visibility .15s;
}

.v-popper__popper.v-popper__popper--shown {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}

.v-popper__popper.v-popper__popper--skip-transition, .v-popper__popper.v-popper__popper--skip-transition > .v-popper__wrapper {
  transition: none !important;
}

.v-popper__backdrop {
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.v-popper__inner {
  box-sizing: border-box;
  position: relative;
  overflow-y: auto;
}

.v-popper__inner > div {
  z-index: 1;
  max-width: inherit;
  max-height: inherit;
  position: relative;
}

.v-popper__arrow-container {
  width: 10px;
  height: 10px;
  position: absolute;
}

.v-popper__popper--arrow-overflow .v-popper__arrow-container, .v-popper__popper--no-positioning .v-popper__arrow-container {
  display: none;
}

.v-popper__arrow-inner, .v-popper__arrow-outer {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  top: 0;
  left: 0;
}

.v-popper__arrow-inner {
  visibility: hidden;
  border-width: 7px;
}

.v-popper__arrow-outer {
  border-width: 6px;
}

.v-popper__popper[data-popper-placement^="top"] .v-popper__arrow-inner, .v-popper__popper[data-popper-placement^="bottom"] .v-popper__arrow-inner {
  left: -2px;
}

.v-popper__popper[data-popper-placement^="top"] .v-popper__arrow-outer, .v-popper__popper[data-popper-placement^="bottom"] .v-popper__arrow-outer {
  left: -1px;
}

.v-popper__popper[data-popper-placement^="top"] .v-popper__arrow-inner, .v-popper__popper[data-popper-placement^="top"] .v-popper__arrow-outer {
  border-bottom-width: 0;
  border-bottom-color: #0000 !important;
  border-left-color: #0000 !important;
  border-right-color: #0000 !important;
}

.v-popper__popper[data-popper-placement^="top"] .v-popper__arrow-inner {
  top: -2px;
}

.v-popper__popper[data-popper-placement^="bottom"] .v-popper__arrow-container {
  top: 0;
}

.v-popper__popper[data-popper-placement^="bottom"] .v-popper__arrow-inner, .v-popper__popper[data-popper-placement^="bottom"] .v-popper__arrow-outer {
  border-top-width: 0;
  border-top-color: #0000 !important;
  border-left-color: #0000 !important;
  border-right-color: #0000 !important;
}

.v-popper__popper[data-popper-placement^="bottom"] .v-popper__arrow-inner {
  top: -4px;
}

.v-popper__popper[data-popper-placement^="bottom"] .v-popper__arrow-outer {
  top: -6px;
}

.v-popper__popper[data-popper-placement^="left"] .v-popper__arrow-inner, .v-popper__popper[data-popper-placement^="right"] .v-popper__arrow-inner {
  top: -2px;
}

.v-popper__popper[data-popper-placement^="left"] .v-popper__arrow-outer, .v-popper__popper[data-popper-placement^="right"] .v-popper__arrow-outer {
  top: -1px;
}

.v-popper__popper[data-popper-placement^="right"] .v-popper__arrow-inner, .v-popper__popper[data-popper-placement^="right"] .v-popper__arrow-outer {
  border-left-width: 0;
  border-top-color: #0000 !important;
  border-bottom-color: #0000 !important;
  border-left-color: #0000 !important;
}

.v-popper__popper[data-popper-placement^="right"] .v-popper__arrow-inner {
  left: -4px;
}

.v-popper__popper[data-popper-placement^="right"] .v-popper__arrow-outer {
  left: -6px;
}

.v-popper__popper[data-popper-placement^="left"] .v-popper__arrow-container {
  right: -10px;
}

.v-popper__popper[data-popper-placement^="left"] .v-popper__arrow-inner, .v-popper__popper[data-popper-placement^="left"] .v-popper__arrow-outer {
  border-right-width: 0;
  border-top-color: #0000 !important;
  border-bottom-color: #0000 !important;
  border-right-color: #0000 !important;
}

.v-popper__popper[data-popper-placement^="left"] .v-popper__arrow-inner {
  left: -2px;
}

.v-popper--theme-tooltip .v-popper__inner {
  color: #fff;
  background: #000c;
  border-radius: 6px;
  padding: 7px 12px 6px;
}

.v-popper--theme-tooltip .v-popper__arrow-outer {
  border-color: #000c;
}

.v-popper--theme-dropdown .v-popper__inner {
  color: #000;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 6px 30px #0000001a;
}

.v-popper--theme-dropdown .v-popper__arrow-inner {
  visibility: visible;
  border-color: #fff;
}

.v-popper--theme-dropdown .v-popper__arrow-outer {
  border-color: #ddd;
}

:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animate__animated {
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

.animate__animated.animate__infinite {
  animation-iteration-count: infinite;
}

.animate__animated.animate__repeat-1 {
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: var(--animate-repeat);
  animation-iteration-count: var(--animate-repeat);
}

.animate__animated.animate__repeat-2 {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 2);
  animation-iteration-count: calc(var(--animate-repeat) * 2);
}

.animate__animated.animate__repeat-3 {
  animation-iteration-count: 3;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 3);
  animation-iteration-count: calc(var(--animate-repeat) * 3);
}

.animate__animated.animate__delay-1s {
  animation-delay: 1s;
  -webkit-animation-delay: var(--animate-delay);
  animation-delay: var(--animate-delay);
}

.animate__animated.animate__delay-2s {
  animation-delay: 2s;
  -webkit-animation-delay: calc(var(--animate-delay) * 2);
  animation-delay: calc(var(--animate-delay) * 2);
}

.animate__animated.animate__delay-3s {
  animation-delay: 3s;
  -webkit-animation-delay: calc(var(--animate-delay) * 3);
  animation-delay: calc(var(--animate-delay) * 3);
}

.animate__animated.animate__delay-4s {
  animation-delay: 4s;
  -webkit-animation-delay: calc(var(--animate-delay) * 4);
  animation-delay: calc(var(--animate-delay) * 4);
}

.animate__animated.animate__delay-5s {
  animation-delay: 5s;
  -webkit-animation-delay: calc(var(--animate-delay) * 5);
  animation-delay: calc(var(--animate-delay) * 5);
}

.animate__animated.animate__faster {
  animation-duration: .5s;
  -webkit-animation-duration: calc(var(--animate-duration) / 2);
  animation-duration: calc(var(--animate-duration) / 2);
}

.animate__animated.animate__fast {
  animation-duration: .8s;
  -webkit-animation-duration: calc(var(--animate-duration) * .8);
  animation-duration: calc(var(--animate-duration) * .8);
}

.animate__animated.animate__slow {
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
}

.animate__animated.animate__slower {
  animation-duration: 3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 3);
  animation-duration: calc(var(--animate-duration) * 3);
}

@media print, (prefers-reduced-motion: reduce) {
  .animate__animated {
    transition-duration: 1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }

  .animate__animated[class*="Out"] {
    opacity: 0;
  }
}

@keyframes bounce {
  from, 20%, 53%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%, 43% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -30px, 0)scaleY(1.1);
  }

  70% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -15px, 0)scaleY(1.05);
  }

  80% {
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -4px, 0)scaleY(1.02);
  }
}

.animate__bounce {
  transform-origin: bottom;
  animation-name: bounce;
}

@keyframes flash {
  from, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.animate__flash {
  animation-name: flash;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__pulse {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, .75, 1);
  }

  40% {
    transform: scale3d(.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, .85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__rubberBand {
  animation-name: rubberBand;
}

@keyframes shakeX {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.animate__shakeX {
  animation-name: shakeX;
}

@keyframes shakeY {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(0, -10px, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(0, 10px, 0);
  }
}

.animate__shakeY {
  animation-name: shakeY;
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px)rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px)rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px)rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px)rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.animate__headShake {
  animation-name: headShake;
  animation-timing-function: ease-in-out;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.animate__swing {
  transform-origin: top;
  animation-name: swing;
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    transform: scale3d(.9, .9, .9)rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__tada {
  animation-name: tada;
}

@keyframes wobble {
  from {
    transform: translate3d(0, 0, 0);
  }

  15% {
    transform: translate3d(-25%, 0, 0)rotate3d(0, 0, 1, -5deg);
  }

  30% {
    transform: translate3d(20%, 0, 0)rotate3d(0, 0, 1, 3deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0)rotate3d(0, 0, 1, -3deg);
  }

  60% {
    transform: translate3d(10%, 0, 0)rotate3d(0, 0, 1, 2deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0)rotate3d(0, 0, 1, -1deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__wobble {
  animation-name: wobble;
}

@keyframes jello {
  from, 11.1%, to {
    transform: translate3d(0, 0, 0);
  }

  22.2% {
    transform: skewX(-12.5deg)skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg)skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg)skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg)skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-.78125deg)skewY(-.78125deg);
  }

  77.7% {
    transform: skewX(.390625deg)skewY(.390625deg);
  }

  88.8% {
    transform: skewX(-.195313deg)skewY(-.195313deg);
  }
}

.animate__jello {
  transform-origin: center;
  animation-name: jello;
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.3);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1);
  }
}

.animate__heartBeat {
  animation-name: heartBeat;
  animation-duration: 1.3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 1.3);
  animation-duration: calc(var(--animate-duration) * 1.3);
  animation-timing-function: ease-in-out;
}

@keyframes backInDown {
  0% {
    opacity: .7;
    transform: translateY(-1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInDown {
  animation-name: backInDown;
}

@keyframes backInLeft {
  0% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInLeft {
  animation-name: backInLeft;
}

@keyframes backInRight {
  0% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInRight {
  animation-name: backInRight;
}

@keyframes backInUp {
  0% {
    opacity: .7;
    transform: translateY(1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInUp {
  animation-name: backInUp;
}

@keyframes backOutDown {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateY(700px)scale(.7);
  }
}

.animate__backOutDown {
  animation-name: backOutDown;
}

@keyframes backOutLeft {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }
}

.animate__backOutLeft {
  animation-name: backOutLeft;
}

@keyframes backOutRight {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }
}

.animate__backOutRight {
  animation-name: backOutRight;
}

@keyframes backOutUp {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateY(-700px)scale(.7);
  }
}

.animate__backOutUp {
  animation-name: backOutUp;
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.animate__bounceIn {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: bounceIn;
}

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0)scaleY(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, -10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, 5px, 0)scaleY(.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(-10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(-5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0)scaleY(5);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, 10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -5px, 0)scaleY(.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

.animate__bounceOut {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: bounceOut;
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0)scaleY(3);
  }
}

.animate__bounceOutDown {
  animation-name: bounceOutDown;
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0)scaleX(2);
  }
}

.animate__bounceOutLeft {
  animation-name: bounceOutLeft;
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0)scaleX(2);
  }
}

.animate__bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0)scaleY(3);
  }
}

.animate__bounceOutUp {
  animation-name: bounceOutUp;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate__fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeInTopLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInTopLeft {
  animation-name: fadeInTopLeft;
}

@keyframes fadeInTopRight {
  from {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInTopRight {
  animation-name: fadeInTopRight;
}

@keyframes fadeInBottomLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInBottomLeft {
  animation-name: fadeInBottomLeft;
}

@keyframes fadeInBottomRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInBottomRight {
  animation-name: fadeInBottomRight;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.animate__fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.animate__fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.animate__fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.animate__fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.animate__fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.animate__fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.animate__fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.animate__fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@keyframes fadeOutTopLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }
}

.animate__fadeOutTopLeft {
  animation-name: fadeOutTopLeft;
}

@keyframes fadeOutTopRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }
}

.animate__fadeOutTopRight {
  animation-name: fadeOutTopRight;
}

@keyframes fadeOutBottomRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }
}

.animate__fadeOutBottomRight {
  animation-name: fadeOutBottomRight;
}

@keyframes fadeOutBottomLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }
}

.animate__fadeOutBottomLeft {
  animation-name: fadeOutBottomLeft;
}

@keyframes flip {
  from {
    animation-timing-function: ease-out;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 0)rotate3d(0, 1, 0, -360deg);
  }

  40% {
    animation-timing-function: ease-out;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -190deg);
  }

  50% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -170deg);
  }

  80% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(.95, .95, .95)translate3d(0, 0, 0)rotate3d(0, 1, 0, 0deg);
  }

  to {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 0)rotate3d(0, 1, 0, 0deg);
  }
}

.animate__animated.animate__flip {
  backface-visibility: visible;
  animation-name: flip;
}

@keyframes flipInX {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.animate__flipInX {
  animation-name: flipInX;
  backface-visibility: visible !important;
}

@keyframes flipInY {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(0, 1, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.animate__flipInY {
  animation-name: flipInY;
  backface-visibility: visible !important;
}

@keyframes flipOutX {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.animate__flipOutX {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: flipOutX;
  backface-visibility: visible !important;
}

@keyframes flipOutY {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, -15deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }
}

.animate__flipOutY {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: flipOutY;
  backface-visibility: visible !important;
}

@keyframes lightSpeedInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(-30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(20deg);
  }

  80% {
    transform: skewX(-5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__lightSpeedInRight {
  animation-name: lightSpeedInRight;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(-20deg);
  }

  80% {
    transform: skewX(5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__lightSpeedInLeft {
  animation-name: lightSpeedInLeft;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(30deg);
  }
}

.animate__lightSpeedOutRight {
  animation-name: lightSpeedOutRight;
  animation-timing-function: ease-in;
}

@keyframes lightSpeedOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(-30deg);
  }
}

.animate__lightSpeedOutLeft {
  animation-name: lightSpeedOutLeft;
  animation-timing-function: ease-in;
}

@keyframes rotateIn {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -200deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateIn {
  transform-origin: center;
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -90deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 200deg);
  }
}

.animate__rotateOut {
  transform-origin: center;
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }
}

.animate__rotateOutDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.animate__rotateOutDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.animate__rotateOutUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 90deg);
  }
}

.animate__rotateOutUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutUpRight;
}

@keyframes hinge {
  0% {
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 80deg);
  }

  40%, 80% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 60deg);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 700px, 0);
  }
}

.animate__hinge {
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
  transform-origin: 0 0;
  animation-name: hinge;
}

@keyframes jackInTheBox {
  from {
    opacity: 0;
    transform-origin: bottom;
    transform: scale(.1)rotate(30deg);
  }

  50% {
    transform: rotate(-10deg);
  }

  70% {
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__jackInTheBox {
  animation-name: jackInTheBox;
}

@keyframes rollIn {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)rotate3d(0, 0, 1, -120deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rollIn {
  animation-name: rollIn;
}

@keyframes rollOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)rotate3d(0, 0, 1, 120deg);
  }
}

.animate__rollOut {
  animation-name: rollOut;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.animate__zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, -1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }
}

.animate__zoomInDown {
  animation-name: zoomInDown;
}

@keyframes zoomInLeft {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(-1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(10px, 0, 0);
  }
}

.animate__zoomInLeft {
  animation-name: zoomInLeft;
}

@keyframes zoomInRight {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(-10px, 0, 0);
  }
}

.animate__zoomInRight {
  animation-name: zoomInRight;
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }
}

.animate__zoomInUp {
  animation-name: zoomInUp;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.animate__zoomOut {
  animation-name: zoomOut;
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, 2000px, 0);
  }
}

.animate__zoomOutDown {
  transform-origin: bottom;
  animation-name: zoomOutDown;
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(-2000px, 0, 0);
  }
}

.animate__zoomOutLeft {
  transform-origin: 0;
  animation-name: zoomOutLeft;
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(2000px, 0, 0);
  }
}

.animate__zoomOutRight {
  transform-origin: 100%;
  animation-name: zoomOutRight;
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, -2000px, 0);
  }
}

.animate__zoomOutUp {
  transform-origin: bottom;
  animation-name: zoomOutUp;
}

@keyframes slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  from {
    visibility: visible;
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.animate__slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.animate__slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

.animate__slideOutUp {
  animation-name: slideOutUp;
}

/*# sourceMappingURL=search.css.map */
